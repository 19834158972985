import {
  FieldErrors,
  UseFormGetValues,
  UseFormRegister,
  UseFormWatch,
  ValidationRule,
} from 'react-hook-form';
import { UserStatus } from '../store/user/slice';
import { ConfigResponse } from './config/interfaces';
import { PlanData } from './choosePlanTypes';
import { MembershipDetails } from './membershipInfoTypes';
import { PaymentHistoryResponse } from './paymentHistoryTypes';
import { CartData } from './cartDataTypes';
import { InitiateCartPaymentResponse } from './paymentSummaryTypes';
import { CurrentUserPlansResponse } from './currentUserPlansTypes';
import { CartAddOnForOthers } from './cartDataTypes';
import { WillMailRecipientSlice } from './willmailrecipienttypes';
import { SigningDocuments } from './willsigningDocumentsType';

export type AuthSlice = {
  authLoading: boolean;
  authUser: AuthUser | null;
  requestStatus: string;
  userProfile: UserProfile | null;
  isUserProfileLoaded: boolean;
  userContacts: Array<Contact>;
  userAssets: Array<Asset>;
  userLiabilities: Array<Liability>;
  content?: Content | null;
  will: Will | null;
  remainingProfileFields: Array<string>;
  // backupExecutors: Array<Contact>;
  accessRole: AccessRole;
  config: ConfigResponse | null;
  yellowPlusConfig: Partial<PlanData>;
  yellowMembershipConfig: Partial<MembershipDetails>;
  yellowCartData: Partial<CartData>;

  yellowPaymentSummaryData: Partial<InitiateCartPaymentResponse>;
  isConfigLoading: boolean;
  showGuardianSection: boolean;
  userStatus: UserStatus[];
  unloggedInUserSessionData: Partial<UnloggedInUserSessionData>;
  loggedOutConfig: any;
  currentUserPlansResponse: Partial<CurrentUserPlansResponse>;

  paymentHistoryResponse: Partial<PaymentHistoryResponse>;

  willRecipientMailSlice: Partial<WillMailRecipientSlice>;

  createdAt?: string;

  willRecipients?: WillRecipientInput[];
  userNotifications?: UserNotification[];
};

export type WillRecipient = {
  userId?: string;
  recipients?: WillRecipientInput[];
};

export type WillRecipientInput = {
  fullName: string;
  email: string;
}

export type UnloggedInUserSessionData = {
  dateOfBirth: string;
  isNri: boolean;
  postLoginUrl: string;
  myCartCurrentlyShowing: string | any;
  addingFor?: string | null | undefined;
  chosenPlanId: string | null;
  chosenAddOns: string[];
};

export type MeDTO = {
  profile: UserProfile;
  userAccessRole: AccessRole;
  contacts: Array<Contact>;
  will: Will;
  assets: Array<Asset>;
  liabilities: Array<Liability>;
  remainingProfileFields: Array<string>;
  createdAt: string;
};

export type UserProfile = {
  isWelcomeEmailSent: boolean;
  documentId: string;
  fullName: string;
  email: string;
  isNRI: boolean;
  externalVendor: string;
  profilePicture: string;
  gender: Gender;
  religion: Religion;
  phoneNumber: string;
  dateOfBirth: string;
  primaryAddress: UserAddress;
  mailingAddress: UserAddress;
  kycDetails: KycDetails;
  wills: Array<WillRelationshipMap>;
  partner?: string;
};

export enum WillMapRoles {
  WILL_ADMIN = 'will_admin',
}

export type WillRelationshipMap = {
  role: WillMapRoles;
  will_id: string;
  isActive: boolean;
};

export enum KycType {
  PAN = 'PAN',
  PASSPORT = 'PASSPORT',
  DRIVING_LICENCE = 'DRIVING_LICENCE',
  VOTER_ID = 'VOTER_ID',
}

export enum KycStatus {
  NOT_DONE = 'NOT_DONE',
  DONE = 'DONE',
  PENDING = 'PENDING',
  PROCESSED = 'PROCESSED',
}

export type IdfyIdentifiers = {
  idfyRequestId?: String;
  idfyTaskId?: String;
  idfyGroupId?: String;
};

export type KycDetails = {
  kycType: KycType;
  kycStatus: KycStatus;
  idNumber?: String;
  fullName?: String;
  fathersName?: String;
  dateOfBirth?: String;
  idfyDocumentUrls?: [String];
  idfyIdentifiers?: IdfyIdentifiers;
};

export enum RequestLoader {
  NOT_STARTED = 'NOT_STARTED',
  LOADING = 'LOADING',
  LOAD_COMPLETE = 'LOAD_COMPLETE',
  ERROR = 'ERROR',
}

export type AuthUser = {
  id: string;
  phoneNumber: string;
  authToken: string | null;
};

export enum RoleType {
  ANONYMOUS = 'ANONYMOUS',
  LOGGEDIN = 'LOGGEDIN',
  ADMIN = 'ADMIN',
}

export type AccessRole = {
  role: RoleType;
  permissions: string[];
};

export const DEFAULT_ROLE: AccessRole = {
  role: RoleType.ANONYMOUS,
  permissions: ['default_permission'],
};

export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  OTHER = 'OTHER',
}

export enum States {
  ANDHRAPRADESH = 'ANDHRA PRADESH',
  ARUNACHALPRADESH = 'ARUNACHAL PRADESH',
  ASSAM = 'ASSAM',
  BIHAR = 'BIHAR',
  CHHATTISGARH = 'CHHATTISGARH',
  GOA = 'GOA',
  GUJARAT = 'GUJARAT',
  HARYANA = 'HARYANA',
  HIMACHALPRADESH = 'HIMACHAL PRADESH',
  JHARKHAND = 'JHARKHAND',
  KARNATAKA = 'KARNATAKA',
  KERALA = 'KERALA',
  MADHYAPRADESH = 'MADHYA PRADESH',
  MAHARASHTRA = 'MAHARASHTRA',
  MANIPUR = 'MANIPUR',
  MEGHALAYA = 'MEGHALAYA',
  MIZORAM = 'MIZORAM',
  NAGALAND = 'NAGALAND',
  ODISHA = 'ODISHA',
  PUNJAB = 'PUNJAB',
  RAJASTHAN = 'RAJASTHAN',
  SIKKIM = 'SIKKIM',
  TAMILNADU = 'TAMILNADU',
  TELANGANA = 'TELANGANA',
  TRIPURA = 'TRIPURA',
  UTTARPRADESH = 'UTTAR PRADESH',
  UTTARAKHAND = 'UTTARAKHAND',
  WESTBENGAL = 'WEST BENGAL',
}

export enum NewStates {
  'Andhra Pradesh' = 'Andhra Pradesh',
  'Arunachal Pradesh' = 'Arunachal Pradesh',
  'Assam' = 'Assam',
  'Bihar' = 'Bihar',
  'Chhattisgarh' = 'Chhattisgarh',
  'Goa' = 'Goa',
  'Gujarat' = 'Gujarat',
  'Haryana' = 'Haryana',
  'Himachal Pradesh' = 'Himachal Pradesh',
  'Jharkhand' = 'Jharkhand',
  'Karnataka' = 'Karnataka',
  'Kerala' = 'Kerala',
  'Madhya Pradesh' = 'Madhya Pradesh',
  'Maharashtra' = 'Maharashtra',
  'Manipur' = 'Manipur',
  'Meghalaya' = 'Meghalaya',
  'Mizoram' = 'Mizoram',
  'Nagaland' = 'Nagaland',
  'Odisha' = 'Odisha',
  'Punjab' = 'Punjab',
  'Rajasthan' = 'Rajasthan',
  'Sikkim' = 'Sikkim',
  'Tamil Nadu' = 'Tamil Nadu',
  'Telangana' = 'Telangana',
  'Tripura' = 'Tripura',
  'Uttar Pradesh' = 'Uttar Pradesh',
  'Uttarakhand' = 'Uttarakhand',
  'West Bengal' = 'West Bengal',
  'Delhi' = 'Delhi',
  'Andaman and Nicobar Islands' = 'Andaman and Nicobar Islands',
  'Jammu and Kashmir' = 'Jammu and Kashmir',
  'Dadra and Nagar Haveli' = 'Dadra and Nagar Haveli',
  'Daman and Diu' = 'Daman and Diu',
  'Lakshadweep' = 'Lakshadweep',
  'Puducherry' = 'Puducherry',
  'Ladakh' = 'Ladakh',
}

export enum Countries {
  'Aruba' = 'Aruba',
  'Afghanistan' = 'Afghanistan',
  'Angola' = 'Angola',
  'Anguilla' = 'Anguilla',
  'Åland Islands' = 'Åland Islands',
  'Albania' = 'Albania',
  'Andorra' = 'Andorra',
  'United Arab Emirates' = 'United Arab Emirates',
  'Argentina' = 'Argentina',
  'Armenia' = 'Armenia',
  'American Samoa' = 'American Samoa',
  'Antarctica' = 'Antarctica',
  'French Southern and Antarctic Lands' = 'French Southern and Antarctic Lands',
  'Antigua and Barbuda' = 'Antigua and Barbuda',
  'Australia' = 'Australia',
  'Austria' = 'Austria',
  'Azerbaijan' = 'Azerbaijan',
  'Burundi' = 'Burundi',
  'Belgium' = 'Belgium',
  'Benin' = 'Benin',
  'Burkina Faso' = 'Burkina Faso',
  'Bangladesh' = 'Bangladesh',
  'Bulgaria' = 'Bulgaria',
  'Bahrain' = 'Bahrain',
  'Bahamas' = 'Bahamas',
  'Bosnia and Herzegovina' = 'Bosnia and Herzegovina',
  'Saint Barthélemy' = 'Saint Barthélemy',
  'Saint Helena, Ascension and Tristan da Cunha' = 'Saint Helena, Ascension and Tristan da Cunha',
  'Belarus' = 'Belarus',
  'Belize' = 'Belize',
  'Bermuda' = 'Bermuda',
  'Bolivia' = 'Bolivia',
  'bbean Netherlands' = 'bbean Netherlands',
  'Brazil' = 'Brazil',
  'Barbados' = 'Barbados',
  'Brunei' = 'Brunei',
  'Bhutan' = 'Bhutan',
  'Bouvet Island' = 'Bouvet Island',
  'Botswana' = 'Botswana',
  'Central African Republic' = 'Central African Republic',
  'Canada' = 'Canada',
  'Cocos (Keeling) Islands' = 'Cocos (Keeling) Islands',
  'Switzerland' = 'Switzerland',
  'Chile' = 'Chile',
  'China' = 'China',
  'Ivory Coast' = 'Ivory Coast',
  'Cameroon' = 'Cameroon',
  'DR Congo' = 'DR Congo',
  'Republic of the Congo' = 'Republic of the Congo',
  'Cook Islands' = 'Cook Islands',
  'Colombia' = 'Colombia',
  'Comoros' = 'Comoros',
  'Cape Verde' = 'Cape Verde',
  'Costa Rica' = 'Costa Rica',
  'Cuba' = 'Cuba',
  'Curaçao' = 'Curaçao',
  'Christmas Island' = 'Christmas Island',
  'Cayman Islands' = 'Cayman Islands',
  'Cyprus' = 'Cyprus',
  'Czechia' = 'Czechia',
  'Germany' = 'Germany',
  'Djibouti' = 'Djibouti',
  'Dominica' = 'Dominica',
  'Denmark' = 'Denmark',
  'Dominican Republic' = 'Dominican Republic',
  'Algeria' = 'Algeria',
  'Ecuador' = 'Ecuador',
  'Egypt' = 'Egypt',
  'Eritrea' = 'Eritrea',
  'Western Sahara' = 'Western Sahara',
  'Spain' = 'Spain',
  'Estonia' = 'Estonia',
  'Ethiopia' = 'Ethiopia',
  'Finland' = 'Finland',
  'Fiji' = 'Fiji',
  'Falkland Islands' = 'Falkland Islands',
  'France' = 'France',
  'Faroe Islands' = 'Faroe Islands',
  'Micronesia' = 'Micronesia',
  'Gabon' = 'Gabon',
  'United Kingdom' = 'United Kingdom',
  'Georgia' = 'Georgia',
  'Guernsey' = 'Guernsey',
  'Ghana' = 'Ghana',
  'Gibraltar' = 'Gibraltar',
  'Guinea' = 'Guinea',
  'Guadeloupe' = 'Guadeloupe',
  'Gambia' = 'Gambia',
  'Guinea-Bissau' = 'Guinea-Bissau',
  'Equatorial Guinea' = 'Equatorial Guinea',
  'Greece' = 'Greece',
  'Grenada' = 'Grenada',
  'Greenland' = 'Greenland',
  'Guatemala' = 'Guatemala',
  'French Guiana' = 'French Guiana',
  'Guam' = 'Guam',
  'Guyana' = 'Guyana',
  'Hong Kong' = 'Hong Kong',
  'Heard Island and McDonald Islands' = 'Heard Island and McDonald Islands',
  'Honduras' = 'Honduras',
  'Croatia' = 'Croatia',
  'Haiti' = 'Haiti',
  'Hungary' = 'Hungary',
  'Indonesia' = 'Indonesia',
  'Isle of Man' = 'Isle of Man',
  'India' = 'India',
  'British Indian Ocean Territory' = 'British Indian Ocean Territory',
  'Ireland' = 'Ireland',
  'Iran' = 'Iran',
  'Iraq' = 'Iraq',
  'Iceland' = 'Iceland',
  'Israel' = 'Israel',
  'Italy' = 'Italy',
  'Jamaica' = 'Jamaica',
  'Jersey' = 'Jersey',
  'Jordan' = 'Jordan',
  'Japan' = 'Japan',
  'Kazakhstan' = 'Kazakhstan',
  'Kenya' = 'Kenya',
  'Kyrgyzstan' = 'Kyrgyzstan',
  'Cambodia' = 'Cambodia',
  'Kiribati' = 'Kiribati',
  'Saint Kitts and Nevis' = 'Saint Kitts and Nevis',
  'South Korea' = 'South Korea',
  'Kosovo' = 'Kosovo',
  'Kuwait' = 'Kuwait',
  'Laos' = 'Laos',
  'Lebanon' = 'Lebanon',
  'Liberia' = 'Liberia',
  'Libya' = 'Libya',
  'Saint Lucia' = 'Saint Lucia',
  'Liechtenstein' = 'Liechtenstein',
  'Sri Lanka' = 'Sri Lanka',
  'Lesotho' = 'Lesotho',
  'Lithuania' = 'Lithuania',
  'Luxembourg' = 'Luxembourg',
  'Latvia' = 'Latvia',
  'Macau' = 'Macau',
  'Saint Martin' = 'Saint Martin',
  'Morocco' = 'Morocco',
  'Monaco' = 'Monaco',
  'Moldova' = 'Moldova',
  'Madagascar' = 'Madagascar',
  'Maldives' = 'Maldives',
  'Mexico' = 'Mexico',
  'Marshall Islands' = 'Marshall Islands',
  'North Macedonia' = 'North Macedonia',
  'Mali' = 'Mali',
  'Malta' = 'Malta',
  'Myanmar' = 'Myanmar',
  'Montenegro' = 'Montenegro',
  'Mongolia' = 'Mongolia',
  'Northern Mariana Islands' = 'Northern Mariana Islands',
  'Mozambique' = 'Mozambique',
  'Mauritania' = 'Mauritania',
  'Montserrat' = 'Montserrat',
  'Martinique' = 'Martinique',
  'Mauritius' = 'Mauritius',
  'Malawi' = 'Malawi',
  'Malaysia' = 'Malaysia',
  'Mayotte' = 'Mayotte',
  'Namibia' = 'Namibia',
  'New Caledonia' = 'New Caledonia',
  'Niger' = 'Niger',
  'Norfolk Island' = 'Norfolk Island',
  'Nigeria' = 'Nigeria',
  'Nicaragua' = 'Nicaragua',
  'Niue' = 'Niue',
  'Netherlands' = 'Netherlands',
  'Norway' = 'Norway',
  'Nepal' = 'Nepal',
  'Nauru' = 'Nauru',
  'New Zealand' = 'New Zealand',
  'Oman' = 'Oman',
  'Pakistan' = 'Pakistan',
  'Panama' = 'Panama',
  'Pitcairn Islands' = 'Pitcairn Islands',
  'Peru' = 'Peru',
  'Philippines' = 'Philippines',
  'Palau' = 'Palau',
  'Papua New Guinea' = 'Papua New Guinea',
  'Poland' = 'Poland',
  'Puerto Rico' = 'Puerto Rico',
  'North Korea' = 'North Korea',
  'Portugal' = 'Portugal',
  'Paraguay' = 'Paraguay',
  'Palestine' = 'Palestine',
  'French Polynesia' = 'French Polynesia',
  'Qatar' = 'Qatar',
  'Réunion' = 'Réunion',
  'Romania' = 'Romania',
  'Russia' = 'Russia',
  'Rwanda' = 'Rwanda',
  'Saudi Arabia' = 'Saudi Arabia',
  'Sudan' = 'Sudan',
  'Senegal' = 'Senegal',
  'Singapore' = 'Singapore',
  'South Georgia' = 'South Georgia',
  'Svalbard and Jan Mayen' = 'Svalbard and Jan Mayen',
  'Solomon Islands' = 'Solomon Islands',
  'Sierra Leone' = 'Sierra Leone',
  'El Salvador' = 'El Salvador',
  'San Marino' = 'San Marino',
  'Somalia' = 'Somalia',
  'Saint Pierre and Miquelon' = 'Saint Pierre and Miquelon',
  'Serbia' = 'Serbia',
  'South Sudan' = 'South Sudan',
  'São Tomé and Príncipe' = 'São Tomé and Príncipe',
  'Suriname' = 'Suriname',
  'Slovakia' = 'Slovakia',
  'Slovenia' = 'Slovenia',
  'Sweden' = 'Sweden',
  'Eswatini' = 'Eswatini',
  'Sint Maarten' = 'Sint Maarten',
  'Seychelles' = 'Seychelles',
  'Syria' = 'Syria',
  'Turks and Caicos Islands' = 'Turks and Caicos Islands',
  'Chad' = 'Chad',
  'Togo' = 'Togo',
  'Thailand' = 'Thailand',
  'Tajikistan' = 'Tajikistan',
  'Tokelau' = 'Tokelau',
  'Turkmenistan' = 'Turkmenistan',
  'Timor-Leste' = 'Timor-Leste',
  'Tonga' = 'Tonga',
  'Trinidad and Tobago' = 'Trinidad and Tobago',
  'Tunisia' = 'Tunisia',
  'Turkey' = 'Turkey',
  'Tuvalu' = 'Tuvalu',
  'Taiwan' = 'Taiwan',
  'Tanzania' = 'Tanzania',
  'Uganda' = 'Uganda',
  'Ukraine' = 'Ukraine',
  'United States Minor Outlying Islands' = 'United States Minor Outlying Islands',
  'Uruguay' = 'Uruguay',
  'United States' = 'United States',
  'Uzbekistan' = 'Uzbekistan',
  'Vatican City' = 'Vatican City',
  'Saint Vincent and the Grenadines' = 'Saint Vincent and the Grenadines',
  'Venezuela' = 'Venezuela',
  'British Virgin Islands' = 'British Virgin Islands',
  'United States Virgin Islands' = 'United States Virgin Islands',
  'Vietnam' = 'Vietnam',
  'Vanuatu' = 'Vanuatu',
  'Wallis and Futuna' = 'Wallis and Futuna',
  'Samoa' = 'Samoa',
  'Yemen' = 'Yemen',
  'South Africa' = 'South Africa',
  'Zambia' = 'Zambia',
  'Zimbabwe' = 'Zimbabwe',
}

export enum NonIndianCountries {
  'Aruba' = 'Aruba',
  'Afghanistan' = 'Afghanistan',
  'Angola' = 'Angola',
  'Anguilla' = 'Anguilla',
  'Åland Islands' = 'Åland Islands',
  'Albania' = 'Albania',
  'Andorra' = 'Andorra',
  'United Arab Emirates' = 'United Arab Emirates',
  'Argentina' = 'Argentina',
  'Armenia' = 'Armenia',
  'American Samoa' = 'American Samoa',
  'Antarctica' = 'Antarctica',
  'French Southern and Antarctic Lands' = 'French Southern and Antarctic Lands',
  'Antigua and Barbuda' = 'Antigua and Barbuda',
  'Australia' = 'Australia',
  'Austria' = 'Austria',
  'Azerbaijan' = 'Azerbaijan',
  'Burundi' = 'Burundi',
  'Belgium' = 'Belgium',
  'Benin' = 'Benin',
  'Burkina Faso' = 'Burkina Faso',
  'Bangladesh' = 'Bangladesh',
  'Bulgaria' = 'Bulgaria',
  'Bahrain' = 'Bahrain',
  'Bahamas' = 'Bahamas',
  'Bosnia and Herzegovina' = 'Bosnia and Herzegovina',
  'Saint Barthélemy' = 'Saint Barthélemy',
  'Saint Helena, Ascension and Tristan da Cunha' = 'Saint Helena, Ascension and Tristan da Cunha',
  'Belarus' = 'Belarus',
  'Belize' = 'Belize',
  'Bermuda' = 'Bermuda',
  'Bolivia' = 'Bolivia',
  'bbean Netherlands' = 'bbean Netherlands',
  'Brazil' = 'Brazil',
  'Barbados' = 'Barbados',
  'Brunei' = 'Brunei',
  'Bhutan' = 'Bhutan',
  'Bouvet Island' = 'Bouvet Island',
  'Botswana' = 'Botswana',
  'Central African Republic' = 'Central African Republic',
  'Canada' = 'Canada',
  'Cocos (Keeling) Islands' = 'Cocos (Keeling) Islands',
  'Switzerland' = 'Switzerland',
  'Chile' = 'Chile',
  'China' = 'China',
  'Ivory Coast' = 'Ivory Coast',
  'Cameroon' = 'Cameroon',
  'DR Congo' = 'DR Congo',
  'Republic of the Congo' = 'Republic of the Congo',
  'Cook Islands' = 'Cook Islands',
  'Colombia' = 'Colombia',
  'Comoros' = 'Comoros',
  'Cape Verde' = 'Cape Verde',
  'Costa Rica' = 'Costa Rica',
  'Cuba' = 'Cuba',
  'Curaçao' = 'Curaçao',
  'Christmas Island' = 'Christmas Island',
  'Cayman Islands' = 'Cayman Islands',
  'Cyprus' = 'Cyprus',
  'Czechia' = 'Czechia',
  'Germany' = 'Germany',
  'Djibouti' = 'Djibouti',
  'Dominica' = 'Dominica',
  'Denmark' = 'Denmark',
  'Dominican Republic' = 'Dominican Republic',
  'Algeria' = 'Algeria',
  'Ecuador' = 'Ecuador',
  'Egypt' = 'Egypt',
  'Eritrea' = 'Eritrea',
  'Western Sahara' = 'Western Sahara',
  'Spain' = 'Spain',
  'Estonia' = 'Estonia',
  'Ethiopia' = 'Ethiopia',
  'Finland' = 'Finland',
  'Fiji' = 'Fiji',
  'Falkland Islands' = 'Falkland Islands',
  'France' = 'France',
  'Faroe Islands' = 'Faroe Islands',
  'Micronesia' = 'Micronesia',
  'Gabon' = 'Gabon',
  'United Kingdom' = 'United Kingdom',
  'Georgia' = 'Georgia',
  'Guernsey' = 'Guernsey',
  'Ghana' = 'Ghana',
  'Gibraltar' = 'Gibraltar',
  'Guinea' = 'Guinea',
  'Guadeloupe' = 'Guadeloupe',
  'Gambia' = 'Gambia',
  'Guinea-Bissau' = 'Guinea-Bissau',
  'Equatorial Guinea' = 'Equatorial Guinea',
  'Greece' = 'Greece',
  'Grenada' = 'Grenada',
  'Greenland' = 'Greenland',
  'Guatemala' = 'Guatemala',
  'French Guiana' = 'French Guiana',
  'Guam' = 'Guam',
  'Guyana' = 'Guyana',
  'Hong Kong' = 'Hong Kong',
  'Heard Island and McDonald Islands' = 'Heard Island and McDonald Islands',
  'Honduras' = 'Honduras',
  'Croatia' = 'Croatia',
  'Haiti' = 'Haiti',
  'Hungary' = 'Hungary',
  'Indonesia' = 'Indonesia',
  'Isle of Man' = 'Isle of Man',
  'British Indian Ocean Territory' = 'British Indian Ocean Territory',
  'Ireland' = 'Ireland',
  'Iran' = 'Iran',
  'Iraq' = 'Iraq',
  'Iceland' = 'Iceland',
  'Israel' = 'Israel',
  'Italy' = 'Italy',
  'Jamaica' = 'Jamaica',
  'Jersey' = 'Jersey',
  'Jordan' = 'Jordan',
  'Japan' = 'Japan',
  'Kazakhstan' = 'Kazakhstan',
  'Kenya' = 'Kenya',
  'Kyrgyzstan' = 'Kyrgyzstan',
  'Cambodia' = 'Cambodia',
  'Kiribati' = 'Kiribati',
  'Saint Kitts and Nevis' = 'Saint Kitts and Nevis',
  'South Korea' = 'South Korea',
  'Kosovo' = 'Kosovo',
  'Kuwait' = 'Kuwait',
  'Laos' = 'Laos',
  'Lebanon' = 'Lebanon',
  'Liberia' = 'Liberia',
  'Libya' = 'Libya',
  'Saint Lucia' = 'Saint Lucia',
  'Liechtenstein' = 'Liechtenstein',
  'Sri Lanka' = 'Sri Lanka',
  'Lesotho' = 'Lesotho',
  'Lithuania' = 'Lithuania',
  'Luxembourg' = 'Luxembourg',
  'Latvia' = 'Latvia',
  'Macau' = 'Macau',
  'Saint Martin' = 'Saint Martin',
  'Morocco' = 'Morocco',
  'Monaco' = 'Monaco',
  'Moldova' = 'Moldova',
  'Madagascar' = 'Madagascar',
  'Maldives' = 'Maldives',
  'Mexico' = 'Mexico',
  'Marshall Islands' = 'Marshall Islands',
  'North Macedonia' = 'North Macedonia',
  'Mali' = 'Mali',
  'Malta' = 'Malta',
  'Myanmar' = 'Myanmar',
  'Montenegro' = 'Montenegro',
  'Mongolia' = 'Mongolia',
  'Northern Mariana Islands' = 'Northern Mariana Islands',
  'Mozambique' = 'Mozambique',
  'Mauritania' = 'Mauritania',
  'Montserrat' = 'Montserrat',
  'Martinique' = 'Martinique',
  'Mauritius' = 'Mauritius',
  'Malawi' = 'Malawi',
  'Malaysia' = 'Malaysia',
  'Mayotte' = 'Mayotte',
  'Namibia' = 'Namibia',
  'New Caledonia' = 'New Caledonia',
  'Niger' = 'Niger',
  'Norfolk Island' = 'Norfolk Island',
  'Nigeria' = 'Nigeria',
  'Nicaragua' = 'Nicaragua',
  'Niue' = 'Niue',
  'Netherlands' = 'Netherlands',
  'Norway' = 'Norway',
  'Nepal' = 'Nepal',
  'Nauru' = 'Nauru',
  'New Zealand' = 'New Zealand',
  'Oman' = 'Oman',
  'Pakistan' = 'Pakistan',
  'Panama' = 'Panama',
  'Pitcairn Islands' = 'Pitcairn Islands',
  'Peru' = 'Peru',
  'Philippines' = 'Philippines',
  'Palau' = 'Palau',
  'Papua New Guinea' = 'Papua New Guinea',
  'Poland' = 'Poland',
  'Puerto Rico' = 'Puerto Rico',
  'North Korea' = 'North Korea',
  'Portugal' = 'Portugal',
  'Paraguay' = 'Paraguay',
  'Palestine' = 'Palestine',
  'French Polynesia' = 'French Polynesia',
  'Qatar' = 'Qatar',
  'Réunion' = 'Réunion',
  'Romania' = 'Romania',
  'Russia' = 'Russia',
  'Rwanda' = 'Rwanda',
  'Saudi Arabia' = 'Saudi Arabia',
  'Sudan' = 'Sudan',
  'Senegal' = 'Senegal',
  'Singapore' = 'Singapore',
  'South Georgia' = 'South Georgia',
  'Svalbard and Jan Mayen' = 'Svalbard and Jan Mayen',
  'Solomon Islands' = 'Solomon Islands',
  'Sierra Leone' = 'Sierra Leone',
  'El Salvador' = 'El Salvador',
  'San Marino' = 'San Marino',
  'Somalia' = 'Somalia',
  'Saint Pierre and Miquelon' = 'Saint Pierre and Miquelon',
  'Serbia' = 'Serbia',
  'South Sudan' = 'South Sudan',
  'São Tomé and Príncipe' = 'São Tomé and Príncipe',
  'Suriname' = 'Suriname',
  'Slovakia' = 'Slovakia',
  'Slovenia' = 'Slovenia',
  'Sweden' = 'Sweden',
  'Eswatini' = 'Eswatini',
  'Sint Maarten' = 'Sint Maarten',
  'Seychelles' = 'Seychelles',
  'Syria' = 'Syria',
  'Turks and Caicos Islands' = 'Turks and Caicos Islands',
  'Chad' = 'Chad',
  'Togo' = 'Togo',
  'Thailand' = 'Thailand',
  'Tajikistan' = 'Tajikistan',
  'Tokelau' = 'Tokelau',
  'Turkmenistan' = 'Turkmenistan',
  'Timor-Leste' = 'Timor-Leste',
  'Tonga' = 'Tonga',
  'Trinidad and Tobago' = 'Trinidad and Tobago',
  'Tunisia' = 'Tunisia',
  'Turkey' = 'Turkey',
  'Tuvalu' = 'Tuvalu',
  'Taiwan' = 'Taiwan',
  'Tanzania' = 'Tanzania',
  'Uganda' = 'Uganda',
  'Ukraine' = 'Ukraine',
  'United States Minor Outlying Islands' = 'United States Minor Outlying Islands',
  'Uruguay' = 'Uruguay',
  'United States' = 'United States',
  'Uzbekistan' = 'Uzbekistan',
  'Vatican City' = 'Vatican City',
  'Saint Vincent and the Grenadines' = 'Saint Vincent and the Grenadines',
  'Venezuela' = 'Venezuela',
  'British Virgin Islands' = 'British Virgin Islands',
  'United States Virgin Islands' = 'United States Virgin Islands',
  'Vietnam' = 'Vietnam',
  'Vanuatu' = 'Vanuatu',
  'Wallis and Futuna' = 'Wallis and Futuna',
  'Samoa' = 'Samoa',
  'Yemen' = 'Yemen',
  'South Africa' = 'South Africa',
  'Zambia' = 'Zambia',
  'Zimbabwe' = 'Zimbabwe',
}

export enum Religion {
  HINDU = 'HINDU',
  MUSLIM = 'MUSLIM',
  CHRISTIAN = 'CHRISTIAN',
  SIKH = 'SIKH',
  PARSI = 'PARSI',
  JAIN = 'JAIN',
  OTHER = 'OTHER',
}

export type UserAddress = {
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  pinCode: string;
  country: string;
};

export type Contact = {
  userId: string;
  documentId: string;
  relatedTo: string;
  fullName: string;
  email?: string;
  phoneNumber?: string;
  dateOfBirth?: string;
  anniversaryDate?: string;
  religion?: Religion;
  address?: UserAddress;
  gender?: Gender;
  otherRelationship?: string;
  relationship: Relationship;
  idType?: KycType;
  idNumber?: string;
  // ?: String;
  // role: WillRoles;
  // TODO: Remove `role` everywhere.
  rolesToBeDisabled?: WillRoles[];
  roles: WillRoles[];
  secondaryBeneficiary?: SecondaryBeneficiary;
};

export type SecondaryBeneficiaryAssetDistribution = {
  assetId?: string | null;
  distribution?: Distribution[];
};

export type SecondaryBeneficiary = {
  type?: SecondaryBeneficiaryTypes;
  charityExists?: Boolean;
  charity?: Charity;
  customSplit?: SecondaryBeneficiaryAssetDistribution | null | undefined;
};

type Charity = {
  theme?: String;
  charityDetails: CharityDetails;
};

type CharityDetails = {
  organization: Organization;
};

export type Organization = {
  name: string;
  pointOfContact: string;
  phoneNumber: string;
  website: string;
  instructions: string;
  address: Address;
};

type Address = {
  addressLine1: string;
  addressLine2?: String;
  city: string;
  state?: string;
  pinCode: string;
  country?: string;
};

export enum SecondaryBeneficiaryTypes {
  LINEAL = 'LINEAL',
  PRIMARY_SPLIT = 'PRIMARY_SPLIT',
  CUSTOM = 'CUSTOM',
}

export enum Relationship {
  CHILD = 'CHILD',
  PARENT = 'PARENT',
  SPOUSE = 'SPOUSE',
  SIBLING = 'SIBLING',
  FRIEND = 'FRIEND',
  RELATIVE = 'RELATIVE',
  OTHER = 'OTHER',
}

export enum WillRoles {
  BENEFICIARY = 'BENEFICIARY',
  EXECUTOR = 'EXECUTOR',
  GUARDIAN = 'GUARDIAN',
  WITNESS = 'WITNESS',
  BACKUP_EXECUTOR = 'BACKUP_EXECUTOR',
  BACKUP_GUARDIAN = 'BACKUP_GUARDIAN',
}
// <---- Assets Types ---> START
export type Asset = {
  documentId?: string;
  currentOwner?: string;
  ownershipHistory: OwnershipHistory[];
  isClaimed: boolean;
  // assetDescription: AssetDescription;
  assetType: AssetType | undefined;
  assetSubType: AssetSubType | undefined;
  propertySubType?: string;
  natureOfHolding?: NatureOfHolding;
  jointHolderName?: string;
  nameOfFirm?: string;
  nameOfBank?: string;
  nameOfCompany?: string;
  address?: UserAddress;
  percentageOfHolding?: number;
  sizeOfProperty?: number;
  surveyNumber?: string;
  noofUnits?: string;
  noofVested?: string;
  noofunVested?: string;
  borrowerName?: string;
  loanAmount?: string;
  interestRate?: string;
  contactNumber?: string;
  loanDate?: string;
  branchName?: string;
  accountNumber?: string;
  institutionName?: string;
  folioNumber?: string;
  depositoryParticipant?: string;
  fdNumber?: string;
  policyNumber?: string;
  sumAssured?: string;
  namePostOffice?: string;
  uanNumber?: string;
  bankName?: string;
  registrationNumber?: string;
  model?: string;
  brand?: string;
  kind?: JewelleryType;
  weight?: string;
  artistName?: string;
  hpns?: string;
  description?: string;
  //   TODO: Make this an enum with different types of digital accounts.
  accountType?: string;
  typeOfInsurance?: string;
  username?: string;
  password?: string;
  assetDocuments?: AssetDocument[];
  nominees?: Nominee[];
};

export type Nominee = {
  contactId: string;
  fullName: string;
};

export type OwnershipHistory = {
  ownerId: string;
  ownerFrom: string;
  ownerTill: string;
};

export type AssetDescription = {
  assetType: AssetType;
  assetSubType: AssetSubType;
  natureOfHolding?: NatureOfHolding;
  nameOfFirm?: string;
  nameOfBank: string;
  nameOfCompany?: string;
  address?: UserAddress;
  percentageOfHolding?: number;
  sizeOfProperty?: number;
  surveyNumber?: string;
  branchName?: string;
  accountNumber?: string;
  institutionName?: string;
  folioNumber?: string;
  depositoryParticipant?: string;
  fdNumber?: string;
  policyNumber?: string;
  sumAssured?: string;
  namePostOffice?: string;
  uanNumber?: string;
  bankName?: string;
  registrationNumber: string;
  model: string;
  brand: string;
  kind: JewelleryType;
  weight: string;
  artistName: string;
  description: string;
  //   TODO: Make this an enum with different types of digital accounts.
  accountType: string;
  username: string;
  password: string;
};

export type FinancialAsset =
  | BankAccount
  | Bond
  | DematAccount
  | FixedDeposit
  | Gratuity
  | InsurancePolicy
  | MutualFund
  | PhysicalShares
  | PostalSavingAccount
  | ProvidentFund
  | PublicProvidentFund
  | WealthManagementAccount;

export type BusinessAsset = {
  type: BusinessType;
  natureOfHolding: NatureOfHolding;
  nameOfFirm: string;
  address: UserAddress;
  percentageOfHolding: number;
};

export type ImmovableAsset = {
  type: ImmovablePropertyType;
  natureOfHolding: NatureOfHolding;
  address: UserAddress;
  sizeOfProperty: number;
  surveyNumber: string;
  percentageOfHolding: number;
};

export type BankAccount = {
  type: BankAccountType;
  branchName: string;
  accountNumber: string;
  natureOfHolding: NatureOfHolding;
};

export type Bond = {
  type: BondType;
  nameOfInstitution: string;
  folioNumber: string;
  institutionName: string;
  natureOfHolding: NatureOfHolding;
};

export type DematAccount = {
  type: DematAccountType;
  depositoryParticipant: string;
  accountNumber: string;
  natureOfHolding: NatureOfHolding;
};

export type FixedDeposit = {
  fdNumber: string;
  type: BankAccountType;
  companyName: string;
  accountNumber: string;
  natureOfHolding: NatureOfHolding;
  amount: number;
};

export type Gratuity = {
  type: GratuityType;
  nameOfCompany: string;
};

export type InsurancePolicy = {
  type: InsurancePolicyType;
  policyNumber: string;
  sumAssured: string;
};

export type MutualFund = {
  type: WealthManagementAccountType;
  institutionName: string;
  accountNumber: number;
  natureOfHolding: NatureOfHolding;
};

export type PhysicalShares = {
  type: DematAccountType;
  depositoryParticipant: string;
  accountNumber: string;
  natureOfHolding: NatureOfHolding;
};

export type PostalSavingAccount = {
  type: PostalSavingAccountType;
  namePostOffice: string;
  accountNumber: string;
};

export type ProvidentFund = {
  type: ProvidentFundType;
  nameOfCompany: string;
  uanNumber: string;
};

export type PublicProvidentFund = {
  type: PublicProvidentFundType;
  nameOfBank: string;
  bankName: string;
  accountNumber: string;
};

export type WealthManagementAccount = {
  type: WealthManagementAccountType;
  institutionName: string;
  accountNumber: number;
  natureOfHolding: NatureOfHolding;
};

// <---- Assets Types ---> END

// <---- Assets Enums ---> START
export enum AssetType {
  IMMOVABLE_PROPERTY = 'IMMOVABLE_PROPERTY',
  BUSINESS = 'BUSINESS',
  FINANCIAL = 'FINANCIAL',
  OTHER = 'OTHER',
  DIGITAL = 'DIGITAL',
}

export enum OtherAssetType {
  VEHICLE = 'VEHICLE',
  JEWELLERY = 'JEWELLERY',
  WATCH = 'WATCH',
  PAINTING = 'PAINTING',
  // DIGITAL_ASSET = 'DIGITAL_ASSET',
  OTHER = 'OTHER',
}

// export enum DigitalAssetType{
//   UPI = 'UPI',
//   CRYPTO = 'CRYPTO'
// }

export enum BusinessType {
  PROPRIETORSHIP = 'PROPRIETORSHIP',
  PROPRIETORSHIP_FIRM = 'PROPRIETORSHIP FIRM',
  LLP = 'LLP',
  COMPANY = 'COMPANY',
}

export enum FinancialAssetType {
  SAVINGS_ACCOUNT = 'SAVINGS ACCOUNT',
  CURRENT_ACCOUNT = 'CURRENT ACCOUNT',
  SAFE_DEPOSIT = 'SAFE DEPOSIT',
  DEMAT_ACCOUNT = 'DEMAT ACCOUNT',
  PUBLIC_PROVIDENT_FUND = 'PUBLIC PROVIDENT FUND',
  PROVIDENT_FUND = 'PROVIDENT FUND',
  GRATUITY = 'GRATUITY',
  INSURANCE_POLICY = 'INSURANCE POLICY',
  FIXED_DEPOSIT = 'FIXED DEPOSIT',
  BOND_TYPE = 'BOND TYPE',
  PHYSICAL_SHARES = 'PHYSICAL SHARES',
  WEALTH_MANAGEMENT_ACCOUNT = 'WEALTH MANAGEMENT ACCOUNT',
  POSTAL_SAVING_ACCOUNT = 'POSTAL SAVING ACCOUNT',
}

export enum ImmovablePropertyType {
  COMMERCIAL = 'COMMERCIAL',
  RESIDENTIAL_PLOT = 'RESIDENTIAL PLOT',
  COMMERCIAL_BUILDING = 'COMMERCIAL BUILDING',
  RESIDENTIAL_APARTMENT = 'RESIDENTIAL APARTMENT',
  RESIDENTIAL_VILLA = 'RESIDENTIAL VILLA',
  RESIDENTIAL_BUNGALOW = 'RESIDENTIAL BUNGALOW',
  AGRICULTURAL_LAND = 'AGRICULTURAL LAND',
}

export enum AssetSubType {
  PROPRIETORSHIP = 'PROPRIETORSHIP',
  LLP = 'LLP',
  COMPANY = 'COMPANY',
  PARTNERSHIP_FIRM = 'PARTNERSHIP_FIRM',
  COMMERCIAL = 'COMMERCIAL',
  RESIDENTIAL_PLOT = 'RESIDENTIAL_PLOT',
  COMMERCIAL_BUILDING = 'COMMERCIAL_BUILDING',
  RESIDENTIAL_PROPERTY = 'RESIDENTIAL_PROPERTY',
  COMMERCIAL_PROPERTY = 'COMMERCIAL_PROPERTY',
  AGRICULTURAL_LAND = 'AGRICULTURAL_LAND',
  SAVINGS_ACCOUNT = 'SAVINGS_ACCOUNT',
  BANK_ACCOUNT = 'BANK_ACCOUNT',
  CURRENT_ACCOUNT = 'CURRENT_ACCOUNT',
  SAFE_DEPOSIT = 'SAFE_DEPOSIT',
  DEMAT_ACCOUNT = 'DEMAT_ACCOUNT',
  PUBLIC_PROVIDENT_FUND = 'PUBLIC_PROVIDENT_FUND',
  PROVIDENT_FUND = 'PROVIDENT_FUND',
  GRATUITY = 'GRATUITY',
  INSURANCE_POLICY = 'INSURANCE_POLICY',
  FIXED_DEPOSIT = 'FIXED_DEPOSIT',
  BOND_TYPE = 'BOND_TYPE',
  PHYSICAL_SHARES = 'PHYSICAL_SHARES',
  WEALTH_MANAGEMENT_ACCOUNT = 'WEALTH_MANAGEMENT_ACCOUNT',
  POSTAL_SAVING_ACCOUNT = 'POSTAL_SAVING_ACCOUNT',
  CRYPTO_PORTFOLIO = 'CRYPTO_PORTFOLIO',
  VEHICLE = 'VEHICLE',
  JEWELLERY = 'JEWELLERY',
  WATCH = 'WATCH',
  PAINTING = 'PAINTING',
  DIGITAL_ASSET = 'DIGITAL_ASSET',
  OTHER = 'OTHER',
  HUF = 'HUF',
  DIGITAL_GUARDIANS = 'DIGITAL_GUARDIANS',
  MUTUAL_FUNDS = 'MUTUAL_FUNDS',
  CRYPTO_PORTOFOLIO = 'CRYPTO_PORTOFOLIO',
  ELECTRONIC_WALLET = 'ELECTRONIC_WALLET',
  BROKING_ACCOUNT = 'BROKING_ACCOUNT',
  BONDS = 'BONDS',
  DEBENTURES = 'DEBENTURES',
  AIFS = 'AIFS',
  PMS = 'PMS',
  ELECTRONIC_INSURANCE = 'ELECTRONIC_INSURANCE',
  OTHER_FINANCIAL_ASSET = 'OTHER_FINANCIAL_ASSET',
  NATIONAL_PENSION_SCHEME = 'NATIONAL_PENSION_SCHEME',
}

export enum NatureOfHolding {
  SOLE = 'SOLE',
  JOINT = 'JOINT',
  SINGLE = 'SINGLE',
}

export enum BankAccountType {
  SAVINGS_ACCOUNT = 'SAVINGS_ACCOUNT',
  CURRENT_ACCOUNT = 'CURRENT_ACCOUNT',
  SAFE_DEPOSIT = 'SAFE_DEPOSIT',
  FIXED_DEPOSIT = 'FIXED_DEPOSIT',
}

export enum BondType {
  BOND_TYPE = 'BOND_TYPE',
}

export enum DematAccountType {
  DEMAT_ACCOUNT = 'DEMAT_ACCOUNT',
}

export enum GratuityType {
  GRATUITY = 'GRATUITY',
}

export enum InsurancePolicyType {
  INSURANCE_POLICY = 'INSURANCE_POLICY',
}

export enum PublicProvidentFundType {
  PUBLIC_PROVIDENT_FUND = 'PUBLIC_PROVIDENT_FUND',
}

export enum ProvidentFundType {
  PROVIDENT_FUND = 'PROVIDENT_FUND',
}

export enum PhysicalSharesType {
  PHYSICAL_SHARES = 'PHYSICAL_SHARES',
}
export enum WealthManagementAccountType {
  WEALTH_MANAGEMENT_ACCOUNT = 'WEALTH_MANAGEMENT_ACCOUNT',
}
export enum PostalSavingAccountType {
  POSTAL_SAVING_ACCOUNT = 'POSTAL_SAVING_ACCOUNT',
}

export enum JewelleryType {
  GOLD = 'GOLD',
  SILVER = 'SILVER',
  BRONZE = 'BRONZE',
  PLATINUM = 'PLATINUM',
}

// <---- Assets Enums ---> END

// <----- Liabilities --> START
export type Liability = {
  documentId?: string | null;
  currentOwner?: string;
  liabilityType?: LiabilityType;
  liabilitySubType?: LiabilitySubType;
  liabilityDetails?: LiabilityDetails;
};

export type LiabilityDetails = {
  detailsOfInstitution: string;
  accountNumber: string;
  loanAmount: string;
  description: string;
};

export enum LiabilityType {
  LOAN = 'LOAN',
}

export enum LiabilitySubType {
  HOME_LOAN = 'HOME_LOAN',
  VEHICLE_LOAN = 'VEHICLE_LOAN',
  PERSONAL_LOAN = 'PERSONAL_LOAN',
}

export type WillLiabilityTypes = {
  [LiabilityType.LOAN]: Array<LiabilitySubType>;
};

// <----- Liabilities --> END

// WILL ------> START

type AssetDocument = {
  documentName: string;
  tags: string[];
  documentUrls: string[];
  currentOwner: string;
  assetType: AssetType;
  assetSubType: AssetSubType;
  assetDocumentId?: string;
};

export type Will = {
  documentId: string;
  type: string;
  dateOfCreation?: string;
  updatedOn?: string;
  generatedWill?: boolean;
  courierRequestInfo?: PostWillGeneration;
  willCompletionProgress?: number;
  will_progress?: Array<WILL_CREATION_STATES>;
  dateOfSigning?: string;
  testatorDetails?: TestatorDetails;
  assetTypes?: WillAssetTypes;
  liabilityTypes?: WillLiabilityTypes;
  assets?: Array<Asset>;
  guardians?: Guardian;
  backupGuardians?: Array<string>;
  executor?: string;
  backupExecutors?: Array<string>;
  liabilities?: Array<Liability>;
  beneficiaries?: Array<Contact>;
  witnesses?: Array<string>;
  paymentInfo?: PaymentInfo;
  assetDistribution?: AssetDistribution[];
  residuaryDistribution?: ResiduaryDistribution;
  organizations?: Organization[];
  isWillUpdated?: boolean;
  isFinalWillGenerated?: boolean;
  isAmongFirstCustomer?: boolean;
  funeralArrangement?: FuneralArrangement;
  willLocation?: WillLocation;
  signingDocuments?: SigningDocuments;
  isDistributionModified?: boolean;
};

export type FuneralArrangement = {
  religion: Religion;
  howToConduct: string;
};

export type PaymentInfo = {
  paidWill: Boolean;
  gracePeriod: String;
  paymentId: String;
};

export type ResiduaryDistribution = {
  distribution: Distribution[];
};

export type WillLocation = {
  address: Address;
  nameOfProperty: string;
  additionalInstructions: string;
  notifyExecutor?: Boolean;
  latLong?: string;
};

export type UpdateWill = {
  documentId: string;
  type: string;
  dateOfCreation?: string;
  updatedOn?: string;
  generatedWill?: boolean;
  courierRequestInfo?: PostWillGeneration;
  willCompletionProgress?: number;
  will_progress?: Array<WILL_CREATION_STATES>;
  dateOfSigning?: string;
  testatorDetails?: TestatorDetails;
  assetTypes?: WillAssetTypes;
  liabilityTypes?: WillLiabilityTypes;
  assets?: Asset;
  guardians?: Guardian;
  backupGuardians?: Array<string>;
  executor?: Contact | null;
  backupExecutors?: Array<Contact>;
  liabilities?: Liability;
  beneficiaries?: Array<Contact>;
  witnesses?: Array<Contact>;
  assetDistribution?: AssetDistribution[];
  residuaryDistribution?: ResiduaryDistribution;
  organizations?: Organization[];
  isWillUpdated?: boolean;
  willLocation?: WillLocation;
  isDistributionModified?: boolean;
};

export type Guardian = {
  guardian: string | null;
  children: string[];
};

export type TestatorDetails = {
  userId: string;
  fullName: string;
  dateOfBirth?: string;
  phoneNumber?: string;
  email?: string;
  gender?: Gender;
  address?: UserAddress;
  religion?: Religion;
  anniversaryDate?: string;
  fatherName?: string;
};

export type MailingDetails = {
  userId: string;
  fullName: string;
  phoneNumber?: string;
  email?: string;
  address?: UserAddress;
};

export type WillAssetTypes = {
  BUSINESS: BusinessType[];
  FINANCIAL: FinancialAssetType[];
  IMMOVABLE_PROPERTY: ImmovablePropertyType[];
  OTHER: OtherAssetType[];
  DIGITAL: DigitalAssetType[];
};

export enum DigitalAssetType {
  CRYPTO_PORTFOLIO = 'CRYPTO_PORTFOLIO',
}

export type WillPdfInput = {
  willId: string;
  forPreview: boolean;
  webPreview?: boolean;
};

export enum WillActions {
  SELECT_PEOPLE = 'SELECT_PEOPLE',
  ADD_PERSON = 'ADD_PERSON',
  CONFIRM_PEOPLE = 'CONFIRM_PEOPLE',
  COMPLETED = 'COMPLETED',
}

export enum WillTypes {
  FREE_WILL = 'FREE_WILL',
  PREMIUM_WILL = 'PREMIUM_WILL',
}

export enum WILL_CREATION_STATES {
  BASIC_DETAILS_FILLED = 'BASIC_DETAILS_FILLED',
  ASSETS_ADDED = 'ASSETS_ADDED',
  ASSET_DETAILS_ADDED = 'ASSET_DETAILS_ADDED',
  LIABILITIES_ADDED = ' LIABILITIES_ADDED',
  BENEFICIARIES_ADDED = 'BENEFICIARIES_ADDED',
  GUARDIANS_ADDED = 'GUARDIANS_ADDED',
  EXECUTOR_ADDED = 'EXECUTOR_ADDED',
  WITNESSES_ADDED = 'WITNESSES_ADDED',
}

// WILL -----> END

// CONTENT ---> START
export type Content = {
  recommended: Recommended;
  faqs: Array<Faq>;
  articles: Array<Article>;
  videos: Array<Video>;
  sampleWills: SampleWills;
  categories: Array<Category>;
};

export type Category = {
  id: string;
  orderOfAppearance: number;
  icon: string;
  title: string;
  description: string;
  type: ContentTypes;
};

export type Recommended = {
  faqs: Array<Faq>;
  articles: Array<Article>;
  videos: Array<Video>;
};

export enum ContentTypes {
  FAQ = 'FAQ',
  ARTICLE = 'ARTICLE',
  VIDEO = 'VIDEO',
  CATEGORY = 'CATEGORY',
}

export type Faq = {
  id: string;
  categoryIds: string[];
  type: ContentTypes.FAQ;
  question: string;
  answer: RichText[][];
};

export type Article = {
  id: string;
  categoryIds: string[];
  tagIds: string[];
  dateOfUpdation: string;
  type: ContentTypes.ARTICLE;
  title: string;
  draft: boolean;
  body: RichText[][];
  readTime: string;
  summary: string;
  author: string;
  authorId: string;
  slug: string;
  mainImage: string;
  featureOnWeb: boolean;
  featureOnMobile: boolean;
};

export type RichText = {
  type: RICH_TEXT_TYPES;
  text: string;
  image: string;
  styles: Array<RICH_TEXT_FONT_STYLES>;
  primaryStyle: RICH_TEXT_PRIMARY_STYLES;
};

export type Video = {
  id: string;
  type: ContentTypes.VIDEO;
  title: string;
  videoUrl: string;
};

export type SampleWills = {
  freeWill: string;
  paidWill: string;
};

export enum RICH_TEXT_TYPES {
  TEXT = 'TEXT',
  IMAGE = 'IMAGE',
}

export enum RICH_TEXT_PRIMARY_STYLES {
  h1 = 'h1',
  normal = 'normal',
}

export enum RICH_TEXT_FONT_STYLES {
  strong = 'strong',
  em = 'em',
}

// CONTENT ---> END

// POST WILL GENERATION ---> START
export type PostWillGeneration = {
  id: string;
  userId: string;
  name: string;
  phoneNumber: string;
  email: string;
  mailingAddress: UserAddress;
  status: CourierStatus;
  dateOfCreation: DateAndTimeOfCreationByCourierStatus[];
  expectedDateOfDelivery?: string;
  indiaPostTrackingID: string;
};

export type DateAndTimeOfCreationByCourierStatus = {
  status: CourierStatus;
  date: string;
  time: string;
};

export enum CourierStatus {
  REQUESTED = 'REQUESTED',
  REQUEST_RECEIVED = 'REQUEST_RECEIVED',
  READY_FOR_DISPATCH = 'READY_FOR_DISPATCH',
  ON_THE_WAY = 'ON_THE_WAY',
  DELIVERED = 'DELIVERED',
  READY_FOR_SHIPMENT = 'READY_FOR_SHIPMENT',
  SHIPPED = 'SHIPPED',
}
// POST WILL GENERATION ---> END

// Input Types --> START
export type EditProfileInputs = {
  fullName: string;
  email: string;
  gender?: Gender;
  phoneNumber?: string;
  dateOfBirth: string;
  isNRI?: boolean;
  primaryAddress?: UserAddress;
  mailingAddress?: UserAddress;
};

export type FuneralArrangementInputs = {
  religion: Religion;
  howToConduct: string;
};

export type ContactUsInputs = {
  name: string;
  email: string;
  message: string;
};

export type OnBoardingInputs = {
  email?: string;
  dateOfBirth?: string;
  fullName?: string;
};

export type AssetCreationInput = Asset;

export type AddContactInputs = Contact;

export type TestatorDetailsInputs = TestatorDetails;

export type PrintAndCourierDetails = {
  name: string;
  phoneNumber: string;
  email: string;
  mailingAddress: UserAddress;
};

export type FormRegisterInputs =
  | ContactUsInputs
  | EditProfileInputs
  | AssetCreationInput
  | Contact
  | TestatorDetails
  | PrintAndCourierDetails
  | OnBoardingInputs
  | PostWillGeneration
  | CartAddOnForOthers
  | FuneralArrangementInputs
  | WillLocation;

export enum HTMLInputTypes {
  EMAIL = 'email',
  FILE = 'file',
  TEXT = 'text',
  DATE = 'date',
  PASSWORD = 'password',
}

export enum HTMLButtonTypes {
  BUTTON = 'button',
  SUBMIT = 'submit',
  RESET = 'reset',
}

export type InputProps = {
  type: HTMLInputTypes;
  register: UseFormRegister<FormRegisterInputs>;
  errors?: FieldErrors<any>;
  errorMessage?: string;
  fieldToRegister: any;
  placeHolder: string;
  required?: ValidationRule<boolean>;
  pattern?: ValidationRule<RegExp>;
  maxDate?: string;
  minorValid?: boolean;
  disabled?: boolean;
  getValues?: UseFormGetValues<FormRegisterInputs>;
  watch?: UseFormWatch<FormRegisterInputs>;
  onChangeValue?: Function;
  useFormRegisterConfig?: any;
  defaultValue?: any;

  readOnly?: boolean;
};

export type SelectProps = {
  placeHolder: string;
  hidePlaceHolder?: boolean;
  selectOptions: any;
  register: UseFormRegister<FormRegisterInputs>;
  getValues?: UseFormGetValues<FormRegisterInputs>;
  errors?: FieldErrors<any>;
  errorMessage?: string;
  fieldToRegister: any;
  required?: ValidationRule<boolean>;
  pattern?: ValidationRule<RegExp>;
  onChangeEvent?: Function | null;
  disabled?: boolean;
  value?: string | number | readonly string[] | undefined;
  labelStyle?: React.CSSProperties | undefined;
  defaultValue?: string;
};

// FormBuilder related types
export type AssetsJSON = Array<MainAsset>;

interface Assett {
  id: string | null;
  name: string;
  icon: string;
  description: string;
}

interface MainAsset extends Assett {
  assetType: string | null;
  subAssets: (SubAsset | SubAssetWithSubSubAsset)[];
}

interface SubAsset extends Assett {
  subAssetType: string;
  formFields: (StandardField | DropDownField)[];
}

interface SubAssetWithSubSubAsset extends Assett {
  subAssetType: string;
  subSubAssets: Array<SubSubAsset>;
}

interface SubSubAsset extends Assett {
  subSubAssetType: string;
  formFields: (StandardField | DropDownField)[];
}

export type Field = StandardField | DropDownField;

export interface StandardField {
  fieldType: string;
  placeholder: string | null;
  isRequired: boolean;
  validation?: Validation;
  graphqlVariableName: string;
  extraFields?: Array<ExtraField>;
  desc?: string;
}

export interface DropDownField extends StandardField {
  fieldType: 'dropdown';
  dropdownOptions: Array<DropDownOptions>;
}

type DropDownOptions = {
  value: string;
  label: string;
};

type ExtraField = {
  value: string;
  fields: (StandardField | DropDownField)[];
};

export enum Validation {
  AlphaNumeric,
  Text,
  Numeric,
  PinCode,
  PhoneNumber,
  URL,
  Float,
  Decimal,
  Year,
  Date,
  AlphaNumericWithDot,
  Universal,
}

export enum WILL_TYPES {
  FREE_WILL = 'FREE_WILL',
  PREMIUM_WILL = 'PREMIUM_WILL',
}
export type PaymentInput = {
  amount?: Number;
  willType?: WILL_TYPES;
  paymentReason?: String;
  willId?: String;
};

export type PaymentSignatureInput = {
  razorpayPaymentId: String;
  razorpayOrderId: String;
  razorpaySignature: String;
  receiptId: String;
};

export type DeleteAssetInput = {
  documentId: String;
  willId: String;
};

export type AssetDistribution = {
  assetId: string | undefined;
  distribution: Distribution[];
};

export type Distribution = {
  beneficiaryId: string;
  beneficiaryName: string;
  relationship: Relationship;
  percentageShare: string;
};

export enum MyPeoplOverlayContext {
  GUARDIAN,
  EXECUTOR,
  WITNESS,
  BACKUP_GUARDIAN,
  BACKUP_EXECUTOR,
  DISTRIBUTION,
  SECONDARY_BENEFICIARY,
  NOMINEE,
  CHILDREN,
}

export interface RoleStatus {
  roleType?: WillRoles;
  isApproved?: boolean;
  assignedOn: string;
}

export interface MyRoles {
  documentId?: string;
  assigneePhoneNumber: string;
  assignorUserId: string;
  assignorName: string;
  assignedRoles?: RoleStatus[];
  isInvited?: boolean;
}

export interface MyRolesInput {
  documentId?: string;
  assigneePhoneNumber: string;
  assignorUserId: string;
  assignorName: string;
  roleStatus?: RoleStatus;
}

export interface UserNotification {
  type?: string;
  title: string;
  body: string;
  imageUrl?: string;
  payload?: string;
  expireAt?: string;
  triggerAt?: string;
  navigateTo?: string;
  sent?: boolean;
}