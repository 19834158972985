import CustomButton from 'common/Reusables/Button';
import MuiDrawer from 'common/Reusables/Drawer';
import CustomTextArea from 'common/Reusables/Inputs/CustomTextArea';
import SelectField from 'common/Reusables/Select';
import { UPDATE_USER_WILL } from 'common/queries';
import { useEffect, useState } from 'react';
import { UseFormRegister, useForm } from 'react-hook-form';
import { useAppSelector, useTypedDispatch } from 'store/hooks';
import { ToastMessage, ToastType, showToast } from 'store/toast/slice';
import { selectActiveWill } from 'store/user/selectors';
import {
  addToUserStatus,
  fetchUserProfileAction,
  updateActiveWill,
  UserStatus,
} from 'store/user/slice';
import styled from 'styled-components';
import { runMutation } from 'utils/graphQL';
import {
  FormRegisterInputs,
  FuneralArrangementInputs,
  Religion,
  Will,
  WillTypes,
} from 'utils/types';
import SaveDetailsFloatingButton from './CreateWill/SaveDetailsFloatingButton';
import { checkBasicConditionsToGenerateWill, getWillStatus } from '../../utils';
import CloseIcon from '@mui/icons-material/Close';

const Wrapper = styled.div`
  padding: 30px 20px;
`;

type FuneralArrangementsProps = {
  open: boolean;
  hideDrawer: Function;
  isPendingKYC: boolean;
  setShowKYCModal: Function;
};

enum FuneralArrangementsScreen {
  Intro,
  Form,
}

const exampleTextMapping: Array<{ religion: Religion; text: string }> = [
  {
    religion: Religion.HINDU,
    text: 'I would like to be cremated, with my funeral ceremony taking place in Tirupati temple. I would like my daughter, Priya, to perform my last rites, and my ashes to be immersed in the Ganga.',
  },
  {
    religion: Religion.MUSLIM,
    text: 'I would like to be buried in Ajmer Sharif Dargah. I would want there to be just a 3-day mourning period for me, not 20 or 40 days.',
  },
  {
    religion: Religion.CHRISTIAN,
    text: 'I would like to be buried, not cremated. I’d like to have candles lit throughout the day and night based on my age, celebrating my time on earth.',
  },
  {
    religion: Religion.PARSI,
    text: 'I would like my body to be taken to the Malabar Hill Tower of Silence. I do not want an expensive funeral; instead, I would like that money to be donated to xxx charity.',
  },
  {
    religion: Religion.JAIN,
    text: 'I would like my body to be cremated and my ashes to be kept as a memento in an urn in our ancestral home.',
  },
  {
    religion: Religion.SIKH,
    text: 'I would like to be cremated, and for my funeral ceremony to take place in the local gurudwara. I want 500 needy people to be fed langar at my funeral. Please recover the cost of the langar from my assets.',
  },
  {
    religion: Religion.OTHER,
    text: 'I would like to have a non-religious funeral service. Instead, I would like to have my favourite songs, poems, and readings play a prominent part in the service.',
  },
];

const IntroScreen = ({
  setCurrentScreen,
  hideDrawer,
  isPendingKYC,
  setShowKYCModal,
}: {
  setCurrentScreen: Function;
  hideDrawer: Function;
  isPendingKYC: boolean;
  setShowKYCModal: Function;
}) => {
  return (
    <>
      <p className='subText font-semi-bold contactLabel'>Funeral Arrangements</p>
      <>
        <div style={{ marginTop: '16px', marginBottom: '16px' }}>
          <p>
            Let your loved ones know how you would like your funeral to be conducted, if you have
            any special wishes.
          </p>
          <p style={{ marginTop: '32px' }}>
            The instructions you provide here will be sent to your Executor in the event of your
            death, so they will be able to act accordingly.
          </p>
          <p style={{ marginTop: '8px', color: 'gray' }}>
            Please note, you may also access this section after your Will has been generated, if you
            would prefer to do it later.
          </p>
          <p style={{ marginTop: '32px' }}>
            <b>Do you want to set up instructions for your funeral arrangements now?</b>
          </p>
        </div>
        <CustomButton
          style={{ width: '100%' }}
          text={'Set up now'}
          className='continueWillBtn'
          onClick={() => setCurrentScreen(FuneralArrangementsScreen.Form)}
        />
        <CustomButton
          fontColor='black'
          backgroundColor='white'
          style={{ width: '100%', border: '2px solid black', marginTop: '16px' }}
          text={'Do it later'}
          className='continueWillBtn'
          onClick={() => {
            if (isPendingKYC) {
              setShowKYCModal(true);
              hideDrawer();
            } else {
              hideDrawer();
            }
          }}
        />
      </>
    </>
  );
};

const FormScreen = ({
  setCurrentScreen,
  hideDrawer,
  isPendingKYC,
  setShowKYCModal,
}: {
  setCurrentScreen: Function;
  hideDrawer: Function;
  isPendingKYC: boolean;
  setShowKYCModal: Function;
}) => {
  const userWill = useAppSelector(selectActiveWill);
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    // reset,
    // clearErrors,
    // resetField,
  } = useForm<FuneralArrangementInputs>({
    defaultValues: userWill?.funeralArrangement,
  });

  const [selectedReligion, setSelectedReligion] = useState<Religion>();
  const dispatch = useTypedDispatch();

  const submitForm = async (data: FuneralArrangementInputs) => {
    try {
      const updateWillRequestBody: Will = {
        funeralArrangement: data,
        documentId: userWill?.documentId || ('' as string),
        type: WillTypes.PREMIUM_WILL,
      } as Will;

      const {
        data: { updateWill: updatedWill },
      }: any = await runMutation({
        mutation: UPDATE_USER_WILL,
        variables: {
          key: 'input',
          value: updateWillRequestBody,
        },
      });

      await new Promise((resolve, reject) => {
        dispatch(fetchUserProfileAction(undefined, resolve, reject));
      });

      if (updatedWill) {
        dispatch(
          updateActiveWill({
            will: updatedWill,
          }),
        );
      }

      console.log(
        'in WillGuardians, in updateUserWill, getWillStatus(updatedWill) is: ' +
          getWillStatus(updatedWill),
      );

      console.log(
        'in WillGuardians, in updateUserWill, checkBasicConditionsToGenerateWill(updatedWill) is: ' +
          checkBasicConditionsToGenerateWill(updatedWill),
      );

      if (getWillStatus(updatedWill) && checkBasicConditionsToGenerateWill(updatedWill)) {
        dispatch(addToUserStatus(UserStatus.ACTION_SET_WILL_CREATION_COMPLETED));
      }

      const toast: ToastMessage = {
        type: ToastType.SUCCESS,
        message: 'Successful',
      };
      dispatch(showToast(toast));
      // if (isPendingKYC) {
      //     setShowKYCModal(true)
      // }

      hideDrawer();
    } catch (error) {
      const toast: ToastMessage = {
        type: ToastType.ERROR,
        message: 'Error submitting form. Please try again in sometime.',
      };
      dispatch(showToast(toast));
    }
  };

  return (
    <>
      <div className='flex items-center justify-between'>
        <p className='subText font-semi-bold contactLabel'>Funeral Arrangements</p>
        <div className='cursor-pointer' onClick={(e) => hideDrawer()}>
          <CloseIcon />
        </div>
      </div>
      <div style={{ marginTop: '32px' }}>
        <p>
          Your Will Executor will ensure that the following instructions are carried out as per your
          wishes
        </p>
        <h3 style={{ marginTop: '16px', marginBottom: '16px', fontSize: '20px' }}>Type of religious custom</h3>
        <form onSubmit={handleSubmit(submitForm)}>
          <SelectField
            selectOptions={Religion}
            hidePlaceHolder={true}
            placeHolder='Religion'
            fieldToRegister='religion'
            register={register as UseFormRegister<FormRegisterInputs>}
            required={true}
            errors={errors}
            errorMessage='Please select a religion'
            onChangeEvent={(ev: string) => {
              // console.log(ev);
              // console.log(exampleTextMapping.filter((it) => it.religion == (ev as Religion)));
              setSelectedReligion(ev as Religion);
            }}
            /*
                        // @ts-ignore*/
            getValues={getValues}
          />
          <h3 style={{ marginTop: '16px', marginBottom: '16px', fontSize: '20px' }}>Funeral Arrangements</h3>
          {selectedReligion && (
            <div>e.g. {exampleTextMapping.find((it) => it.religion == selectedReligion)?.text}</div>
          )}
          <CustomTextArea
            hidePlaceHolder={true}
            placeHolder='Funeral Arrangements'
            fieldToRegister='howToConduct'
            register={register as UseFormRegister<FormRegisterInputs>}
            required={true}
            /*
                        // @ts-ignore*/
            getValues={getValues}
          />
          <SaveDetailsFloatingButton
            width={window.innerWidth <= 768 ? '100%' : 500}
            btnText='Done'
            onClick={() => {
              (window as any).webengage.track("Funeral Arrangements added",{
                "Added Funeral Arrangements": "yes"
              });
            }}
          />
        </form>
      </div>
    </>
  );
};

export const FuneralArrangements = ({
  open,
  hideDrawer,
  isPendingKYC,
  setShowKYCModal,
}: FuneralArrangementsProps) => {
  const [currentScreen, setCurrentScreen] = useState(FuneralArrangementsScreen.Intro);
  const userWill = useAppSelector(selectActiveWill);

  useEffect(() => {
    if (userWill?.funeralArrangement !== undefined || userWill?.funeralArrangement !== null) {
      setCurrentScreen(FuneralArrangementsScreen.Form);
    }
  }, [userWill]);

  return (
    <MuiDrawer width={'min(100%, 500px)'} open={open} hideDrawer={hideDrawer}>
      <Wrapper>
        {currentScreen === FuneralArrangementsScreen.Intro && (
          <IntroScreen
            setCurrentScreen={setCurrentScreen}
            hideDrawer={hideDrawer}
            isPendingKYC={isPendingKYC}
            setShowKYCModal={setShowKYCModal}
          />
        )}
        {currentScreen === FuneralArrangementsScreen.Form && (
          <FormScreen
            setCurrentScreen={setCurrentScreen}
            hideDrawer={hideDrawer}
            isPendingKYC={isPendingKYC}
            setShowKYCModal={setShowKYCModal}
          />
        )}
      </Wrapper>
    </MuiDrawer>
  );
};
