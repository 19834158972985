import { Grid } from '@mui/material';
import CustomButton from 'common/Reusables/Button';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import theme from 'styles/theme';
import { pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
// import dummy from 'assets/dummy.pdf';
// import SampleFreeWill from 'assets/SampleFreeWill.pdf';
// Icons for Summary Cards

import { useAppSelector, useTypedDispatch } from 'store/hooks';
import {
  selectActiveWill,
  selectConfig,
  selectIsGuardianShow,
  selectUserProfile,
  selectUserStatus,
  selectCurrentUserPlans,
} from 'store/user/selectors';
import { getWillProgressPercentage } from 'utils';
import { useNavigate } from 'react-router-dom';
import { showToast, ToastMessage, ToastType } from 'store/toast/slice';
import PreviewWill, { getWillPDF } from './CreateWill/PreviewWill';
import { Will, WILL_CREATION_STATES, WillTypes } from 'utils/types';
import { runMutation } from 'utils/graphQL';
import { UPDATE_USER_WILL } from 'common/queries';
import {
  fetchConfig,
  fetchUserProfileAction,
  updateActiveWill,
  UserStatus,
} from 'store/user/slice';
import useAnalyticsEventTracker, { EVENT_CATEGORIES } from 'common/GoogleAnalytics';
import DetailsModal from './DetailsModal';
// import NotifyBlock from './NotifyBlock';
import UpdateWillModal from './UpdateWillModal';
import { Pages } from 'routes';
import { Mixpanel } from 'utils/mixpanel';
import { Intercom } from 'utils/intercom';
import { useGenerateWill } from 'common/useGenerateWill';
import { breakpoints } from '../../styles/Breakpoints';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { cardsGlobal } from '../../common/CardThemes';
import { SectionSummaryScreens } from '../../common/SectionScreenSummary';
import { useRedirectToPaymentOrMyWill } from 'common/useRedirectToPaymentOrMyWill';
import { useVendor } from 'common/useVendor';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export const SectionSummaryWrapper = styled.div`
  // padding: 40px 40px 0;

  .sectionSummaryPadding {
    padding: 0 40px 40px;
    @media screen and (max-width: ${breakpoints.md}) {
      padding: 0;
      max-width: 100vw;
      height: auto;
      flex-basis: 100%;
      display: flex;
      flex-direction: column;
    }
  }

  .actions {
    @media screen and (max-width: ${breakpoints.md}) {
      display: none;
    }
  }

  .successMessage {
    width: 100%;
    padding: 20px;
    padding-left: 180px;
    padding-right: 180px;
    background-color: #ffeda5;
    color: black;
    align-items: center;
    font-weight: bold;
    font-size: 25px;
    justify-content: space-between;
    flex-direction: row;
    box-sizing: border-box;
    .priceButton {
      display: flex;
      align-items: center;
      p {
        padding-left: 15px;
        padding-right: 25px;
        font-size: 16px;
        font-weight: bold;
        @media screen and (max-width: ${breakpoints.md}) {
          padding: 0;
          font-weight: normal;
          color: rgba(16, 24, 40, 0.48);
        }
      }
      @media screen and (max-width: ${breakpoints.md}) {
        width: 100%;
        flex-direction: column !important;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 12px;
      }
    }

    @media screen and (max-width: ${breakpoints.md}) {
      font-size: 16px;
      background-color: #ffffff;
      border-radius: 16px;
      box-shadow: 0px -4px 45px -8px rgba(0, 0, 0, 0.51);
      z-index: 3 !important;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      padding: 20px;
      gap: 10px;
    }

    .priceText {
      @media screen and (max-width: ${breakpoints.md}) {
        color: rgba(16, 24, 40, 0.48);
        font-size: 16px;
      }
    }
  }

  .skipActionArea {
    width: 180px;
    height: 45px;
    border: 2px solid ${(props) => props.theme.primaryColor};
    border-radius: 5px;
    margin-bottom: 20px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .proceedBtn {
    width: 200px !important;
    margin-left: 20px;
    @media screen and (max-width: ${breakpoints.md}) {
      width: 100%;
      min-width: 100%;
      margin-left: 0px;
      display: block;
    }
  }

  .action_button_holder {
    display: flex;
    margin-bottom: 30px;

    .continueWillBtn {
      width: 180px;
      margin-left: 20px;
      @media screen and (max-width: ${breakpoints.md}) {
        width: 100%;
        min-width: 100%;
        margin-left: 0px;
      }
    }

    @media screen and (max-width: ${breakpoints.md}) {
      box-sizing: border-box;
      padding: 0px;
      margin: 24px;
      // height: 70px;
      display: block;
      position: sticky;
      bottom: 24px;
      z-index: 9999;
    }
  }

  .sectionSummary_container {
    margin-top: 20px;

    .summary_cards_holder {
      position: relative;
      width: 100%;
      margin-top: 30px;
      // padding-bottom: 100px;

      @media screen and (max-width: ${breakpoints.md}) {
        flex-wrap: nowrap;
        margin-top: 0;
        height: min-content;
        overflow-x: scroll;
        overflow-y: hidden;
        max-width: 100vw;
        padding-bottom: 5px;
      }

      .summary_card {
        @media screen and (max-width: ${breakpoints.md}) {
          min-width: 80vw;
          width: 80vw;
          margin-top: 0;
        }
      }
    }

    @media screen and (max-width: ${breakpoints.md}) {
      max-width: 100%;
      height: min-content;
      margin-top: 0;
      overflow: hidden;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  .summary {
    min-height: min(50vh, 500px);
    margin: 10px 10px 10px;
  }
  .illustrationImage {
    width: 100%;
  }
  .myWill-subtext-font {
    font-size: 24px;
  }

  @media screen and (max-width: ${breakpoints.md}) {
    .main_container {
      display: flex;
      flex-direction: column;
      // height: 80dvh;
      justify-content: space-between;
    }
  }
`;

export const StyledSummaryCardGrid = styled(Grid)`
  cursor: pointer;
  height: 70vh;
  @media screen and (max-width: ${breakpoints.md}) {
    height: 60vh;
  }
  margin: 0 10px;
  padding: 25px 20px 20px;
  border-radius: 12px;
  margin-bottom: 20px;
  box-sizing: border-box;

  background-color: ${(props: { backgroundColor: string }) => props.backgroundColor};

  .pdfPreview {
    max-height: 100%;
  }

  .head {
    .icon {
      height: 18px;
      width: 18px;
    }
  }

  /* .step_counter {
    position: relative;
    top: -8px;
  } */

  .illustrationImageHolder {
    height: 50%;
    padding: 15px;
    box-sizing: border-box;
    @media screen and (max-width: ${breakpoints.md}) {
      width: 100%;
      height: auto;
      align-items: center;
      justify-content: center;
      display: flex;
    }

    img {
      display: block;
      height: 85%;
      margin: 0 auto;
      object-fit: contain;
      @media screen and (max-width: ${breakpoints.md}) {
        width: 60%;
        height: auto;
      }
    }
  }

  /* @media (min-width: 1600px){
    height: 560px;
  }
  @media (min-width: 1800px){
    height: 600px;
  }
  @media (min-width: 2000px){
    height: 800px;
  } */
`;

type SectionSummaryCardsInfo = {
  backgroundColor: string;
  title: string;
  icon: string;
  illustrationImage: string;
  description: string;
  action: Function;
};

const SectionSummary = ({
  setCurrentlyShowing,
  activelyShowing,
  toggleKycScreen,
  setToggleKycScreen,
  setIsShowKYCModal,
}: {
  setCurrentlyShowing: Function;
  activelyShowing?: any;
  toggleKycScreen?: any;
  setToggleKycScreen?: any;
  setIsShowKYCModal?: any;
}) => {
  const themew = useTheme();
  const { redirectToPaymentOrMyWill } = useRedirectToPaymentOrMyWill();
  const lessThanSmall = useMediaQuery(themew.breakpoints.down('sm'));
  const gaEventTracker = useAnalyticsEventTracker({ category: EVENT_CATEGORIES.MY_WILL });
  const userActiveWill = useAppSelector(selectActiveWill);
  // const userAccessRole = useAppSelector(selectUserAccessRoles);
  const userProfile = useAppSelector(selectUserProfile);
  const guardianShow = useAppSelector(selectIsGuardianShow);
  const userStatus = useAppSelector(selectUserStatus);
  const currentUserPlansResponse = useAppSelector(selectCurrentUserPlans);
  const currentPlan = currentUserPlansResponse?.data?.fetchPlans?.[0];

  const [isPaymentComplete, setIsPaymentComplete] = useState<boolean>(false);
  const [isKYCCompleted, setIsKYCCompleted] = useState<boolean>(false);

  //   const content = useAppSelector(selectContent);
  const [sectionSummaryCards, setSectioncards] = useState<SectionSummaryCardsInfo[]>([]);

  const [generatingWill, setGeneratingWill] = useState(false);
  const [isGenerateWill, setIsGenerateWill] = useState(false);

  const [isShowDetailsModal, setIsShowDetailsModal] = useState(false);
  const [isUpdateMyWill, setIsUpdateWill] = useState(false);
  const [showUpdateButtonOverlay, setShowUpdateButtonOverlay] = useState(true);
  // const [isUpdateMyWill, setIsUpdateMyWill] = useState(false);

  const { generateWill } = useGenerateWill();
  const dispatch = useTypedDispatch();
  const willId = useAppSelector(selectActiveWill)?.documentId;
  const configInfo = useAppSelector(selectConfig);
  const navigate = useNavigate();
  const { isAcko } = useVendor();
  // const willPDFSignedURL = async (willId: string) => {
  //   try {
  //     const response = await runMutation({
  //       mutation: GENERATE_PREVIEW_OF_WILL,
  //       variables: {
  //         key: 'input',
  //         value: {
  //           willId,
  //           forPreview: true,
  //         },
  //       },
  //     });
  //     console.log({ response });
  //     return response;
  //   } catch (exception) {
  //     console.log({ exception });
  //   }
  // };

  useEffect(() => {
    // dispatch(fetchGuardianShowStatus());
    console.log('UserActive Will', userActiveWill);
    if (!configInfo) dispatch(fetchConfig());
  }, []);

  useEffect(() => {
    let cards = [
      {
        ...cardsGlobal[0],
        action: () => {
          gaEventTracker({
            action: 'Entered Personal Details Step',
            label: 'Section Summary',
          });
          setCurrentlyShowing(SectionSummaryScreens.PERSONAL_DETAILS);
        },
      },
     
      {
        ...cardsGlobal[1],
        action: () => {
          gaEventTracker({
            action: 'Entered My Assets Step',
            label: 'Section Summary',
          });
          (window as any).webengage.track("My Estate Clicked");
          setCurrentlyShowing(SectionSummaryScreens.ASSETS);
        },
      },
      {
        ...cardsGlobal[2],
        action: () => {
          gaEventTracker({
            action: 'Entered My People Step',
            label: 'Section Summary',
          });
          (window as any).webengage.track("My People Clicked");
          setCurrentlyShowing(SectionSummaryScreens.PEOPLE);
        },
      },
      {
        ...cardsGlobal[3],
        action: () => {
          if (
            userActiveWill &&
            userActiveWill.will_progress?.includes(WILL_CREATION_STATES.ASSET_DETAILS_ADDED)
          ) {
            gaEventTracker({
              action: 'Entered Asset distribution Step',
              label: 'Section Summary',
            });
            (window as any).webengage.track("Asset Distribution Clicked");
            setCurrentlyShowing(SectionSummaryScreens.ASSETS_DISTRIBUTION);
            return;
          }
          const toast: ToastMessage = {
            type: ToastType.ERROR,
            message: 'You must enter details of your assets before you can begin this section.',
          };
          dispatch(showToast(toast));
        },
      },
      {
        ...cardsGlobal[4],
        action: () => {
          gaEventTracker({
            action: 'Entered Exceutor Step',
            label: 'Section Summary',
          });
          (window as any).webengage.track("Will Executor Clicked");
          setCurrentlyShowing(SectionSummaryScreens.EXECUTOR);
        },
      },
      {
        ...cardsGlobal[5],
        action: () => {
          gaEventTracker({
            action: 'Entered Guardians Step',
            label: 'Section Summary',
          });
          (window as any).webengage.track("My Guardians Clicked");
          setCurrentlyShowing(SectionSummaryScreens.GUARDIANS);
        },
      },
      {
        ...cardsGlobal[6],
        action: () => {
          gaEventTracker({
            action: 'Entered Witnesses Step',
            label: 'Section Summary',
          });
          (window as any).webengage.track("My Witness Clicked");
          setCurrentlyShowing(SectionSummaryScreens.WITNESSES);
        },
      },
    ];
    // console.log('This is guardian Show',guardianShow);
    // if (guardianShow) {
    //   console.log()
    //   return setSectioncards([...cards.slice(0,4),{
    //     backgroundColor: '#FAD3D1',
    //     title: 'Guardians',
    //     icon: guardianIcon,
    //     illustrationImage: sectionSummaryImages.guardian,
    //     description:
    //       'If you have children who are younger than 18 years of age, consider who you would want to care for them in your absence',
    //     action: () => {
    //       gaEventTracker({
    //         action: 'Entered Guardians Step',
    //         label: 'Section Summary',
    //       });
    //       setCurrentlyShowing(SectionSummaryScreens.GUARDIANS);
    //     },
    //   },...cards.slice(4)])
    // }
    setSectioncards(cards);
  }, [guardianShow]);

  // const sectionSummaryCards: SectionSummaryCardsInfo[] =

  const UNUSED_generateWill: any = async () => {
    gaEventTracker({
      action: 'Attempted Generate Will',
      label: 'Section Summary',
    });
    if (getWillProgressPercentage(userActiveWill?.will_progress ?? []) !== 100)
      return dispatch(
        showToast({
          type: ToastType.ERROR,
          message: `Cannot generate will as it's incomplete.`,
        }),
      );
    console.log(userActiveWill?.will_progress, WILL_CREATION_STATES);
    // if (isWillIncomplete(userActiveWill?.will_progress as WILL_CREATION_STATES[]))
    //   return dispatch(
    //     showToast({
    //       type: ToastType.ERROR,
    //       message: `Cannot generate will as it's incomplete.`,
    //     }),
    //   );
    setGeneratingWill(true);
    try {
      const pdfUrl = await getWillPDF({
        willId: userActiveWill?.documentId as string,
        forPreview: false,
      });
      if (!pdfUrl) throw new Error();

      const updateWillRequestBody: Will = {
        documentId: userActiveWill?.documentId || ('' as string),
        type: WillTypes.PREMIUM_WILL,
        generatedWill: true,
      } as Will;

      const {
        data: { updateWill: updatedWill },
      }: any = await runMutation({
        mutation: UPDATE_USER_WILL,
        variables: {
          key: 'input',
          value: updateWillRequestBody,
        },
      });

      await new Promise((resolve, reject) => {
        dispatch(fetchUserProfileAction(undefined, resolve, reject));
      });

      if (updatedWill) {
        dispatch(
          updateActiveWill({
            will: updatedWill,
          }),
        );
      }
    } catch (error) {
      setGeneratingWill(false);
      const toast: ToastMessage = {
        type: ToastType.ERROR,
        message: 'Error generating your will. Please try again in sometime.',
      };
      dispatch(showToast(toast));
    }
  };

  // const updateUserWill = async () => {
  //   // setLoading(true);
  //   const updateWillRequestBody: UpdateWill = {
  //     documentId: userActiveWill?.documentId || ('' as string),
  //     type: WillTypes.PREMIUM_WILL,
  //     willCompletionProgress: getWillProgressPercentage(
  //       getLatestWillProgress(userActiveWill ?? null, WILL_CREATION_STATES.ASSETS_ADDED),
  //     ),
  //     // assetTypes,
  //     isWillUpdated: true,
  //   };
  //   try {
  //     const {
  //       data: { updateWill: updatedWill },
  //     }: any = await runMutation({
  //       mutation: UPDATE_USER_WILL,
  //       variables: {
  //         key: 'input',
  //         value: updateWillRequestBody,
  //       },
  //     });
  //     // setLoading(false);
  //     const toast: ToastMessage = {
  //       type: ToastType.SUCCESS,
  //       message: 'Will Generated!!',
  //     };
  //     dispatch(showToast(toast));

  //     setTimeout(() => {
  //       dispatch(dismissToast());
  //     }, 2000);

  //     dispatch(updateActiveWill({ will: updatedWill }));
  //     return updatedWill as Will;
  //   } catch (error) {
  //     // setLoading(false);
  //     const toast: ToastMessage = {
  //       type: ToastType.ERROR,
  //       message: 'Error Updating your will. Please try again.',
  //     };
  //     dispatch(showToast(toast));
  //   }
  // };
  const getWillStatus = () => {
    let status = true;
    // if (!userProfile?.kycDetails) {
    //   return false;
    // }
    // if (userProfile?.kycDetails) {
    //   if (userProfile?.kycDetails.kycStatus !== KycStatus.DONE) {
    //     console.log(
    //       '************************************************ KYC Status',
    //       userProfile?.kycDetails.kycStatus,
    //     );
    //     return false;
    //   }
    // }
    if (userActiveWill?.will_progress) {
      if (!userActiveWill.will_progress.includes('BASIC_DETAILS_FILLED' as WILL_CREATION_STATES)) {
        return false;
      }
    } else {
      return false;
    }
    console.log('Will progress', userActiveWill, status);
    return status;
  };

  // TODO: Reuse this for implementing whether the will is completed or not

  useEffect(() => {
    if (userActiveWill && 'generatedWill' in userActiveWill && userActiveWill?.generatedWill)
      navigate('/generated-will');
    // eslint-disable-next-line
  }, [userActiveWill]);

  useEffect(() => {
    if (getWillStatus()) {
      setIsGenerateWill(true);
    }
    if (userProfile?.kycDetails) {
      if (userProfile.kycDetails.kycStatus === 'DONE') {
        setIsKYCCompleted(true);
      }
    }
  }, [userActiveWill, userProfile]);

  useEffect(() => {
    if (userStatus.includes(UserStatus.PROFILE_LOADED)) {
      setIsPaymentComplete(!userStatus.includes(UserStatus.PAYMENT_INCOMPLETE));
      console.log('Is Payment Complete', !userStatus.includes(UserStatus.PAYMENT_INCOMPLETE));
    }
    console.log(
      '********************User First Will completed',
      userStatus.includes(UserStatus.FIRST_WILL_COMPLETED),
      userActiveWill?.isWillUpdated,
    );
  }, [userStatus]);

  useEffect(() => {
    if(isAcko){
      setIsKYCCompleted(true);
    }
  }, [isAcko])

  useEffect(() => {
    if(userProfile?.wills?.length && userProfile?.wills?.length > 1){
      setIsKYCCompleted(true);
    }
  },[userProfile])

  return (
    <SectionSummaryWrapper>
      {
        <>
          <Grid className='sectionSummaryPadding'>
            <Grid
              container
              lg={12}
              md={12}
              sm={0}
              justifyContent={'space-between'}
              className='actions'
            >
              <h3 className='heading2'>Will Editor</h3>
              <div className='action_button_holder'>
                {/* After Payment, this button need not to show, still the minimum conditions not met in Grace Period Running (We have another Generate Will Button) */}
                {userActiveWill?.paymentInfo === null ? (
                  userStatus.includes(UserStatus.WILL_CREATION_INCOMPLETE) ||
                    userStatus.includes(UserStatus.FIRST_WILL_COMPLETED) ? (
                    isGenerateWill ? (
                      <CustomButton
                        text={generatingWill ? 'Generating Will...' : 'Generate Will'}
                        className='continueWillBtn'
                        onClick={async () => {
                          if (false) {
                            console.log(willId);
                            generateWill(willId as string);
                            UNUSED_generateWill();
                          }
                          if (userStatus.includes(UserStatus.WILL_CREATION_INCOMPLETE)) {
                            (window as any).webengage.track("Generate Will Clicked",{
                              "Will Generated": "no"
                            });
                            return setIsShowDetailsModal(true);
                            }
                            if (!isKYCCompleted) {
                              (window as any).webengage.track("Generate Will Clicked",{
                                "Will Generated": "no"
                              });
                              setIsShowKYCModal(true);
                              
                            return;
                          }
                          else{
                            if(Date.now() > Number(currentPlan?.expireAt)){
                              (window as any).webengage.track("Generate Will Clicked",{
                                "Will Generated": "no"
                              });
                              navigate(Pages.PAYMENT + '?selectedPlans=will', { replace: true });
                            }
                            else{
                              if(willId){
                                setGeneratingWill(true);
                                await generateWill(willId);
                                setGeneratingWill(false);
                                redirectToPaymentOrMyWill();
                              }
                            }
                          }
                          // if (willId) {
                          //   // console.log("will ID works", willId);
                          //   setGeneratingWill(true);
                          //   await generateWill(willId);
                          //   setGeneratingWill(false);
                          //   redirectToPaymentOrMyWill();
                          // }
                        }}
                        lessThanSmallPageBottom={lessThanSmall}
                      />
                    ) : (
                      <CustomButton
                        text={`${userActiveWill?.willCompletionProgress &&
                          userActiveWill?.willCompletionProgress > 0
                          ? 'Continue Will'
                          : 'Start Will'
                          }`}
                        className='continueWillBtn'
                        onClick={() => {
                          // (window as any).webengage.track("Start Will Clicked");
                          setCurrentlyShowing(SectionSummaryScreens.PERSONAL_DETAILS)}}
                        lessThanSmallPageBottom={lessThanSmall}
                      />
                    )
                  ) : null
                ) : null}
              </div>
            </Grid>

            {!isPaymentComplete ? (
              <p className={`subText myWill-subtext-font hide_in_mobile`}>
                Create your Will now and secure your legacy.
              </p>
            ) : null}
            <div className={'main_container'}>
              <Grid lg={12} md={12} sm={12} className='sectionSummary_container'>
                {/* {userActiveWill !== null && userActiveWill?.paymentInfo !== null ? (
                  <Grid
                    display='flex'
                    style={{ width: '100%', ...(lessThanSmall ? { marginBottom: '16px' } : {}) }}
                  >
                    <Grid lg={6} md={6} sm={12}>
                      <NotifyBlock />
                    </Grid>
                  </Grid>
                ) : null} */}

                {/* <h3 className='heading5'>Continue with your Will creation</h3> */}
                <Grid
                  container
                  className='summary_cards_holder'
                  style={{
                    ...(userActiveWill !== null &&
                      userActiveWill?.paymentInfo !== null &&
                      showUpdateButtonOverlay
                      ? { overflow: 'hidden' }
                      : {}),
                  }}
                >
                  {userActiveWill !== null &&
                    userActiveWill?.paymentInfo !== null &&
                    showUpdateButtonOverlay && (
                      <Grid
                        style={{
                          position: 'absolute',
                          top: 0,
                          width: '100%',
                          height: '100%',
                          backgroundColor: 'rgba(0,0,0,0.6)',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          zIndex: '999',
                          borderRadius: '4px',
                        }}
                      >
                        <Grid
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: '16px',
                            backgroundColor: '#FFC920',
                            borderRadius: '5px',
                            width: '200px',
                            cursor: 'pointer',
                            zIndex: '1000',
                          }}
                          onClick={() => {
                            Mixpanel.track('Update Will Clicked');
                            Intercom.track('Update Will Clicked');
                            (window as any).webengage.track("Will Updated",{
                              "Will Updated": "yes",
                              "Will Update Journey Started": "yes"
                            });
                            setIsUpdateWill(true);
                          }}
                        >
                          Update My Will
                        </Grid>
                      </Grid>
                    )}
                  <PreviewWill />
                  {React.Children.toArray(
                    sectionSummaryCards.map((card, index) => (
                      <Grid item lg={3} md={4} sm={6} xs={12} className='summary_card'>
                        <StyledSummaryCardGrid
                          backgroundColor={card.backgroundColor}
                          display={'flex'}
                          flexDirection='column'
                          justifyContent={'space-between'}
                          className='summary'
                          onClick={() => card.action()}
                        >
                          <Grid
                            display={'flex'}
                            direction={'column'}
                            alignItems={'start'}
                            gap={'12px'}
                            // justifyContent='space-between'
                            className='head'
                          >
                            <Grid
                              display={'flex'}
                              alignItems={'center'}
                              justifyContent='space-between'
                              className='head w-100'
                            >
                              <span className='subText font-semi-bold'>{card.title}</span>
                              <img
                                src={card.icon}
                                alt='icon'
                                className='icon'
                                style={{
                                  /* @ts-ignore */
                                  height: card.title === 'My Beneficiaries' && '32px',
                                  /* @ts-ignore */
                                  width: card.title === 'My Beneficiaries' && '32px',
                                }}
                              />
                            </Grid>
                            <Grid className='step_counter'>
                              <span className=''>
                                {index + 1} of {sectionSummaryCards.length}
                              </span>
                            </Grid>
                          </Grid>
                          <div className='illustrationImageHolder'>
                            <img
                              src={card.illustrationImage}
                              alt=''
                              className='illustrationImage'
                            />
                          </div>
                          <Grid className={'pb-10px'}>
                            <span className='xs_text'>{card.description}</span>
                          </Grid>
                          <CustomButton
                            text='Edit'
                            className='w-100'
                            backgroundColor={theme.white}
                            fontColor={theme.primaryColor}
                            onClick={() => card.action()}
                          />
                        </StyledSummaryCardGrid>
                      </Grid>
                    )),
                  )}
                </Grid>
              </Grid>
              <div className='action_button_holder'>
                {/* After Payment, this button need not to show, still the minimum conditions not met in Grace Period Running (We have another Generate Will Button) */}
                {userActiveWill?.paymentInfo === null ? (
                  userStatus.includes(UserStatus.WILL_CREATION_INCOMPLETE) ||
                    userStatus.includes(UserStatus.FIRST_WILL_COMPLETED) ? (
                    isGenerateWill ? (
                      <CustomButton
                        text={generatingWill ? 'Generating Will...' : 'Generate Will'}
                        className='continueWillBtn show_in_mobile'
                        onClick={async () => {
                          // if (false) {
                          //   console.log(willId);
                          //   generateWill(willId as string);
                          //   UNUSED_generateWill();
                          // }
                          if (userStatus.includes(UserStatus.WILL_CREATION_INCOMPLETE)) {
                            (window as any).webengage.track("Generate Will Clicked",{
                              "Will Generated": "no"
                            });
                            return setIsShowDetailsModal(true);
                          }
                          if(!isKYCCompleted){
                            (window as any).webengage.track("Generate Will Clicked",{
                              "Will Generated": "no"
                            });
                            setIsShowKYCModal(true)
                          }
                          else{
                            if(Date.now() > Number(currentPlan?.expireAt)){
                              (window as any).webengage.track("Generate Will Clicked",{
                                "Will Generated": "no"
                              });
                              navigate(Pages.PAYMENT + '?selectedPlans=will', { replace: true });
                            }
                            else{
                              if(willId){
                                setGeneratingWill(true);
                                await generateWill(willId);
                                setGeneratingWill(false);
                                redirectToPaymentOrMyWill();
                              }
                            }
                          }
                          // if (willId) {
                          //   setGeneratingWill(true);
                          //   await generateWill(willId);
                          //   setGeneratingWill(false);
                          //   // navigate(Pages.PAYMENT);
                          //   redirectToPaymentOrMyWill();
                          // }
                        }}
                        lessThanSmallPageBottom={lessThanSmall}
                      />
                    ) : (
                      <CustomButton
                        text={`${userActiveWill?.willCompletionProgress &&
                          userActiveWill?.willCompletionProgress > 0
                          ? 'Continue Will'
                          : 'Start Will'
                          }`}
                        className='continueWillBtn show_in_mobile'
                        onClick={() => {
                          // (window as any).webengage.track("Start Will Clicked");
                          setCurrentlyShowing(SectionSummaryScreens.PERSONAL_DETAILS)}}
                        lessThanSmallPageBottom={lessThanSmall}
                      />
                    )
                  ) : null
                ) : null}
              </div>
            </div>
          </Grid>

          {
            // will is completed
            !userStatus.includes(UserStatus.WILL_CREATION_INCOMPLETE) &&
              // second will is not created yet (update not clicked)
              !userStatus.includes(UserStatus.FIRST_WILL_COMPLETED) &&
              // if payment is not done (using this since isFinalWillGenerated isn't set)
              // userStatus.includes(UserStatus.PAYMENT_INCOMPLETE)
              userActiveWill?.paymentInfo === null ? (
              <>
                <div style={{ height: '80px' }}></div>
                <Grid
                  container
                  lg={12}
                  md={12}
                  sm={12}
                  className='successMessage'
                  style={{ position: 'fixed', bottom: 0, width: '100vw', zIndex: 10 }}
                >
                  <span className={'font-merriweather'}>Your Will is ready to be generated!</span>
                  <div className='priceButton'>
                    {/* {userStatus.includes(UserStatus.PAYMENT_INCOMPLETE) ? ( */}
                    {/*   <p>₹{config!!.configs.mobilePlans['Premium Will'].basePrice}*</p> */}
                    {/* ) : null} */}
                    <CustomButton
                      text={
                        userStatus.includes(UserStatus.PAYMENT_INCOMPLETE)
                          ? 'Proceed to checkout'
                          : 'Proceed'
                      }
                      className='proceedBtn'
                      onClick={() => {
                        if (!isKYCCompleted) {
                          (window as any).webengage.track("Generate Will Clicked",{
                            "Will Generated": "no"
                          });
                          setIsShowKYCModal(true);
                        } else {
                          if((Date.now() > Number(currentPlan?.expireAt))){
                            (window as any).webengage.track("Generate Will Clicked",{
                              "Will Generated": "no"
                            });
                          }
                          ((Date.now() > Number(currentPlan?.expireAt)) ? navigate(Pages.PAYMENT + '?selectedPlans=will', { replace: true }) : redirectToPaymentOrMyWill())
                        }
                      }}
                    />
                  </div>
                </Grid>
              </>
            ) : null
          }
          <DetailsModal
            isShowModal={isShowDetailsModal}
            handleOpenCloseModal={() => setIsShowDetailsModal(false)}
            goToAssets={() => setCurrentlyShowing(SectionSummaryScreens.ASSETS)}
            goToDistribution={() => setCurrentlyShowing(SectionSummaryScreens.ASSETS_DISTRIBUTION)}
            goToExecutor={() => setCurrentlyShowing(SectionSummaryScreens.EXECUTOR)}
          />

          <UpdateWillModal
            isShowModal={isUpdateMyWill}
            handleOpenCloseModal={() => setIsUpdateWill(false)}
            isAbleToUpdate={() => setShowUpdateButtonOverlay(false)}
          />
        </>
      }
    </SectionSummaryWrapper>
  );
};

export default SectionSummary;
