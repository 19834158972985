import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import GenerateWill from './GenerateWill';
import congratulations from 'assets/svg/congratulations.svg';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const style = (lessThanSmall?: boolean) => ({
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'min(85%, 480px)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  padding: lessThanSmall ? '0px' : '40px 52px 54px 32px',
  borderRadius: '12px',
  ...(lessThanSmall ? { display: 'flex', flexDirection: 'column' } : {}),
});
const style2 = {
  background: 'rgba(0,0,0,0.4)',
};

export default function CongratulationsWindow({
  isOpenModal,
  handleModalClose,
  setKYCHeroScreen,
}: {
  isOpenModal: boolean;
  handleModalClose: Function;
  setKYCHeroScreen?: Function;
}) {
  const currentTheme = useTheme();
  const lessThanSmall = useMediaQuery(currentTheme.breakpoints.down('sm'));

  return (
    <Modal
      sx={style2}
      open={isOpenModal}
      onClose={() => handleModalClose()}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={style(lessThanSmall)}>
        <div style={{ display: 'flex', justifyContent: 'end', padding: '12px' }}>
          <CloseIcon
            style={{ color: '#000', fontSize: '24px', cursor: 'pointer' }}
            onClick={() => handleModalClose()}
          />
        </div>
        <div
          style={{
            display: 'flex',
            ...(lessThanSmall
              ? { alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }
              : { justifyContent: 'space-between', alignItems: 'center' }),
          }}
        >
          <img src={congratulations} style={{ height: '150px', width: '150px' }} />
          <div className={`${lessThanSmall ? 'flex flex-col items-center gap-12px w-100' : ''}`}>
            <p className={`heading6 ${lessThanSmall ? 'w-100 text-center' : ''}`}>
              Congratulations!
            </p>
            <p style={{ margin: lessThanSmall ? '0px' : '16px 0px', color: '#101828a3', padding: lessThanSmall ? '0px 12px' : '0'}}>
              You’re now ready to generate your Will
            </p>
            <div className={`${lessThanSmall ? 'w-100 flex items-center justify-center ' : ''}`}>
              <GenerateWill setKYCHeroScreen={setKYCHeroScreen} />
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
}
