import { UserAddress, Will, WillRoles, WILL_CREATION_STATES } from 'utils/types';

// Illustration Images for Summary Cards
import personalDetails from 'assets/images/illustration-personalDetails.png';
import assets from 'assets/images/illustration-assets.png';
import assetsDistribution from 'assets/images/illustration-assetsDistribution.png';
import guardian from 'assets/images/illustration-guardian.png';
import executor from 'assets/images/illustration-executor.png';
import witness from 'assets/images/illustration-witness.png';
import people from 'assets/images/illustration-people.png';

// Icons for different Assets.
import businessAsset from 'assets/svg/assetType-business.svg';
import immovableAsset from 'assets/svg/assetType-immovable.svg';
import financialAsset from 'assets/svg/assetType-financial.svg';
import otherAsset from 'assets/svg/assetType-other.svg';
import tooltip from 'assets/svg/tooltipIcon.svg';
import digitalAsset from 'assets/svg/assetType-digital.svg';

// Icons for Social Media
import facebook from 'assets/svg/yellow_facebook.svg';
import twitter from 'assets/svg/yellow_twitter.svg';
import linkedIn from 'assets/svg/yellow_linkedIn.svg';

const getEmptyAddress = (): UserAddress => ({
  addressLine1: '',
  addressLine2: '',
  city: '',
  pinCode: '',
  state: '',
  country: '',
});

const regex = {
  email: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
  // phoneNumber: /^[6-9]\d{9}$/,
  phoneNumber: /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$|^\+[1-9]\d{1,14}$/, //All Mobile numbers, if India, accepts with or without +91  //Indian Mobile number, accepts with or without +91
  // phoneNumber:
  //   /((?:\+|00)[17](?: |\-)?|(?:\+|00)[1-9]\d{0,2}(?: |\-)?|(?:\+|00)1\-\d{3}(?: |\-)?)?(0\d|\([0-9]{3}\)|[1-9]{0,3})(?:((?: |\-)[0-9]{2}){4}|((?:[0-9]{2}){4})|((?: |\-)[0-9]{3}(?: |\-)[0-9]{4})|([0-9]{7}))/,
  pinCodeIndia: /^[1-9][0-9]{5}$/, // Indian Pincode
  pinCodeInternational: /^.*$/,
  fullName: /^[A-Za-z ]+$/,
};

const sectionSummaryImages = {
  personalDetails,
  assets,
  assetsDistribution,
  guardian,
  executor,
  witness,
  people,
};

const assetsIcons = {
  businessAsset,
  immovableAsset,
  financialAsset,
  otherAsset,
  tooltip,
  digitalAsset,
};

const socialMediaIcons = {
  facebook,
  twitter,
  linkedIn,
};

const toggleFullScreen = (ref: React.MutableRefObject<any>) => {
  if (ref.current.requestFullscreen) {
    ref.current.requestFullscreen();
  } else if (ref.current.webkitRequestFullscreen) {
    /* Safari */
    ref.current.webkitRequestFullscreen();
  } else if (ref.current.msRequestFullscreen) {
    /* IE11 */
    ref.current.msRequestFullscreen();
  }
};

// const downloadURI = (uri: string, name: string) => {
//   const link = document.createElement('a');
//   if (typeof link.download === 'undefined') {
//     window.location.href = uri;
//   } else {
//     link.href = uri;
//     link.download = name;
//     link.target = '_blank';
//     link.style.display = 'none';
//     document.body.appendChild(link);
//     link.click();
//     document.body.removeChild(link);
//   }
// };

const downloadURI = (uri: string, name: string) => {
  let link = document.createElement('a');
  link.download = name;
  link.href = uri;
  link.style.display = 'none';

  if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
    // For iOS, use the 'click' method and 'window.open' with '_blank' target
    link.onclick = () => {
      window.open(uri, '_blank');
    };
  } else {
    // For other devices, set the attribute 'target' as '_blank'
    link.target = '_blank';
  }

  // Set the Content-Type header as 'application/octet-stream'
  link.type = 'application/octet-stream';

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};


const openReferralFactory = () => {
  let link = document.createElement('a');
  link.className = 'rf-popup-nbgI4X';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const exitFullScreen = () => {
  var element: any = window.document;
  var requestMethod =
    element.mozCancelFullScreen ||
    element.exitFullscreen ||
    element.webkitExitFullscreen ||
    element.msExitFullscreen;
  if (requestMethod) {
    requestMethod.call(element);
    console.log('calling exit method');
  } else {
    console.log('Oops. Request method false.');
  }
};

const formatDuration = (totalSeconds: number): string => {
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = formatSeconds(Math.floor(totalSeconds % 60));
  return `${minutes}:${seconds}`;
};

const formatSeconds = (seconds: number): string => {
  if (seconds < 10) return `0${seconds}`;
  return `${seconds}`;
};

const getAge = (dateString: string | Date) => {
  const today = new Date();
  const birthDate = new Date(dateString);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

const getLatestWillProgress = (
  will: Will | null,
  type: WILL_CREATION_STATES,
): Array<WILL_CREATION_STATES> => {
  if (!will) return [];
  if (will.will_progress?.includes(type)) return will.will_progress as WILL_CREATION_STATES[];
  return will.will_progress?.concat(type) as WILL_CREATION_STATES[];
};

const getWillProgressPercentage = (currentProgress: Array<WILL_CREATION_STATES>): number => {
  if (currentProgress.length === 0) return 0;
  return Math.floor((currentProgress.length / Object.keys(WILL_CREATION_STATES).length) * 100);
};

const removeDuplicateRoles = (roles: WillRoles[]) =>
  roles.filter((role, position) => roles.indexOf(role) === position);

const isMinor = (dob: string | Date) => getAge(dob) < 18;

const getWillStatus = (userActiveWill: Will) => {
  let status = true;
  if (userActiveWill?.will_progress) {
    if (!userActiveWill.will_progress.includes('BASIC_DETAILS_FILLED' as WILL_CREATION_STATES)) {
      return false;
    }
  } else {
    return false;
  }
  // console.log('Will progress', userActiveWill, status);
  return status;
};

const checkBasicConditionsToGenerateWill = (userActiveWill: Will) => {
  if (userActiveWill?.assets) {
    if (userActiveWill.assets.length === 0) {
      console.log('in WillGuardians, in 1');
      return false;
    }
  } else {
    return false;
  }
  if (userActiveWill?.assetDistribution && userActiveWill.assets) {
    if (
      userActiveWill.assetDistribution.length === 0 ||
      userActiveWill.assetDistribution.length !== userActiveWill.assets.length
    ) {
      console.log('in WillGuardians, in 2');
      return false;
    }
  } else {
    console.log('in WillGuardians, in 3');
    return false;
  }
  if (!userActiveWill?.executor) {
    console.log('in WillGuardians, in 4');
    return false;
  }

  // console.log('All Set');
  console.log('in WillGuardians, in 5');
  return true;
};

const getPlanString = (planId: string, addOns: string[]) => {
  let userTag = '';

  if (planId === 'starter') userTag += 'Starter';
  if (planId === 'select') userTag += 'Select';
  if (planId === 'elite') userTag += 'Elite';

  if (addOns!!.length !== 0) {
    for (let addOn of addOns || []) {
      if (addOn === 'even') userTag += ' + Even';
      else userTag += ` + ${addOn}`;
    }
  }

  return userTag;
};

const getMaskedPhoneNumber = (phoneNumber: string) => {
  const lastTwoDigits = phoneNumber.slice(-2);

  // This is including + sign in the number itself
  // const firstTwoDigits = phoneNumber.slice(0, 2);

  // Without plus
  const firstTwoDigits = phoneNumber.slice(3, 5);

  const maskedPhoneNumber = firstTwoDigits.padEnd(phoneNumber.length - 2, 'x'); // Mask only for 6 digits after firstTwoDigits.
  return `${maskedPhoneNumber}${lastTwoDigits}`;
};

// @ts-ignore
const scrollToTopInstantly = () => window.scrollTo({ top: 0, left: 0, behavior: 'instant' });

export {
  getMaskedPhoneNumber,
  getEmptyAddress,
  getPlanString,
  regex,
  sectionSummaryImages,
  assetsIcons,
  socialMediaIcons,
  toggleFullScreen,
  exitFullScreen,
  formatDuration,
  getLatestWillProgress,
  getWillProgressPercentage,
  removeDuplicateRoles,
  isMinor,
  getAge,
  getWillStatus,
  checkBasicConditionsToGenerateWill,
  downloadURI,
  openReferralFactory,
  scrollToTopInstantly,
};
