import { Grid } from '@mui/material';
import {
  AssetSubTypesWrapper,
  HelpWrapper,
  NotifyPointsWrapper,
  NotifyWrapper,
  QueryHelpCard,
} from './NewMyWill';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import { useState } from 'react';
import MuiDrawer from 'common/Reusables/Drawer';
import OverlayHead from './CreateWill/Steps/OverlayHead';
import ShowPeople from 'common/NotifyPeople/ShowPeople';
import helpIcon from 'assets/images/willStepsHelpIcon.png';
import book from 'assets/svg/book.svg';
import contact from 'assets/svg/menuItem-contact.svg';
import notifyIcon from 'assets/svg/notify-icon.svg';
import { Intercom } from '../../utils/intercom';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useVendor } from 'common/useVendor';

function NotifyBlock() {
  const theme = useTheme();
  const lessThanSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const [isNotify, setIsNotify] = useState(false);
  const [isNotifyHelp, setIsNotifyHelp] = useState(false);
  const { isAcko } = useVendor();
  return (
    <>
      <NotifyWrapper>
        <Grid className='timer-text' display={'flex'}>
          <Grid>
            <img src={notifyIcon} alt='' />
          </Grid>
          <Grid style={{ paddingLeft: '12px' }}>
            <p className='line-height' style={{ fontSize: '14px' }}>
              Notify My Beneficiaries
            </p>
            <p
              className='line-height'
              style={{ color: 'rgba(16, 24, 40, 0.6)', fontSize: '13px', marginTop: '8px' }}
            >
              Now that you have drafted your Will, you must inform the people who play an important
              role.
            </p>
            <div
              className='line-height go-to'
              style={{ fontSize: '13px' }}
              onClick={() => setIsNotify(true)}
            >
              <p>
                <u>Notify Now </u>
              </p>
              <p style={{ marginTop: '6px', marginLeft: '8px' }}>
                <TrendingFlatIcon />
              </p>
            </div>
          </Grid>
        </Grid>
      </NotifyWrapper>
      <MuiDrawer open={isNotify} hideDrawer={() => setIsNotify(false)} width={'min(100%, 500px)'}>
        <AssetSubTypesWrapper>
          <OverlayHead
            heading='Notify My Beneficiaries'
            showHelp={true}
            onClick={() => setIsNotify(false)}
            setShowHelp={() => setIsNotifyHelp(true)}
          />
          <p className='notify-help-text'>
            Notifications will only alert the user about their role in your Will. The details of
            your Will will not be shared.
          </p>
          <ShowPeople />
        </AssetSubTypesWrapper>
      </MuiDrawer>
      <MuiDrawer
        open={isNotifyHelp}
        hideDrawer={() => setIsNotifyHelp(false)}
        width={'min(100%, 500px)'}
      >
        <AssetSubTypesWrapper>
          <OverlayHead heading='Notify My Beneficiaries' onClick={() => setIsNotifyHelp(false)} />
          <p className='notify-help-text'>
            You can choose to notify important people in your Will here.
          </p>
          <p style={{ color: 'rgba(16, 24, 40, 0.64)' }}>Please note:</p>
          {/* Nofity People Points */}
          <NotifyPointsWrapper>
            {lessThanSmall ? (
              <HelpOutlineIcon />
            ) : (
              <img src={helpIcon} alt='Help Icon' className='help_icon' />
            )}
            <p className='help-text'>
              An alert will be sent via Whatsapp that will notify the person only of their role in
              your Will.
            </p>
          </NotifyPointsWrapper>
          <NotifyPointsWrapper>
            {lessThanSmall ? (
              <HelpOutlineIcon />
            ) : (
              <img src={helpIcon} alt='Help Icon' className='help_icon' />
            )}
            <p className='help-text'>The details of your Will will not be shared.</p>
          </NotifyPointsWrapper>
          <NotifyPointsWrapper>
            {lessThanSmall ? (
              <HelpOutlineIcon />
            ) : (
              <img src={helpIcon} alt='Help Icon' className='help_icon' />
            )}
            <p className='help-text'>
              Notifications will be sent to your Primary Beneficiaries only. Secondary
              Beneficiaries, Backups, Monetary Bequests and people below 18 years of age will not be
              notified.
            </p>
          </NotifyPointsWrapper>
        </AssetSubTypesWrapper>
        <HelpWrapper container>
          {!isAcko && (
            <Grid
              item
              lg={12}
              className='queries_section'
              display={'flex'}
              flexDirection='column'
              justifyContent='space-around'
              alignItems={'center'}
              style={{ boxSizing: 'border-box' }}
            >
              <p className='subText font-semi-bold'>Have a specific query in mind?</p>
              <QueryHelpCard
                icon={book}
                text='Explore FAQs'
                onClick={() => window.open('https://getyellow.in/faq', '_blank')}
              />
              <QueryHelpCard
                icon={contact}
                text='Contact Us'
                onClick={Intercom.hideOverLayAndShowIntercom({ setShowOverlay: setIsNotifyHelp })}
              />
            </Grid>
          )}
        </HelpWrapper>
      </MuiDrawer>
    </>
  );
}

export default NotifyBlock;
