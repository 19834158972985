import theme from '../styles/theme';
import personIcon from '../assets/svg/person.svg';
import { sectionSummaryImages } from '../utils';
import myPeopleIcon from '../assets/svg/my_people.svg';
import assetsIcon from '../assets/svg/assetsIcon.svg';
import assetsDistributionIcon from '../assets/svg/assetsDistributionIcon.svg';
import executorIcon from '../assets/svg/executor.svg';
import guardianIcon from '../assets/svg/shield.svg';
import witnessIcon from '../assets/svg/witness.svg';
import { SectionSummaryScreens } from './SectionScreenSummary';

export const cardsGlobal = [
  {
    backgroundColor: theme.light_beige,
    title: 'Personal Details',
    icon: personIcon,
    illustrationImage: sectionSummaryImages.personalDetails,
    description: 'Enter your name, date of birth and contact information.',
    sectionSummaryScreens: [SectionSummaryScreens.PERSONAL_DETAILS],
  },

  {
    backgroundColor: '#D4D1FA',
    title: 'My Assets',
    icon: assetsIcon,
    illustrationImage: sectionSummaryImages.assets,
    description:
      'This section will guide you through how to make a list of all the assets you own.',
    sectionSummaryScreens: [SectionSummaryScreens.ASSETS, SectionSummaryScreens.LIABILITIES],
  },
  {
    backgroundColor: '#C7D6FF',
    title: 'My Beneficiaries',
    icon: myPeopleIcon,
    illustrationImage: sectionSummaryImages.people,
    description: 'Add names and details of the people or organisations who will inherit your assets.',
    sectionSummaryScreens: [SectionSummaryScreens.PEOPLE],
  },
  {
    backgroundColor: '#FAE6D1',
    title: 'Asset Distribution',
    icon: assetsDistributionIcon,
    illustrationImage: sectionSummaryImages.assetsDistribution,
    description: 'Consider how you would like to distribute your assets among your beneficiaries.',
    sectionSummaryScreens: [SectionSummaryScreens.ASSETS_DISTRIBUTION],
  },
  {
    backgroundColor: '#BAE4D9',
    title: 'Will Executor',
    icon: executorIcon,
    illustrationImage: sectionSummaryImages.executor,
    description:
      'Select someone you trust to administer your Will when you are gone.  This is a crucial step in the Will creation process.',
    sectionSummaryScreens: [SectionSummaryScreens.EXECUTOR],
  },
  {
    backgroundColor: '#FAD3D1',
    title: 'Guardians',
    icon: guardianIcon,
    illustrationImage: sectionSummaryImages.guardian,
    description:
      'If you have children who are younger than 18 years of age, consider who you would want to care for them in your absence.',
    sectionSummaryScreens: [SectionSummaryScreens.GUARDIANS],
  },
  {
    backgroundColor: '#D1E3FA',
    title: 'My Witnesses',
    icon: witnessIcon,
    illustrationImage: sectionSummaryImages.witness,
    description:
      'Designate two people to be your Witnesses. A Witness does not need to know the contents of your Will to qualify for this role.',
    sectionSummaryScreens: [SectionSummaryScreens.WITNESSES],
  },
];
