import { GENERATE_PREVIEW_OF_WILL } from 'common/queries';
import { useEffect, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import { selectActiveWill, selectUserStatus } from 'store/user/selectors';
import { WillPdfInput } from 'utils/types';
import { runMutation } from 'utils/graphQL';
import magnifyingGlass from 'assets/images/magnifying.png';

// import loadingGIF from 'assets/images/loadingTransparent.gif';
// import loadingGIFYellow from 'assets/Yellow_Loader_WHITEBG.gif';
import loadingGIFYellow from 'assets/Yellow_Loader_YELLOW.gif';
import { UserStatus } from 'store/user/slice';
import { WillPreviewContainer } from './WillPreviewContainer';
import Grid from '@mui/material/Grid';
import { StyledSummaryCardGrid } from '../SectionSummary';
import { styled } from '@mui/material';

import willDistribution from 'assets/svg/will-distribution.svg';
import willPersonal from 'assets/svg/will-personal.svg';
import willExecutor from 'assets/svg/will-executor.svg';
import willEstate from 'assets/svg/will-estate.svg';
import { WillModal } from '../../../common/Reusables/WillModal';
import { breakpoints } from '../../../styles/Breakpoints';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const ContentWrapper = styled(Grid)`
  display: flex;
  margin: 32px 0px;
  @media screen and (max-width: ${breakpoints.sm}) {
    margin: 20px 0px;
  }
  align-items: center;
  .font-margin {
    margin-left: 12px;
  }
  .text-color {
    color: #777;
  }
`;

export const getWillPDF = async ({
  willId,
  webPreview,
  forPreview,
}: {
  willId: string;
  webPreview?: boolean;
  forPreview: boolean;
}): Promise<string | null> => {
  const {
    data: { generateWillPdf: pdfUrl },
  }: { data: { generateWillPdf: string } } = await runMutation({
    mutation: GENERATE_PREVIEW_OF_WILL,
    variables: {
      key: 'input',
      value: {
        willId,
        forPreview,
        webPreview,
      } as WillPdfInput,
    },
  });
  if (pdfUrl) return pdfUrl;
  return null;
};

const PreviewWill = () => {
  const themew = useTheme();
  const lessThanSmall = useMediaQuery(themew.breakpoints.down('sm'));

  const [url, setUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isShowWillPreview, setIsShowWillPreview] = useState(false);
  const [isWillComplete, setIsWillComplete] = useState<boolean>(false);

  // const dispatch = useTypedDispatch();
  const userActiveWill = useAppSelector(selectActiveWill);
  const userStatus = useAppSelector(selectUserStatus);

  const generateWillPreview = async () => {
    setLoading(true);
    try {
      if (!userActiveWill?.documentId) throw new Error();
      const pdfUrl = await getWillPDF({
        willId: userActiveWill?.documentId,
        forPreview: true,
        webPreview: true,
      });
      if (!pdfUrl) throw new Error();
      setUrl(pdfUrl);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      // const toast: ToastMessage = {
      //   type: ToastType.ERROR,
      //   message: 'Error getting preview of your will. Please try again.',
      // };
      // dispatch(showToast(toast));
    }
  };

  useEffect(() => {
    if (!userStatus.includes(UserStatus.WILL_CREATION_INCOMPLETE))
      setIsWillComplete(true);
  }, [userStatus])

  useEffect(() => {
    if (!isWillComplete) return;
    if (userActiveWill === undefined || userActiveWill === null) return;

    console.log("Inside UseEffect", { isWillComplete, userActiveWill: JSON.stringify(userActiveWill) });
    setIsShowWillPreview(true);
    generateWillPreview();
  }, [userActiveWill, isWillComplete]);

  return (
    <Grid item lg={3} md={4} sm={6} xs={12} className='summary_card'>
      <StyledSummaryCardGrid
        backgroundColor={'#ffe171'}
        display={'flex'}
        flexDirection='column'
        justifyContent={'space-between'}
        className='summary'
        onClick={async () => {
          if (isShowWillPreview) {
            // await generateWillPreview();
            setIsModalOpen(true);
          }
        }}
        position={'relative'}
      >
        <Grid
          display={'flex'}
          alignItems={'center'}
          // justifyContent='space-between'
          className='head'
        >
          <span className='subText font-semi-bold'>Will Preview</span>
        </Grid>
        <div
          style={{
            position: 'absolute',
            top: '4.2rem',
            bottom: '24px',
            right: '16px',
            left: '16px',
            overflow: 'hidden',
            display: 'flex',
            alignItems: 'center',
            justifyItems: 'center',
            justifyContent: 'center',
            // backgroundColor: 'white',
          }}
        >
          {isShowWillPreview ? (
            <>
              {loading ? (
                <img className='loader-img' src={loadingGIFYellow} alt='' width='40px' />
              ) : (
                <div
                  style={{
                    height: '100%',
                    width: '100%',
                    maxHeight: '100%',
                    maxWidth: '100%',
                    minHeight: '100%',
                    minWidth: '100%',
                    overflow: 'hidden',
                    borderRadius: '12px',
                    position: 'relative',
                  }}
                >
                  <WillPreviewContainer url={url} pageToRender={1} darken={true} />
                  <img
                    src={magnifyingGlass}
                    alt='open-will-preview'
                    style={{
                      position: 'absolute',
                      top: '50%',
                      zIndex: '20',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                    }}
                  />
                </div>
              )}
            </>
          ) : (
            <div
              className={'permanentScrollbar'}
              style={{
                width: '100%',
                height: lessThanSmall ? '100%' : '75%',
                backgroundColor: 'white',
                padding: '20px 16px',
                boxSizing: 'border-box',
                overflow: 'scroll',
              }}
            >
              <p style={{ fontSize: '14px', color: 'rgba(16, 24, 40, 0.64)' }}>
                You can view your personalised Will Preview once you add your:
              </p>
              <ContentWrapper>
                <img src={willPersonal} alt='' />
                <p className='font-margin'>
                  <b>Personal Details</b>
                </p>
              </ContentWrapper>

              <ContentWrapper>
                <img src={willEstate} alt='' />
                <p className='font-margin text-color'>
                  At least <span style={{ color: '#000' }}>1 Asset</span>
                </p>
              </ContentWrapper>
              <ContentWrapper>
                <img src={willDistribution} alt='' />
                <p className='font-margin'>
                  Distribution <span className='text-color'>Instructions</span>
                </p>
              </ContentWrapper>
              <ContentWrapper>
                <img src={willExecutor} alt='' />
                <p className='font-margin'>
                  <span className='text-color'>Your</span> Executor
                </p>
              </ContentWrapper>
            </div>
          )}
        </div>
      </StyledSummaryCardGrid>
      <WillModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        url={url}
        header={'Will Preview'}
      />
    </Grid>
  );
};

export default PreviewWill;
