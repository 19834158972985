import { useAppSelector } from 'store/hooks';
import { selectActiveWill, selectUserProfile } from 'store/user/selectors';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import logo from 'assets/svg/yellowLogo.svg';
import { Mixpanel } from 'utils/mixpanel';
import helpIcon from 'assets/images/willStepsHelpIcon.png';
import MuiDrawer from 'common/Reusables/Drawer';
import book from 'assets/svg/book.svg';
import contact from 'assets/svg/menuItem-contact.svg';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Button, Divider, Grid, Typography } from '@mui/material';
import BackToSectionSummary from 'Pages/MyWill/BackToSectionSummary';
import { useEffect, useState } from 'react';
import Header from 'common/Header';
import { AssetSubTypesWrapper, HelpWrapper, QueryHelpCard } from 'Pages/MyWill/NewMyWill';
import OverlayHead from 'Pages/MyWill/CreateWill/Steps/OverlayHead';
// import { Intercom } from 'utils/intercom';
import ContactUsHome from 'Pages/ContactUs';
import { breakpoints } from 'styles/Breakpoints';
import pendingInviteImage from 'assets/svg/pending-invite.svg';
import acceptedInviteImage from 'assets/svg/accepted-invite.svg';
import declinedInviteImage from 'assets/svg/declined-invite.svg';
import beneficiaryRoleImage from 'assets/svg/beneficiary-role.svg';
import executorRoleImage from 'assets/svg/executor-role.svg';
import witnessRoleImage from 'assets/svg/witness-role.svg';
import guardianRoleImage from 'assets/svg/guardian-role.svg';
import pendingRequestsImage from 'assets/svg/pending-requests-icon.svg';
import { GET_ALL_MY_ROLES, UPDATE_MY_ROLE_STATUS } from 'common/queries';
import to from 'await-to-js';
import { runMutation, runQuery } from 'utils/graphQL';
import { MyRolesInput, MyRoles, WillRoles } from 'utils/types';
import loadingGIFYellow from 'assets/Yellow_Loader_GREYBG.gif';

// constants

const roleTypeIcons = {
  [WillRoles.BENEFICIARY]: beneficiaryRoleImage,
  [WillRoles.EXECUTOR]: executorRoleImage,
  [WillRoles.WITNESS]: witnessRoleImage,
  [WillRoles.GUARDIAN]: guardianRoleImage,
  [WillRoles.BACKUP_EXECUTOR]: executorRoleImage,
  [WillRoles.BACKUP_GUARDIAN]: guardianRoleImage,
};

const roleTypeDescriptions = {
  [WillRoles.BENEFICIARY]: 'Recipient of assets or interests as outlined in your Will.',
  [WillRoles.EXECUTOR]: 'Tasked with managing and distributing your estate according to your Will.',
  [WillRoles.WITNESS]:
    'Responsible for the care of minor children or dependents if the need arises.',
  [WillRoles.GUARDIAN]:
    'Responsible for the care of minor children or dependents if the need arises.',
  [WillRoles.BACKUP_EXECUTOR]:
    "A backup Executor for a Will is someone chosen to carry out the wishes specified in the Will, if the primary Executor can't or won't do it.",
  [WillRoles.BACKUP_GUARDIAN]:
    "Responsible for the care of minor children or dependents if the primary Guardian can't or won't do it.",
};

// Styled component for Flex container
const FlexContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const LeftSection = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
}));

const RightSection = styled(Grid)(({ theme }) => ({
  display: 'flex',
}));

const SVGImage = styled('img')({
  // Styles for the SVG image
  maxWidth: '100%',
  maxHeight: '100%',
  marginRight: '10px', // Adjust as needed
});

const Text = styled('p')({
  // Styles for the text
  // Add your desired styles here
});

const RoleChicklet = styled('div')({
  borderRadius: '19px',
  padding: '8px 16px', // Top, Right, Bottom, Left
  gap: '8px',
  backgroundColor: '#F1EDE0',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 'max-content',
});

const CustomSection = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const RightButtons = styled('div')({
  display: 'flex',
  gap: '16px',
});

const DeclineButton = styled(Button)({
  color: '#BA1B1B!important',
  borderRadius: '12px!important',
  border: '1.5px solid #BA1B1B!important',
  '&:hover': {
    backgroundColor: 'transparent',
  },
});

const AcceptButton = styled(Button)({
  backgroundColor: '#1D2939!important',
  borderRadius: '12px!important',
  border: '1.5px solid #1D2939!important',
  color: 'white',
  '&:hover': {
    backgroundColor: '#1D2939!important',
  },
});

const MainWrapper = styled(Grid)`
  background-color: #f8f8f8;
  @media screen and (max-width: ${breakpoints.md}) {
    padding: 24px;
  }
  .intro {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 32px;
    color: #101828;
  }
`;

const Loader = styled.div`
  width: 100%;
  height: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 7.5rem;
  }
`;

const MyRolesPage = () => {
  const navigate = useNavigate();
  // const [isLoading, setIsLoading] = useState<boolean>(true);
  const userActiveWill = useAppSelector(selectActiveWill);
  const userProfile = useAppSelector(selectUserProfile);
  const [formattedAssignedRoles, setFormattedAssignedRoles] = useState<Record<
    string,
    Record<string, MyRolesInput[]>
  > | null>(null);
  const [showInfoScreen, setShowInfoScreen] = useState<boolean>(false);
  const [showContactUsScreen, setShowContactUsScreen] = useState(false);
  const themew = useTheme();
  const lessThanSmall = useMediaQuery(themew.breakpoints.down('sm'));

  //   if (isLoading) return <Loading />;

  const onHelpClickRightSide = () => {
    setShowInfoScreen(true);
    Mixpanel.track('Question mark button clicked', {
      card_name: 'My Roles',
    });
  };

  const transformMyRoles = (
    _myAssignedRoles: MyRoles[],
  ): Record<string, Record<string, MyRolesInput[]>> => {
    const data: Record<string, Record<string, MyRolesInput[]>> = {
      markedAssignedRoles: {},
      unmarkedAssignedRoles: {},
    };

    for (const myRole of _myAssignedRoles) {
      const roleName = myRole.assignorName;
      const roleStatusList = myRole.assignedRoles || [];

      for (const roleStatus of roleStatusList) {
        const roleStatusCategory =
          roleStatus.isApproved === null ? 'unmarkedAssignedRoles' : 'markedAssignedRoles';

        if (!data[roleStatusCategory][roleName]) {
          data[roleStatusCategory][roleName] = [];
        }

        data[roleStatusCategory][roleName].push({
          assigneePhoneNumber: myRole.assigneePhoneNumber,
          assignorUserId: myRole.assignorUserId,
          assignorName: myRole.assignorName,
          roleStatus,
        });
      }
    }

    return data;
  };

  const fetchAllMyRoles = async (phoneNumber: string) => {
    const {
      data: { getAllMyRoles: assignedRoles },
    } = (
      await to(
        runQuery({
          query: GET_ALL_MY_ROLES,
          input: {
            key: 'input',
            value: phoneNumber,
          },
        }),
      )
    )[1];
    // console.log(JSON.stringify(transformMyRoles(assignedRoles), null, 2));
    setFormattedAssignedRoles(transformMyRoles(assignedRoles));
  };

  const updateMyRoleStatus = async (myRolesInput: MyRolesInput) => {
    const {
      data: { updateMyRoleStatus: assignedRoles },
    } = (
      await to(
        runMutation({
          mutation: UPDATE_MY_ROLE_STATUS,
          variables: {
            key: 'input',
            value: myRolesInput,
          },
        }),
      )
    )[1];
    // console.log(JSON.stringify(transformMyRoles(assignedRoles), null, 2));
    setFormattedAssignedRoles(transformMyRoles(assignedRoles));
  };

  useEffect(() => {
    if (userProfile?.phoneNumber) {
      fetchAllMyRoles(userProfile?.phoneNumber);
    }
  }, [userProfile?.phoneNumber]);

  return (
    // <WillCreationLayoutWrapper>
    <>
      <div className={'hide_in_mobile'}>
        <Grid
          style={{
            padding: '20px 85px',
            borderBottom: '1px solid rgba(29, 41, 57, 0.16)',
          }}
        >
          <img style={{ width: '112px' }} src={logo} alt='logo' />
        </Grid>
      </div>
      <MainWrapper>
        {userActiveWill && Object.keys(userActiveWill).length > 0 && (
          <>
            <div className={'hide_in_mobile'}>
              <BackToSectionSummary text='Back to My Will' action={() => navigate(-1)} />
            </div>
            <div className={'show_in_mobile w-100 flex flex-row'}>
              <Header
                // backgroundColorClass={userActiveWill ? 'background_white' : 'background_lightBeige'}
                backgroundColorClass={'background_white'}
                // @ts-ignore
                centerItems={true}
                backButton={true}
                title={'My Will'}
                backButtonOnClick={() => {}}
                hideAvatar={true}
                renderRightHandSide={
                  lessThanSmall ? (
                    <HelpOutlineIcon onClick={onHelpClickRightSide} />
                  ) : (
                    <img
                      style={{
                        height: '60px',
                        ...(lessThanSmall ? {} : { marginTop: '33px', marginRight: '80px' }),

                        cursor: 'pointer',
                      }}
                      src={helpIcon}
                      alt='Help Icon'
                      onClick={onHelpClickRightSide}
                    />
                  )
                }
              />
            </div>
            <Grid
              style={{
                padding: lessThanSmall ? '0px 10px' : '0px 80px',
                paddingBottom: '300px',
                marginTop: '11px',
                justifyContent: 'space-between',
              }}
              container
              columns={{ sm: 12, xs: 12, md: 12 }}
            >
              <Grid
                item
                sm={12}
                xs={12}
                md={12}
                style={
                  {
                    // ...(!lessThanSmall ? {} : {}),
                  }
                }
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: '16px',
                    alignItems: 'center',
                    ...(lessThanSmall ? { width: '100%' } : {}),
                  }}
                >
                  <h3
                    className={'font-roboto-bold'}
                    style={{
                      fontStyle: 'normal',
                      fontWeight: '400',
                      fontSize: lessThanSmall ? '24px' : '32px',
                      lineHeight: '40px',
                      color: '#101828',
                    }}
                  >
                    My Roles
                  </h3>
                  <div className={'hide_in_mobile'}>
                    {lessThanSmall ? (
                      <HelpOutlineIcon onClick={onHelpClickRightSide} />
                    ) : (
                      <img
                        style={{ height: '60px', cursor: 'pointer' }}
                        src={helpIcon}
                        alt='Help Icon'
                        onClick={onHelpClickRightSide}
                      />
                    )}
                  </div>
                </div>
                <MuiDrawer
                  open={showInfoScreen}
                  hideDrawer={() => setShowInfoScreen(false)}
                  width={'min(100%, 500px)'}
                >
                  <AssetSubTypesWrapper>
                    <OverlayHead
                      heading='My Roles'
                      onClick={() => setShowInfoScreen(false)}
                      setShowHelp={() => setShowInfoScreen(true)}
                    />
                    <p className='notify-help-text'>
                      According to the current law in India, a Will is not valid unless it has been
                      physically signed in the presence of 2 witnesses.
                    </p>
                    <p className='notify-help-text'>
                      If you would like, we are happy to print and ship your Will to you free of
                      charge*. You can also get a soft copy emailed to you and print it out
                      yourself.
                    </p>
                    <p className='notify-help-text' style={{ color: '#666B75' }}>
                      * Free for first-time Will requests only. Updates may be printed and shipped
                      for a fee.
                    </p>
                  </AssetSubTypesWrapper>
                  <HelpWrapper container>
                    <Grid
                      item
                      lg={12}
                      className='queries_section'
                      display={'flex'}
                      flexDirection='column'
                      justifyContent='space-around'
                      alignItems={'center'}
                    >
                      <p className='subText font-semi-bold'>Have a specific query in mind?</p>
                      <QueryHelpCard
                        icon={book}
                        text='Explore FAQs'
                        onClick={() => window.open('https://getyellow.in/faq', '_blank')}
                      />
                      <QueryHelpCard
                        icon={contact}
                        text='Contact Us'
                        onClick={() => {
                          // Intercom.hideOverLayAndShowIntercom({
                          //   setShowOverlay: setShowInfoScreen,
                          // })
                          setShowContactUsScreen(true);
                        }}
                      />
                    </Grid>
                  </HelpWrapper>
                </MuiDrawer>

                <MuiDrawer
                  open={showContactUsScreen}
                  width={'min(100%, 500px)'}
                  hideDrawer={() => setShowContactUsScreen(false)}
                  anchor={lessThanSmall ? 'bottom' : 'right'}
                  borderRadius={lessThanSmall ? '24px 24px 0px 0px' : ''}
                >
                  <ContactUsHome closeIconAction={() => setShowContactUsScreen(false)} />
                </MuiDrawer>

                <p className='intro subText font-roboto'>
                  Here you can see the list of people who have invited you to be a part of their
                  Will
                </p>
                {formattedAssignedRoles && formattedAssignedRoles?.unmarkedAssignedRoles ? (
                  <>
                    {(Object.keys(formattedAssignedRoles?.unmarkedAssignedRoles ?? {})?.length ??
                      0) > 0 && (
                      <>
                        <div className='flex' style={{ alignItems: 'flex-start' }}>
                          <SVGImage src={pendingRequestsImage} alt='Pending Requests' />
                          <Typography variant='h5' gutterBottom>
                            PENDING REQUESTS
                          </Typography>
                        </div>
                        {Object.values(formattedAssignedRoles?.unmarkedAssignedRoles ?? {}).map(
                          (myRolesInputList) =>
                            myRolesInputList.map((myRolesInput) => (
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                style={{
                                  backgroundColor: '#FFFFFF',
                                  padding: '32px 24px 32px 24px',
                                  borderRadius: '12px',
                                  gap: '8px',
                                  marginBottom: '16px',
                                }}
                              >
                                <FlexContainer container>
                                  {/* Left part */}
                                  <LeftSection item xs={12} sm={10} md={10}>
                                    <SVGImage src={pendingInviteImage} alt='Your SVG' />
                                    <Text>
                                      <strong>{myRolesInput.assignorName}</strong> has invited you
                                      to play a part in their Will as a a
                                    </Text>
                                  </LeftSection>
                                  {/* Right part */}
                                  <RightSection item xs={12} sm={2} md={2}>
                                    <Text style={{ color: 'rgba(29, 41, 57, 0.64)' }}>
                                      {myRolesInput.roleStatus?.assignedOn}
                                    </Text>
                                  </RightSection>
                                </FlexContainer>
                                <br />
                                <Divider />
                                <br />
                                <CustomSection>
                                  <div className='flex' style={{ alignItems: 'center', gap: 8 }}>
                                    <RoleChicklet>
                                      <SVGImage
                                        src={
                                          roleTypeIcons[
                                            myRolesInput.roleStatus?.roleType ??
                                              WillRoles.BENEFICIARY
                                          ]
                                        }
                                        alt='Your SVG'
                                      />
                                      <Text>{myRolesInput.roleStatus?.roleType}</Text>
                                    </RoleChicklet>
                                    <Typography
                                      variant='body1'
                                      style={{ color: 'rgba(16, 24, 40, 0.64)' }}
                                    >
                                      {
                                        roleTypeDescriptions[
                                          myRolesInput.roleStatus?.roleType ?? WillRoles.BENEFICIARY
                                        ]
                                      }
                                    </Typography>
                                  </div>
                                  {/* Right buttons */}
                                  <RightButtons>
                                    <AcceptButton
                                      variant='contained'
                                      onClick={() => {
                                        const updatedMyRolesInput = { ...myRolesInput };
                                        updatedMyRolesInput.roleStatus = {
                                          ...(updatedMyRolesInput.roleStatus || {
                                            assignedOn: '',
                                          }),
                                          isApproved: true,
                                        };
                                        updateMyRoleStatus(updatedMyRolesInput);
                                      }}
                                    >
                                      Accept
                                    </AcceptButton>
                                    <DeclineButton
                                      variant='outlined'
                                      onClick={() => {
                                        const updatedMyRolesInput = { ...myRolesInput };
                                        updatedMyRolesInput.roleStatus = {
                                          ...(updatedMyRolesInput.roleStatus || {
                                            assignedOn: '',
                                          }),
                                          isApproved: false,
                                        };
                                        updateMyRoleStatus(updatedMyRolesInput);
                                      }}
                                    >
                                      Decline
                                    </DeclineButton>
                                  </RightButtons>
                                </CustomSection>
                              </Grid>
                            )),
                        )}
                      </>
                    )}
                    <br />
                    {(Object.keys(formattedAssignedRoles?.markedAssignedRoles ?? {})?.length ?? 0) >
                      0 && (
                      <>
                        <div className='flex' style={{ alignItems: 'flex-start' }}>
                          <SVGImage src={acceptedInviteImage} alt='Accepted Roles Image' />
                          <Typography variant='h5' gutterBottom>
                            CURRENT ROLES YOU HAVE ACCEPTED
                          </Typography>
                        </div>
                        {Object.values(formattedAssignedRoles?.markedAssignedRoles ?? {}).map(
                          (myRolesInputList) =>
                            myRolesInputList.map(
                              (myRolesInput) =>
                                myRolesInput.roleStatus?.isApproved === true && (
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    style={{
                                      backgroundColor: '#FFFFFF',
                                      padding: '32px 24px 32px 24px',
                                      borderRadius: '12px',
                                      gap: '8px',
                                      marginBottom: '16px',
                                    }}
                                  >
                                    <FlexContainer container>
                                      {/* Left part */}
                                      <LeftSection item xs={12} sm={10} md={10}>
                                        <Text>
                                          You have accepted{' '}
                                          <strong>{myRolesInput.assignorName}’s</strong> request to
                                          play a part in their Will as a
                                        </Text>
                                      </LeftSection>
                                      {/* Right part */}

                                      <RightSection item xs={10} sm={2} md={2}>
                                        <div>
                                          <RoleChicklet>
                                            <SVGImage
                                              src={
                                                roleTypeIcons[
                                                  myRolesInput.roleStatus?.roleType ??
                                                    WillRoles.BENEFICIARY
                                                ]
                                              }
                                              alt='Your SVG'
                                            />
                                            <Text>{myRolesInput.roleStatus?.roleType}</Text>
                                          </RoleChicklet>
                                          <Text
                                            style={{
                                              color: 'rgba(29, 41, 57, 0.64)',
                                              paddingTop: '20px',
                                            }}
                                          >
                                            {myRolesInput.roleStatus?.assignedOn}
                                          </Text>
                                        </div>
                                      </RightSection>
                                    </FlexContainer>
                                  </Grid>
                                ),
                            ),
                        )}
                        <Divider />
                        <br />
                        <div className='flex' style={{ alignItems: 'flex-start' }}>
                          <SVGImage src={declinedInviteImage} alt='Declined Roles Image' />
                          <Typography variant='h5' gutterBottom>
                            CURRENT ROLES YOU HAVE DECLINED
                          </Typography>
                        </div>
                        {Object.values(formattedAssignedRoles?.markedAssignedRoles ?? {}).map(
                          (myRolesInputList) =>
                            myRolesInputList.map(
                              (myRolesInput) =>
                                myRolesInput.roleStatus?.isApproved === false && (
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    style={{
                                      backgroundColor: '#FFFFFF',
                                      padding: '32px 24px 32px 24px',
                                      borderRadius: '12px',
                                      gap: '8px',
                                      marginBottom: '16px',
                                    }}
                                  >
                                    <FlexContainer container>
                                      {/* Left part */}
                                      <LeftSection item xs={12} sm={10} md={10}>
                                        <Text>
                                          You have declined{' '}
                                          <strong>{myRolesInput.assignorName}’s</strong> request to
                                          be included in his Will as
                                        </Text>
                                      </LeftSection>
                                      {/* Right part */}
                                      <RightSection item xs={12} sm={2} md={2}>
                                        <div>
                                          <RoleChicklet>
                                            <SVGImage
                                              src={
                                                roleTypeIcons[
                                                  myRolesInput.roleStatus?.roleType ??
                                                    WillRoles.BENEFICIARY
                                                ]
                                              }
                                              alt='Your SVG'
                                            />
                                            <Text>{myRolesInput.roleStatus?.roleType}</Text>
                                          </RoleChicklet>
                                          <Text
                                            style={{
                                              color: 'rgba(29, 41, 57, 0.64)',
                                              paddingTop: '20px',
                                            }}
                                          >
                                            {myRolesInput.roleStatus?.assignedOn}
                                          </Text>
                                        </div>
                                      </RightSection>
                                    </FlexContainer>
                                  </Grid>
                                ),
                            ),
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <Loader className='loader'>
                    <img src={loadingGIFYellow} alt='loading...' />
                  </Loader>
                )}
                <br />
              </Grid>
            </Grid>
          </>
        )}
      </MainWrapper>
    </>
  );
};

export default MyRolesPage;
