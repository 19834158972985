import React, { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Pages } from 'routes';
import { useTypedDispatch } from 'store/hooks';
import { addToUserStatus, UserStatus } from 'store/user/slice';
import { Mixpanel } from 'utils/mixpanel';
import styled from 'styled-components';

const InactivityContext = createContext<{ updateExpireTime: () => void } | null>(null);

const DialogWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color:rgba(16, 24, 40, 0.74);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999999 !important;

  .dialog {
    position: relative;
    width: 490px;
    height: 200px;
    background-color: white;
    padding: 20px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
    text-align: start;
    z-index: 9999999999;

    .title {
      font-size: 28px;
      margin-left: 15px;
      margin-right: 20px;
      margin-bottom: 20px;
      margin-top: 10px;
    }

    .description {
      font-size: 16px;
      margin-left: 15px;
      margin-right: 20px;
      margin-bottom: 20px;
      margin-top: 10px;
    }

    .timer {
      background-color: #FFDB5D;
      border-radius: 10px;
      padding: 3px 8px;
      color: black;
    }

    .actions {
      display: flex;
      justify-content: space-between;
      gap: 15px;
      margin-left: 15px;
      margin-right: 20px;
      margin-top: 10px;

      button {
        height: 40px;
        width: 220px;
        padding: 8px 30px;
        border-radius: 8px;
        cursor: pointer;

        &.logout {
          background-color: #ffffff;
          border: 1px solid #000000;
          color: black;
        }

        &.stay {
          background-color: #1D2939;
          border: 1px solid #000000;
          color: white;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .dialog {
      width: 85%;
      height: auto;
      padding: 20px;  

      .title {
        font-size: 20px;
      }

      .description {
        font-size: 16px;
      }

      .timer {
        font-size: 16px;
      }

      .actions {
        flex-direction: row; /* Stack buttons vertically */
        gap: 10px;
        width: 100%; /* Ensure actions container fills the dialog */
        margin: 0px;

        button {
          flex: 1; /* Let buttons take equal space */
          font-size: 14px;
          padding: 10px 20px;
        }
      }
    }
  }
`;

export const InactivityProvider = ({
  children,
  timeout = 10 * 60 * 1000, // Default to 10 minutes
}: {
  children: React.ReactNode;
  timeout?: number;
}) => {
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState<number>(0);
  const [logoutTimer, setLogoutTimer] = useState<number | null>(null);
  const [isActive, setIsActive] = useState(false);
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const updateExpireTime = () => {
    if (isDialogVisible || !isActive) return;
    const expireTime = Date.now() + timeout;
    localStorage.setItem('expireTime', expireTime.toString());
  };

  const handleLogout = () => {
    localStorage.clear();
    dispatch({
      type: 'LOGOUT_ATTEMPT',
    });
    dispatch(addToUserStatus(UserStatus.LOGGED_OUT));
    window.Intercom && window.Intercom('shutdown');
    Mixpanel.setUserLoggedOut();
    navigate(Pages.LOGIN);
    setIsActive(false);
  };

  const checkForInactivity = () => {
    if (!isActive || location.pathname === Pages.LOGIN || location.pathname === Pages.HOME) return;

    const expireTime = localStorage.getItem('expireTime');
    if (!expireTime) return;

    const timeLeft = Number(expireTime) - Date.now();

    if (timeLeft <= 0) {
      handleLogout();
    } else if (timeLeft <= 60 * 1000 && !isDialogVisible) {

      // const razorpayWindow = document.querySelector('.razorpay-container');
      // console.log("ns - razorpayWindow found: ", razorpayWindow);

      // if (razorpayWindow) {
      //   //Remove the razorpay window after 10 seconds (change 10000 to increase window deletion duration)
      //   razorpayWindow.remove();
      //   // Reset the page's state
       
      // }

      setIsDialogVisible(true);
      setTimeRemaining(Math.floor(timeLeft / 1000));

      setLogoutTimer(
        window.setTimeout(() => {
          handleLogout();
        }, timeLeft)
      );
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      checkForInactivity();
      if (isDialogVisible) {
        setTimeRemaining((prev) => (prev > 0 ? prev - 1 : 0));
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [isDialogVisible, logoutTimer, isActive, location.pathname]);

  useEffect(() => {
    if (!isActive || location.pathname === Pages.LOGIN) return;
    updateExpireTime();
    const events = ['click', 'keypress', 'scroll', 'mousemove'];
    events.forEach((event) => window.addEventListener(event, updateExpireTime));

    return () => {
      events.forEach((event) => window.removeEventListener(event, updateExpireTime));
    };
  }, [timeout, isActive, location.pathname]);

  useEffect(() => {
    if (location.pathname !== Pages.LOGIN) {
      setIsActive(true);
      updateExpireTime();
    }
  }, [location.pathname]);

  const handleDialogAction = (isUserActive: boolean) => {
    if (isUserActive) {
      updateExpireTime();
    } else {
      handleLogout();
    }
    setIsDialogVisible(false);
    if (logoutTimer) clearTimeout(logoutTimer);
  };

  return (
    <InactivityContext.Provider value={{ updateExpireTime }}>
      <div>{children}</div>
      {isDialogVisible && (
        <DialogWrapper>
          <div className="dialog">
            <h2 className="title">You will be logged out</h2>
            <p className="description">
              For your security, your session will expire in <strong><span className="timer">{timeRemaining} seconds</span></strong> due to inactivity. Please tap <strong>“Stay Logged In”</strong> to continue.
            </p>

            <div className="actions">
              <button className="logout" onClick={() => handleDialogAction(false)}>
                Log Out now
              </button>
              <button className="stay" onClick={() => handleDialogAction(true)}>
                Stay Logged In
              </button>
            </div>
          </div>
        </DialogWrapper>
      )}
    </InactivityContext.Provider>
  );
};

export const useInactivity = () => useContext(InactivityContext);
