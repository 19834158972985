import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import store from 'store';
import { Provider } from 'react-redux';
import { ApolloProvider } from '@apollo/client';
import client from 'utils/apollo';
import { InactivityProvider } from 'InActivityProvider';

// This is required so that typescript doesn't complain
// about the lack of Intercom on the global window object.
declare global {
  interface Window {
    Intercom: any;
    RF: any;
  }
}

ReactDOM.render(
  <BrowserRouter>
    <ApolloProvider client={client}>
      <Provider store={store}>
        <InactivityProvider>
          <App />
        </InactivityProvider>

      </Provider>
    </ApolloProvider>
  </BrowserRouter>,
  document.getElementById('root'),
);
export { SectionSummaryScreens } from './common/SectionScreenSummary';
