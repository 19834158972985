import { useEffect, useState } from 'react';
import { LinearProgress, linearProgressClasses } from '@mui/material';
import CustomButton from '../../../../../common/Reusables/Button';
import will_signing_video_upload_box_icon from '../../../../../assets/svg/will_signing_video_upload_box_icon.svg';
import will_signing_upload_file_icon from '../../../../../assets/svg/will_signing_upload_file_icon.svg';
import { useAppSelector } from '../../../../../store/hooks';
import { selectActiveWill } from '../../../../../store/user/selectors';
import dateformat from 'dateformat';
import { useDropzone } from 'react-dropzone';
import hash from 'object-hash';
import UploadingConfitmationWindow from './UploadingConfitmationWindow';
import { runMutation, runQuery } from '../../../../../utils/graphQL';
import { GET_WILL_SIGNING_VIDEO_UPLOAD_URL, UPDATE_USER_WILL } from '../../../../../common/queries';
import to from 'await-to-js';
import isFinite from 'lodash.isfinite';
import axios, { AxiosRequestConfig } from 'axios';
import Box from '@mui/material/Box';
import { Will, WillTypes } from '../../../../../utils/types';
import { updateActiveWill } from '../../../../../store/user/slice';
import { useDispatch } from 'react-redux';
import Tooltip from '@mui/material/Tooltip';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const MAX_VIDEO_FILE_SIZE_MB = 300;
const BYTES_PER_MB = 1048576;

const MyVideoWillSummary = ({
  setIsUploadingVideo,
  setJustCompletedUploading,
}: {
  setIsUploadingVideo: Function;
  setJustCompletedUploading: Function;
}) => {
  const themew = useTheme();
  const lessThanSmall = useMediaQuery(themew.breakpoints.down('sm'));

  const dispatch = useDispatch();
  const activeWill = useAppSelector(selectActiveWill);
  const [showNextButton, setShowNextButton] = useState<boolean>(false);
  const { acceptedFiles, getRootProps, getInputProps, open } = useDropzone({
    accept: ['video/*'],
    noClick: true,
    noKeyboard: true,
    maxFiles: 1,
    maxSize: BYTES_PER_MB * MAX_VIDEO_FILE_SIZE_MB,
    onDropRejected: (fileRejections, event) => {
      let message = 'An error occurred, please try again.';
      switch (fileRejections[0].errors[0].code) {
        case 'file-too-large':
          message = `File dropped is larger than ${MAX_VIDEO_FILE_SIZE_MB}MB`;
          break;
        case 'file-invalid-type':
          message = 'Invalid file format dropped. Video format file only.';
          break;
      }
      alert(message);
    },
  });
  const [showDoubleConfirmation, setShowDoubleConfirmation] = useState(false);
  const [, setGetWillSigningVideoUploadInfoInState] = useState<
    { fileName: string; url: string } | undefined
  >(undefined);
  const [progressPercentageOrError, setProgressPercentageOrError] = useState<number | Error | null>(
    null,
  );
  useEffect(() => {
    (async () => {
      console.log(
        'in WillSigningUploadingPage, in acceptedFiles useEffect, acceptedFiles is:' +
          acceptedFiles?.length,
      );
      if (acceptedFiles?.length === 1) {
        setShowDoubleConfirmation(true);
        //const uploadLink = '';
        const {
          data: { getWillSigningVideoUploadInfo },
        } = (await to(runQuery({ query: GET_WILL_SIGNING_VIDEO_UPLOAD_URL })))[1];
        console.log(
          'in WillSigningUploadingPage, in acceptedFiles useEffect, getWillSigningVideoUploadInfo is:' +
            JSON.stringify(getWillSigningVideoUploadInfo, null, 2),
        );
        if (getWillSigningVideoUploadInfo?.url) {
          setGetWillSigningVideoUploadInfoInState(getWillSigningVideoUploadInfo);
          const config: AxiosRequestConfig = {
            onUploadProgress: function (progressEvent: any) {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total,
              );
              setProgressPercentageOrError(percentCompleted);
            },
          };

          let res = await to(
            axios.put(getWillSigningVideoUploadInfo?.url, acceptedFiles[0], config),
          );
          if (res[0]) {
            setProgressPercentageOrError(new Error('Error occurred while uploading'));
          } else {
            setProgressPercentageOrError(100);

            const updateWillRequestBody: Will = {
              documentId: activeWill?.documentId || ('' as string),
              type: WillTypes.PREMIUM_WILL,
              signingDocuments: {
                videoURL: getWillSigningVideoUploadInfo?.fileName,
                videoUploadedAt: new Date().toISOString(),
              },
            } as Will;

            const {
              data: { updateWill: updatedWill },
            }: any = await runMutation({
              mutation: UPDATE_USER_WILL,
              variables: {
                key: 'input',
                value: updateWillRequestBody,
              },
            });

            if (updatedWill) {
              dispatch(
                updateActiveWill({
                  will: updatedWill,
                }),
              );
            }
            setShowNextButton(true);
          }
        } else {
          console.log('in WillSigningUploadingPage, setting as error');
          setProgressPercentageOrError(new Error('Unable to get upload link'));
        }
      }
    })();
  }, [hash(acceptedFiles)]);

  const nextButtonOnClick = () => {
    setJustCompletedUploading(true);
    setIsUploadingVideo(false);
  };
  return (
    <>
      <div
        className={`flex flex-col rounded-radius12 gap-16px ${
          lessThanSmall ? 'p-16px' : 'p-28px'
        } w-100 border-box`}
        style={{ backgroundColor: '#E7ECF3' }}
      >
        <h4 className={'heading6'}>
          Upload a video of yourself reading your Will and signing your Will document.
        </h4>
        {activeWill && activeWill?.dateOfCreation ? (
          <span className={'heading7generic font-roboto'} style={{ color: 'rgba(16, 24, 40, 0.56)' }}>
            Your latest Will ID is{' '}
            <span className={'font-roboto-bold'}>{`${(activeWill?.documentId || '').length > 4 ? '...' : ''
              }${(activeWill?.documentId || '').slice(-4)}`}</span>
            {'. '}
            It was generated on{' '}
            <span className={'font-roboto-bold'}>
              {dateformat(new Date(activeWill?.dateOfCreation), 'dS mmm, yyyy')}
            </span>
          </span>
        ) : null}
        {progressPercentageOrError ? (
          <div
            className={
              'flex flex-col items-center justify-center gap-16px p-24px background_white rounded-radius12'
            }
          >
            <div
              className={`${
                lessThanSmall ? 'grid' : 'flex flex-row'
              } relative flex-row items-center justify-between w-100`}
            >
              <div
                className={`flex items-center justify-start w-auto gap-8px max-w-80 border-box w-min-content`}
              >
                <img
                  src={will_signing_upload_file_icon}
                  className={'h-16px w-16px object-fit-contain'}
                />
                <Tooltip title={acceptedFiles?.[0]?.name || ''}>
                  <span
                    className={'xs_text font-roboto overflow-hidden nowrap'}
                    style={{
                      width: lessThanSmall ? '50vw' : 'auto',
                      maxWidth: lessThanSmall ? '50vw' : 'calc(100% - 24px)',
                      textOverflow: 'ellipsis',
                      lineClamp: 1,
                    }}
                  >
                    {acceptedFiles?.[0]?.name}
                  </span>
                </Tooltip>
              </div>
              <div
                style={{
                  ...(lessThanSmall
                    ? { width: '100%', position: 'relative', minHeight: '30px' }
                    : {}),
                }}
              >
                <span
                  className={'xs_text w-min-content nowrap p-4px font-roboto'}
                  style={{
                    backgroundColor: isFinite(progressPercentageOrError)
                      ? Number(progressPercentageOrError) > 99
                        ? '#E7F6EA'
                        : 'transparent'
                      : '#facdcd',
                    borderRadius: '5px',
                    color: isFinite(progressPercentageOrError)
                      ? Number(progressPercentageOrError) > 99
                        ? '#3C8B3B'
                        : '#101828'
                      : 'red',
                    ...(lessThanSmall ? { position: 'absolute', right: 0, bottom: 0 } : {}),
                  }}
                >
                  {isFinite(progressPercentageOrError) ? `${progressPercentageOrError} %` : 'ERROR'}
                </span>
              </div>
            </div>
            <Box sx={{ width: '100%' }}>
              <LinearProgress
                variant='determinate'
                value={
                  isFinite(progressPercentageOrError) ? Number(progressPercentageOrError) : 100
                }
                sx={{
                  height: '20px',
                  backgroundColor: 'transparent',
                  [`& .${linearProgressClasses.bar}`]: {
                    borderRadius: '5px',
                    backgroundColor: isFinite(progressPercentageOrError) ? '#1270E8' : 'red',
                  },
                }}
              />
            </Box>
            {showNextButton ? <CustomButton text={'Next'} onClick={() => {
              (window as any).webengage.track("Will Recording added", {
                "Added Recording": "yes"
              });
              (window as any).webengage.user.setAttribute("Video Recorded", true);
              nextButtonOnClick() }} /> : null}
          </div>
        ) : (
          <div
            className={
              'flex flex-col items-center justify-center gap-16px p-24px background_white rounded-radius12'
            }
            {...getRootProps({
              style: {
                borderWidth: '0.50px',
                borderColor: 'rgba(29, 41, 57, 0.44)',
                borderStyle: 'dotted',
              },
            })}
          >
            <input {...getInputProps()} capture={false} />
            <img className={'h-100px w-100px'} src={will_signing_video_upload_box_icon} />
            <span className={'xs_text font-roboto'} style={{color: 'rgba(16, 24, 40, 0.64)'}}>Drag and drop video file to upload</span>
            <CustomButton 
              onClick={open} 
              text={'Choose file'}
              style={{
                padding: '8px 44px',
                height: 'auto',
                borderRadius: '8px',
                fontSize: '14px'

              }}
            />
            <span className={'xs_text font-roboto'} style={{color: 'rgba(16, 24, 40, 0.64)'}}>
              Maximum file size{' '}
              <span className={'font-roboto'} style={{color: 'rgba(16, 24, 40)'}}>{`${MAX_VIDEO_FILE_SIZE_MB} MB`}</span>;
              Supported formats: <span className={'font-roboto'} style={{color: 'rgba(16, 24, 40)'}}>.mov, .mp4, .avi, .webm</span>
            </span>
          </div>
        )}
      </div>
      <UploadingConfitmationWindow
        isOpenModal={showDoubleConfirmation}
        handleModalClose={() => setShowDoubleConfirmation(false)}
      />
    </>
  );
};

export default MyVideoWillSummary;
