export enum Pages {
  LOGIN = '/login',
  REGISTER = '/register',
  ONBOARDING = '/onboarding',
  WILL_EDITOR = '/will-editor',
  PAYMENT = '/payments',
  MY_WILL = '/my-will',
  KYC = '/kyc',
  PRIVACY_POLICY = '/privacy-policy',
  LEGAL = '/legal',
  FAQ = '/faq',
  SUCCESS_SCREEN = '/success-screen',
  PRICING = '/membership/pricing',
  YELLOW_PLUS_BASIC = '/membership/basic',
  MEMBER_DETAILS = '/membership/member-details',
  YELLOW_PLUS = '/membership',
  QUICK_CART = '/quick-cart',
  MY_CART = '/my-cart',
  CHECKOUT = '/checkout',
  MY_ACCOUNT = '/my-account',
  PAYMENT_HISTORY = '/payment-history',
  DESKTOP_ONLY = '/desktop-only',
  CONGRATULATIONS = '/congratulations',
  EDIT_PROFILE = '/profile',
  WELCOME = '/welcome-page',
  DASHBOARD = '/dashboard',
  VENDOR_DASHBOARD = '/vendor-dashboard',
  CUSTOM_AUTH = '/custom-auth',
  MY_WILL_UPDATE_LOCATION = "/my-will/update-location",
  MY_WILL_SIGNING_VIDEO = "/my-will/will-signing",
  MY_ROLES = "/my-roles",
  PERSONAL_DETAILS = "/will-editor/personal-details",
  MY_ESTATE = "/will-editor/my-estate",
  MY_BENEFICIARIES = "/will-editor/my-beneficiaries",
  ASSET_DISTRIBUTION = "/will-editor/asset-distribution",
  WILL_EXECUTOR = "/will-editor/will-executor",
  GUARDIANS = "/will-editor/my-guardians",
  MY_WITNESSES = "/will-editor/my-witnesses",

  MY_WILL_SHIPPING_AND_COURIER = "/my-will/shipping-and-courier",
  DELETE_ACCOUNT = "/profile/delete-account",
  // HOME should always be the last entry.
  HOME = '/',
}

export const pagesList = Object.values(Pages).map(String);

export const YELLOW_PLUS_LEGAL = 'https://getyellow.in/terms-of-service';
export const YELLOW_RESOURCES = 'https://getyellow.in/resources';
export const SITE_FAQ = 'https://getyellow.in/faq/yellow-membership';
export const MEMBERSHIP_PRICING = 'https://getyellow.in/pricing-wills'
export const LEGAL_WILL = 'https://getyellow.in/legal-will'

export const MOBILE_ROUTES = [
  Pages.LEGAL,
  Pages.LOGIN,
  Pages.REGISTER,
  Pages.MEMBER_DETAILS,
  Pages.PRICING,
  Pages.MY_CART,
  Pages.YELLOW_PLUS,
  Pages.CHECKOUT,
  Pages.MY_ACCOUNT,
  Pages.PAYMENT_HISTORY,
  Pages.DESKTOP_ONLY,
  Pages.SUCCESS_SCREEN,
  Pages.PRIVACY_POLICY,
  Pages.CONGRATULATIONS,
  Pages.EDIT_PROFILE,
  Pages.YELLOW_PLUS_BASIC,
  Pages.DASHBOARD,
  Pages.QUICK_CART,
  Pages.WILL_EDITOR,
  Pages.PAYMENT,
  Pages.MY_WILL,
  Pages.MY_ROLES,
  Pages.HOME,
];

export const HIDE_INTERCOM_PAGES: Pages[] = [];
