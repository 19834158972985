import { Checkbox, Grid } from '@mui/material';
import React, { forwardRef, useState } from 'react';
import CustomButton from '../../../../../common/Reusables/Button';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const pointsToRemember = [
  {
    title: 'Your Witnesses are present',
    text: 'Coordinate with your Witnesses and inform them of the time commitment',
  },
  {
    title: 'You have a copy of your Will',
    text: 'The first page of your generated Will is a summary page. Keep this in front of you to read for the recording',
  },
  {
    title:
      'You record a single video of yourself reading your Will and signing your Will document',
    text: 'Stitching together multiple videos will not be valid in a court of law.',
  },
];

export const WillSigningIntroductoryFirstRightPane = forwardRef(function (
  {
    setIsUploadingVideo,
  }: {
    setIsUploadingVideo: Function;
  },
  ref,
) {
  const theme = useTheme();
  const lessThanSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const [isUnderstoodInstructions, setIsUnderstoodInstructions] = useState<boolean>(false);
  return (
    <>
      <h4 className='heading6 pb-24px'>Before you upload your Will signing video make sure:</h4>
      {React.Children.toArray(
        pointsToRemember.map((point, index) => <PointToRemember {...point} index={index + 1} />),
      )}
      <div
        style={{ alignItems: 'center', display: 'flex', color: '#17449B' }}
        className={'pb-32px'}
      >
        <Checkbox
          checked={isUnderstoodInstructions}
          sx={{
            color: '#17449B',
            '&.Mui-checked': {
              color: '#17449B',
            },
          }}
          onChange={() => setIsUnderstoodInstructions(!isUnderstoodInstructions)}
        />
        <h4 className='font-roboto heading7 font-18px'>I understand the instructions given above.</h4>
      </div>
      <CustomButton
        style={{ width: '100%' }}
        text={'Upload video of Will signing'}
        disabled={!isUnderstoodInstructions}
        onClick={() => {
          setIsUploadingVideo(true);
        }}
        lessThanSmallPageBottom={lessThanSmall}
      />
    </>
  );
});

const PointToRemember = ({ index, title, text }: { index: number; title: string; text: any }) => (
  <Grid
    item
    lg={12}
    sm={12}
    display='flex'
    className='points pb-20px gap-16px h-min-content'
    style={{ height: 'min-content', flexGrow: 0, flexBasis: 0 }}
  >
    <div
      className={
        'min-h-32px h-32px min-w-32px w-32px rounded-radius50p background_white inline-block'
      }
    >
      <div className={'h-100 w-100 flex flex-row items-center justify-center'}>
        <span className={'font-roboto-bold'}>{index}</span>
      </div>
    </div>
    <span style={{ height: 'min-content' }}>
      <h4 className='font-roboto heading7 m-0 pb-5px text-18px'>{title}</h4>
      <p className='font-roboto m-0 text-18px font-300 text-18px'>{text}</p>
    </span>
  </Grid>
);
