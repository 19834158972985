import { ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// Style components
import styled from 'styled-components';
import { breakpoints } from 'styles/Breakpoints';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import { 
  Grid, 
  StepIconProps 
} from '@mui/material';

// Assets
import success from 'assets/svg/success.svg';
import request from 'assets/svg/printCourier-request.svg';
import requestInactive from 'assets/svg/printCourier-requestInactive.svg';
import readyForDispatch from 'assets/svg/printCourier-readyForDispatch.svg';
import readyForDispatchInactive from 'assets/svg/printCourier-readyForDispatchInactive.svg';
import onTheWay from 'assets/svg/printCourier-onTheWay.svg';
import onTheWayInactive from 'assets/svg/printCourier-onTheWayInactive.svg';
import delivered from 'assets/svg/printCourier-delivered.svg';
import deliveredInactive from 'assets/svg/printCourier-deliveredInactive.svg';
import copyIcon from 'assets/svg/copy.svg';
import arrowIcon from 'assets/svg/arrow-right-clear-bg.svg';
import mail from 'assets/svg/mail.svg';
import phone from 'assets/svg/phone.svg';
import infoIcon from 'assets/images/info.png';
import signWill from 'assets/images/sign-will.png';

// Objects, Utils, Store, Components
import { Pages } from 'routes';
import { getEmptyAddress } from 'utils';
import { CourierStatus } from 'utils/types';
import BackToSectionSummary from 'Pages/MyWill/BackToSectionSummary';
import Navbar from 'Pages/Home/Navbar';
import Header from 'common/Header';
import Loading from 'common/Loading';
import { useAppSelector } from 'store/hooks';
import { selectActiveWill } from 'store/user/selectors';
import { fetchUserProfileAction } from 'store/user/slice';

const TrackerWrapper = styled(Grid)`
  .footer {
    display: flex;
    border-top: 2px solid #edebe7;
    margin: 0px 80px;
    width: 100%;
    padding-top: 16px;
    position: fixed;
    @media screen and (max-width: ${breakpoints.md}) {
      position: unset;
      width: 100%;
      margin: 0;
      border: 0;
    }
    bottom: 0;
    padding-bottom: 20px;
    background: #ffffff;
    span {
      margin: 0px 16px;
      font-weight: 500;
      font-size: 16px;
      line-height: 28px;
      color: #101828;
    }
  }
  .intro {
    margin-top: 10px;

    .successimg {
      height: 120px;
      width: 140px;
      object-fit: contain;
      margin-bottom: 42px;
    }
    .print-will {
      font-weight: 400;
      font-size: 32px;
      line-height: 40px;
      color: #101828;
      margin-bottom: 16px;
      @media screen and (max-width: ${breakpoints.md}) {
        text-align: center;
      }
    }
    .trackingInfo {
      font-weight: 400;
      font-size: 24px;
      line-height: 28px;
      text-align: center;
      color: #101828;
    }
  }

  .resendEmail {
    width: 180px;
    padding: 0px 30px;
    background-color: #fcfcfc;
    border-radius: 12px;

    display: flex;
    justify-content: center;
    align-items: center;

    height: 40px;
  }

  .track-id {
    width: min(100%, 406px);
    height: 52px;
    border: 1px solid rgba(29, 41, 57, 0.24);
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 10px;
  }

  .info-box {
    align-items: center;
    margin-top: 10px;
    display: flex;
    align-items: self-start;
    img {
      width: 16px;
      height: 16px;
    }
    p {
      width: 374px;
      height: 44px;
      margin-left: 10px;
      color: #17449b;
    }
  }

  .mailing-address {
    width: min(100%, 406px);
    background: #f3f4f5;
    border-radius: 8px;
    box-sizing: border-box;
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 16px;
    .title {
      font-weight: 700;
      font-size: 16px;
      margin-bottom: 8px;
    }

    p {
      font-size: 16px;
    }
  }

  .next-steps {
    width: min(100%, 406px);
    box-sizing: border-box;
    background: #fff8dd;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 24px 16px;
    margin-top: 17px;
    .title {
      font-weight: 700;
      font-size: 16px;
      margin-bottom: 20px;
    }
    .step {
      display: flex;
      @media screen and (max-width: ${breakpoints.md}) {
        flex-direction: column;
        justify-content: center;
        text-align: center;
        margin: 0;
        box-sizing: border-box;
      }
      align-items: center;
      justify-content: space-between;
      margin-right: 15px;
      img {
        height: 40px;
        margin-right: 12px;
        @media screen and (max-width: ${breakpoints.md}) {
          margin-right: 0;
        }
      }
      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #101828;
        width: 108px;
      }
    }
  }

  .privacy-text {
    color: #101828;
    margin-right: 30px;
    font-size: 12px;
  }
`;

const ModalContent = styled(Box)`
  background: #fcfcfc;
  box-shadow: 0px -4px 8px rgba(97, 94, 87, 0.08);
  border-radius: 12px;
  padding: 24px 32px;
  width: 560px;
  button {
    background: #1d2939;
    border-radius: 8px;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #fcfcfc;
    width: 100%;
    padding: 11px 10px;
    text-transform: capitalize;
  }
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #101828;
    margin-bottom: 25px;
  }
  .contact {
    display: flex;
    align-items: center;
    img {
      margin-right: 14px;
    }
    a {
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #1270e8;
    }
  }
  .head {
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
    svg {
      cursor: pointer;
    }
    h3 {
      font-weight: 700;
      font-size: 20px;
      line-height: 26px;
      text-align: center;
      color: #101828;
    }
  }
`;

const style = {
  display: 'flex',
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  height: 'auto',
  justifyContent: 'center',
};

const TrackerIconWrapper = styled(Grid)`
  background-color: ${(props: { completed: boolean }) =>
    props.completed ? '#E7F6EA;' : '#F3F4F5'};
  border-radius: 50%;
  min-width: 40px;
  height: 40px;
  margin-right: 20px;
  text-align: center;

  display: flex;
  justify-content: center;
  align-items: center;

  .icon {
    height: 20px;
    width: 20px;
    object-fit: contain;
  }
`;

// Next Steps InfoBox Component
const NextSteps = () => {
  const themew = useTheme();
  const lessThanSmall = useMediaQuery(themew.breakpoints.down('sm'));
  return (
    <div className='next-steps'>
      <p className='title'>Your next steps:</p>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: lessThanSmall ? 'center' : 'start',
          ...(lessThanSmall ? { gap: '12px' } : {}),
        }}
      >
        <div className='step'>
          <img src={signWill} />
          <p>Complete signing of Will</p>
        </div>

        <img
          src={arrowIcon}
          style={{ width: '14px', height: '14px', marginRight: lessThanSmall ? '0' : '18px' }}
        />

        <div className='step'>
          <img src={signWill} />
          <p>Update Will location</p>
        </div>
      </div>
    </div>
  );
};


const PrintAndCourierRequestTracker = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const themew = useTheme();
  const lessThanSmall = useMediaQuery(themew.breakpoints.down('sm'));
  const userActiveWill = useAppSelector(selectActiveWill);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [activeStep, setActiveStep] = useState(1);
  const [address, setAddress] = useState(getEmptyAddress());
  const [isHelpDeskModal, setIsHelpDeskModal] = useState(false);


  useEffect(() => {
    dispatch(fetchUserProfileAction());
  }, [])

  const trackingSteps = [
    {
      activeIcon: request,
      inActiveIcon: requestInactive,
      label: 'Courier request received',
      status: CourierStatus.REQUEST_RECEIVED,
    },
    {
      activeIcon: readyForDispatch,
      inActiveIcon: readyForDispatchInactive,
      label: 'Ready for dispatch',
      status: CourierStatus.READY_FOR_DISPATCH,
    },
    {
      activeIcon: onTheWay,
      inActiveIcon: onTheWayInactive,
      label: 'On the way',
      status: CourierStatus.ON_THE_WAY,
    },
    {
      activeIcon: delivered,
      inActiveIcon: deliveredInactive,
      label: 'Delivered',
      status: CourierStatus.DELIVERED,
    },
  ];

  const manageActiveStep = () => {
    if (
      userActiveWill &&
      userActiveWill.courierRequestInfo &&
      userActiveWill.courierRequestInfo === null
    )
      return setActiveStep(1);

    if (userActiveWill?.courierRequestInfo?.status === CourierStatus.REQUEST_RECEIVED)
      setActiveStep(1);
    if (
      userActiveWill?.courierRequestInfo?.status === CourierStatus.READY_FOR_DISPATCH ||
      userActiveWill?.courierRequestInfo?.status === CourierStatus.READY_FOR_SHIPMENT
    )
      setActiveStep(2);
    if (
      userActiveWill?.courierRequestInfo?.status === CourierStatus.ON_THE_WAY ||
      userActiveWill?.courierRequestInfo?.status === CourierStatus.SHIPPED
    )
      setActiveStep(3);
    if (userActiveWill?.courierRequestInfo?.status === CourierStatus.DELIVERED) setActiveStep(4);
  };

  const getDateOfCreationByStatus = (status: CourierStatus): { date: string; time: string } => {
    const dateOfCreation = userActiveWill?.courierRequestInfo?.dateOfCreation.find(
      (dateOfCreation) => dateOfCreation.status === status,
    );

    return (
      dateOfCreation || {
        date: userActiveWill?.courierRequestInfo?.dateOfCreation[0].date as string,
        time: userActiveWill?.courierRequestInfo?.dateOfCreation[0].time as string,
      }
    );
  };

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(
        userActiveWill && userActiveWill.courierRequestInfo
          ? userActiveWill.courierRequestInfo.indiaPostTrackingID
          : '',
      );
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };

  useEffect(() => {
    manageActiveStep();
  }, [userActiveWill]);

  useEffect(() => {
    if (userActiveWill?.courierRequestInfo?.indiaPostTrackingID === null) {
      setIsLoading(true);
      return;
    }

    setIsLoading(false);
    if (userActiveWill?.courierRequestInfo?.mailingAddress) {
      setAddress(userActiveWill.courierRequestInfo.mailingAddress);
    }
  }, [userActiveWill]);

  if (isLoading) return <Loading />

  return (
    <TrackerWrapper>
      <div className={'hide_in_mobile'}>
        <Navbar backgroundColorClass='background_white' />
      </div>
      <div className={'hide_in_mobile'}>
        <BackToSectionSummary text='Back to My Will' action={() => navigate(Pages.MY_WILL)} />
      </div>
      <div className={'show_in_mobile'}>
        <Header
          title='Back to My Will'
          backButton={true}
          backButtonOnClick={() => navigate(Pages.MY_WILL)}
          // @ts-ignore
          centerItems={true}
        />
      </div>
      <Grid className='intro' display={'flex'} flexDirection='column' alignItems={'center'}>
        <img src={success} alt='success' className='successimg' />
        <h3 className='print-will font-merriweather'>Order placed successfully!</h3>
        {/* <p className='subText text-center trackingInfo font-roboto'>Your Will is on the way</p> */}
      </Grid>
      <Grid
        style={{
          justifyContent: 'space-between',
        }}
        container
        columns={{ xs: 12, md: 12 }}
        sx={{ padding: lessThanSmall ? '0px' : '0px 80px 100px 80px', boxSizing: 'border-box' }}
      >
        <Grid
          style={{
            borderRight: lessThanSmall ? '0' : '1px solid #E5E7EA',
            boxSizing: 'border-box',
            ...(lessThanSmall
              ? {
                gap: '16px',
                display: 'flex',
                flexDirection: 'column',
              }
              : {}),
          }}
          item
          xs={12}
          md={6}
        >
          <div className='track-id border-box'>
            <div
              style={{
                display: 'flex',
              }}
            >
              <p style={{ fontWeight: '700', fontSize: '16px' }}>Tracking ID:</p>
              <p
                style={{
                  marginLeft: '12px',
                }}
              >
                {userActiveWill?.courierRequestInfo?.indiaPostTrackingID}
              </p>
            </div>
            <img
              src={copyIcon}
              style={{ cursor: 'pointer', height: '20px' }}
              onClick={handleCopy}
            />
          </div>

          <div className={`info-box ${lessThanSmall ? 'w-100' : ''}`}>
            <img src={infoIcon} />
            <p>
              Tracking details will be sent to your Email & Phone Number once your Will is shipped
            </p>
          </div>

          <div className='mailing-address'>
            <p className='title'>Mailing Address:</p>
            {address.addressLine1 && <p>{address.addressLine1},</p>}
            {address.addressLine2 && <p>{address.addressLine2},</p>}
            <p>
              {address.city}, {address.state} - {address.pinCode}, {address.country}
            </p>
          </div>

          <div className={'hide_in_mobile'}>
            <NextSteps />
          </div>
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <div
            style={{
              border: '2px solid #ECE9DF',
              padding: '32px',
              borderRadius: '12px',
              ...(lessThanSmall
                ? {
                  marginTop: '16px',
                }
                : {}),
            }}
          >
            <h4
              style={{
                marginBottom: '32px',
              }}
            >
              Track your Will
            </h4>
            <Stepper
              activeStep={activeStep}
              orientation={'vertical'}
              sx={{
                '& .MuiStepConnector-root': {
                  marginLeft: 2.5,
                  minHeight: 32,
                  borderLeft: '2px solid #E3E4E7',
                },
                '& .MuiStepConnector-line': {
                  display: 'none',
                },
              }}
            >
              {trackingSteps.map((step, index) => {
                const stepProps: { completed?: boolean } = {
                  completed: activeStep === index + 1 || activeStep > index + 1,
                };
                const labelProps: {
                  optional?: ReactNode;
                } = {};
                return (
                  <Step key={step.label} {...stepProps}>
                    <StepLabel
                      {...labelProps}
                      sx={{
                        padding: '0',
                      }}
                      StepIconComponent={(props: StepIconProps) => {
                        const { completed } = props;
                        return (
                          <TrackerIconWrapper completed={completed as boolean}>
                            <img
                              src={completed ? step.activeIcon : step.inActiveIcon}
                              alt=''
                              className='icon'
                            />
                          </TrackerIconWrapper>
                        );
                      }}
                    >
                      <div>
                        <p
                          style={{
                            fontWeight: '400',
                            fontSize: '16px',
                            lineHeight: '22px',
                            color: '#000000',
                          }}
                          className={index < activeStep ? 'font-semi-bold ' : ''}
                        >
                          {step.status !== CourierStatus.DELIVERED
                            ? step.label
                            : userActiveWill?.courierRequestInfo?.expectedDateOfDelivery === null
                              ? step.label
                              : `Delivery By ${userActiveWill?.courierRequestInfo?.expectedDateOfDelivery}`}
                        </p>

                        {index < activeStep &&
                          userActiveWill?.courierRequestInfo?.dateOfCreation && (
                            <p style={{ color: 'rgba(16, 24, 40, 0.44)' }}>
                              <span>{getDateOfCreationByStatus(step.status).date}</span>
                            </p>
                          )}
                      </div>
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </div>

          {activeStep === 4 && (
            <div className='resendEmail'>
              <p className='xs_text'>
                Didn’t receive it?{' '}
                <span
                  className='font-semi-bold cursor-pointer'
                  onClick={() => {
                    setIsHelpDeskModal(true);
                  }}
                  style={{ textDecorationLine: 'underline', color: '#1270E8' }}
                >
                  Click here
                </span>
              </p>
            </div>
          )}

          <div className={'show_in_mobile'}>
            <NextSteps />
          </div>
        </Grid>
      </Grid>
      <div className='footer'>
        <a
          className='privacy-text'
          href='https://getyellow.in/privacy-policy'
          target={'_blank'}
          rel='noopener noreferrer'
        >
          <h3>Privacy Policy</h3>
        </a>
        <a
          className='privacy-text'
          href='https://getyellow.in/terms-of-service'
          target={'_blank'}
          rel='noopener noreferrer'
        >
          <h3>Terms of Service</h3>
        </a>
      </div>
      <div>
        <Modal
          open={isHelpDeskModal}
          onClose={() => setIsHelpDeskModal(false)}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <ModalContent sx={style}>
            <div
              style={{
                width: '100%',
              }}
            >
              <div className='head'>
                <h3>Support</h3>
                <CloseIcon style={{ fontSize: '28px' }} onClick={() => setIsHelpDeskModal(false)} />
              </div>
              <p>
                If the delivery status of your Will is{' '}
                <span
                  style={{
                    fontWeight: 'bold',
                  }}
                >
                  Delivered
                </span>
                , but you have not received it, please contact our help desk immediately.{' '}
              </p>
              <p className='contact'>
                <img src={phone} alt='phone' />
                <a
                  style={{ cursor: 'pointer' }}
                  onClick={() => navigator.clipboard.writeText('+91 8050073434')}
                >
                  +91 8050073434{' '}
                </a>
              </p>
              <p className='contact'>
                <img src={mail} alt='mail' />
                <a
                  style={{ cursor: 'pointer' }}
                  onClick={() => navigator.clipboard.writeText('helpdesk@getyellow.in')}
                >
                  helpdesk@getyellow.in{' '}
                </a>
              </p>
              <button style={{ cursor: 'pointer' }} onClick={() => setIsHelpDeskModal(false)}>
                Go to Helpdesk
              </button>
            </div>
          </ModalContent>
        </Modal>
      </div>
    </TrackerWrapper>
  );
};

export default PrintAndCourierRequestTracker;
