import BackToSectionSummary from '../../../BackToSectionSummary';
import will_sample from '../../../../../assets/images/will_sample.png';
import warning_icon_will_signing_old from '../../../../../assets/svg/warning_icon_will_signing_old.svg';
import willSigningDownloadPlayIcon from '../../../../../assets/svg/willSigningDownloadPlayIcon.svg';
import will_signing_video_download_icon from '../../../../../assets/svg/will_signing_video_download_icon.svg';
import will_signing_next_steps_carousel_arrow from '../../../../../assets/svg/will_signing_next_steps_carousel_arrow.svg';
import will_signing_next_steps_notify_my_people from '../../../../../assets/svg/will_signing_next_steps_notify_my_people.svg';
import will_signing_next_steps_refer_and_earn from '../../../../../assets/svg/will_signing_next_steps_refer_and_earn.svg';
import will_signing_next_steps_funeral_arrangements from '../../../../../assets/svg/will_signing_next_steps_funeral_arrangements.svg';
import will_signing_delete_warning_icon from '../../../../../assets/svg/will_signing_delete_warning_icon.svg';
import will_signing_replace_warning_icon from '../../../../../assets/svg/will_signing_replace_warning_icon.svg';
import { useNavigate } from 'react-router-dom';
import { SigningDocuments } from '../../../../../utils/willsigningDocumentsType';
import { useAppSelector } from '../../../../../store/hooks';
import { selectActiveWill } from '../../../../../store/user/selectors';
import dateformat from 'dateformat';
import CustomButton from '../../../../../common/Reusables/Button';
import { Grid } from '@mui/material';
import { runMutation, runQuery } from '../../../../../utils/graphQL';
import {
  DELETE_WILL_SIGNING_DOCUMENTS,
  GET_WILL_SIGNING_VIDEO_DOWNLOAD_URL,
} from '../../../../../common/queries';
import to from 'await-to-js';
import { downloadURI, openReferralFactory } from '../../../../../utils';
import ConfirmationDialog from '../../../../../common/ConfirmationDialog/ConfirmationDialog';
import { useState } from 'react';
import { Will } from '../../../../../utils/types';
import { fetchUserProfileAction } from '../../../../../store/user/slice';
import { showToast, ToastType } from '../../../../../store/toast/slice';
import { useDispatch } from 'react-redux';
import Modal from '@mui/material/Modal';
import VideoContainer from '../../../../../common/Reusables/VideoContainer';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import useReferralFactoryScript from 'Pages/Dashboard/useReferralFactoryScript';

// @ts-ignore
const RecommendedStepCard = ({
  id,
  icon,
  header,
  body,
  onClick,
}: {
  id: any;
  icon: any;
  header: string;
  body: string;
  onClick: Function;
}) => {
  const themew = useTheme();
  const lessThanSmall = useMediaQuery(themew.breakpoints.down('sm'));

  return (
    <div
      id={id}
      style={{
        boxSizing: 'border-box',
        width: 'min(80vw, 400px)',
        minWidth: 'min(80vw, 400px)',
        maxWidth: 'min(80vw, 400px)',
        height: '200px',
        padding: 22,
        background: '#FCFCFC',
        borderRadius: 12,
        border: '2px rgba(80,80,80,0.15) solid',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        gap: 16,
        display: 'inline-flex',
        alignSelf: 'center',
      }}
      className={'cursor-pointer'}
      // @ts-ignore
      onClick={onClick}
    >
      <img
        style={{
          width: '48px',
          height: '48px',
        }}
        src={icon}
      ></img>
      <div
        style={{
          height: lessThanSmall ? '135px' : '91px',
          width: 'calc(100% - 48px)',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          gap: 6,
          display: 'inline-flex',
        }}
      >
        <div
          style={{
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            gap: 6,
            display: 'inline-flex',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
              display: 'inline-flex',
              flexDirection: 'row',
            }}
          >
            <h4
              className={'heading7'}
              style={{
                color: '#101828',
              }}
            >
              {header}
            </h4>
            <img
              style={{
                width: 24,
                height: 24,
              }}
              src={will_signing_next_steps_carousel_arrow}
            ></img>
          </div>
          <h4
            className={'xs_text font-roboto-light'}
            style={{
              color: '#101828',
              width: '100%',
            }}
          >
            {body}
          </h4>
        </div>
      </div>
    </div>
  );
};

const style2 = {
  background: 'rgba(16, 24, 40, 0.6)',
};

const VideoButton = ({
  noBorder,
  showDownloadIcon,
  willId,
}: {
  noBorder?: boolean;
  showDownloadIcon?: boolean;
  willId?: string;
}) => {
  const themew = useTheme();
  const lessThanSmall = useMediaQuery(themew.breakpoints.down('sm'));

  const [showSampleVideo, setShowSampleVideo] = useState(false);
  const downloadOnClick = async () => {
    console.log('in downloadOnClick, willId is:' + willId);
    const {
      data: { getWillSigningVideoDownloadInfo: downloadInfo },
    } = (
      await to(
        runQuery({
          query: GET_WILL_SIGNING_VIDEO_DOWNLOAD_URL,
          input: {
            key: 'input',
            value: willId,
          },
        }),
      )
    )[1];
    console.log('in downloadOnClick, downloadInfo is:\n' + JSON.stringify(downloadInfo, null, 2));

    if (downloadInfo?.url) {
      downloadURI(downloadInfo?.url, downloadInfo?.fileName);
    }
  };

  const playOnClick = () => {
    setShowSampleVideo(true);
  };

  return (
    <>
      <div
        style={{
          width: 'min(80vw, 446px)', //430px + 16px to account for border
          height: 'min(40vw, 214px)', //198px + 16px to account for border
          position: 'relative',
          borderRadius: '12px',
          boxSizing: 'border-box',
          ...(noBorder
            ? {}
            : {
                borderWidth: '16px',
                borderColor: '#FFE171',
                borderStyle: 'solid',
              }),
          background: 'rgba(16, 24, 40, 0.45)',
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          cursor: 'pointer',
          ...(lessThanSmall ? { overflow: 'hidden' } : {}),
        }}
        onClick={showDownloadIcon ? downloadOnClick : playOnClick}
      >
        <div
          style={{
            width: 'min(100%, 68px)',
            height: 'min(100%, 68px)',
            background: 'white',
            borderRadius: '50%',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            zIndex: 100,
          }}
          onClick={downloadOnClick}
        >
          <img
            style={{
              width: 'min(100%, 40px)',
              height: 'min(100%, 40px)',
            }}
            src={showDownloadIcon ? will_signing_video_download_icon : willSigningDownloadPlayIcon}
          ></img>
        </div>
        <div
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
            // @ts-ignore
            'mix-blend-mode': 'multiply',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <img
            style={{
              objectFit: 'contain',
              objectPosition: 'center',
            }}
            src={will_sample}
          ></img>
        </div>
      </div>
      <Modal
        open={showSampleVideo}
        sx={style2}
        onClose={() => {
          setShowSampleVideo(false);
        }}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box
          sx={{
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: lessThanSmall ? '100%' : '50%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            padding: lessThanSmall ? '0px' : '32px',
            borderRadius: '10px',
          }}
        >
          <Grid lg={12} md={12} sm={12} className='video-container'>
            <VideoContainer
              playing={showSampleVideo}
              lg={12}
              md={12}
              sm={12}
              url='https://www.youtube.com/watch?v=S0mv35wZOHk'
            />
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export const WillSigningIntroductoryFirstLeftPane = ({
  signingDocuments,
  setSigningDocuments,
  showNotifyOthersOverlay,
  showFuneralArrangements,
  setIsUploadingVideo,
  lastWillWithVideo,
  setLastWillWithVideo,
  setShowLoading,
  renderHelpIcon,
}: {
  signingDocuments?: SigningDocuments;
  setSigningDocuments: Function;
  showNotifyOthersOverlay: Function;
  showFuneralArrangements: Function;
  setIsUploadingVideo: Function;
  lastWillWithVideo?: Will;
  setLastWillWithVideo: Function;
  setShowLoading: Function;
  renderHelpIcon: Function;
}) => {
  const theme = useTheme();
  const lessThanSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const dispatch = useDispatch();
  const [confirmDeleteVideo, setConfirmDeleteVideo] = useState<boolean>(false);
  const [confirmReplaceVideo, setConfirmReplaceVideo] = useState<boolean>(false);
  const navigate = useNavigate();
  const activeWill = useAppSelector(selectActiveWill);
  const hasVideo = signingDocuments?.videoURL;

  //Initialise Referral Factory Hook -> Supports openReferralFactory() -> Refer & Earn
  useReferralFactoryScript();

  const recomendedSteps = [
    {
      id: 'notify_people',
      icon: will_signing_next_steps_notify_my_people,
      header: 'Notify My Beneficiaries',
      body: 'Notify the people involved in your Will about their roles. Keep them informed about their responsibilities in executing your wishes when the time comes.',
      onClick: () => showNotifyOthersOverlay(true),
    },
    {
      id: 'refer_and_earn',
      icon: will_signing_next_steps_refer_and_earn,
      header: 'Refer and earn',
      body: "Refer friends and family using your exclusive code. They'll enjoy a 15% discount on their Yellow plan purchase, and you'll receive ₹250 as a reward.",
      onClick: () => openReferralFactory(),
    },
    {
      id: 'funeral_arrangements',
      icon: will_signing_next_steps_funeral_arrangements,
      header: 'Funeral arrangements',
      body: 'Communicate your funeral wishes and special requests to ensure a meaningful farewell.',
      onClick: () => showFuneralArrangements(true),
    },
  ];

  const deleteVideo = async () => {
    setShowLoading(true);
    try {
      await runMutation({
        mutation: DELETE_WILL_SIGNING_DOCUMENTS,
        variables: {
          key: 'willId',
          value: lastWillWithVideo?.documentId || activeWill?.documentId,
        },
      });
      setShowLoading(false);
      setSigningDocuments(undefined);
      setLastWillWithVideo(undefined);
      setIsUploadingVideo(true);
      await new Promise((resolve, reject) => fetchUserProfileAction(undefined, resolve, reject));
    } catch (err) {
      dispatch(
        showToast({
          type: ToastType.ERROR,
          message: `Unable to delete signing video: ${err}`,
        }),
      );
    }
  };

  return (
    <>
      <div
        className={'hide_in_mobile_flex'}
        style={{
          display: 'flex',
          width: '100%',
          boxSizing: 'border-box',
          justifyContent: 'space-between',
          marginBottom: '40px',
          alignItems: 'center',
          paddingLeft: '40px',
          paddingRight: '40px',
          paddingTop: '40px',
        }}
      >
        <BackToSectionSummary noMargin={true} text='Back to My Will' action={() => navigate(0)} />
        {!hasVideo && !lessThanSmall ? renderHelpIcon() : <div />}
      </div>
      {!hasVideo ? (
        <div
          className={'hide_scrollbars'}
          style={{ width: '100%', overflow: 'scroll', display: 'flex', alignItems: 'center' }}
        >
          <div
            style={{
              display: 'flex',
              width: 'min(100%, 480px)',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              flexBasis: '100%',
              flexGrow: 1,
              overflow: 'visible',
              boxSizing: 'border-box',
            }}
          >
            <h3
              className={'font-merriweather'}
              style={{
                textAlign: 'center',
                fontStyle: 'normal',
                fontWeight: '400',
                fontSize: '32px',
                lineHeight: '40px',
                color: '#101828',
                paddingBottom: '32px',
              }}
            >
              My Video Will
            </h3>
            <VideoButton />
            <h4 className='max-w-100 heading5 pl-16px pr-16px pt-32px pb-12px text-center border-box'>
              Sample video of Will summary reading<br/>& signing the Will document
            </h4>
            <h4 className='subText pb-16px text-center font-roboto w-66 font-400'>
              Legal experts recommend that you make a<br/>recording of this process and keep it on file.
            </h4>
          </div>
        </div>
      ) : (
        <div
          className={'hide_scrollbars'}
          style={{
            width: '100%',
            overflow: 'scroll',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              maxWidth: 'min(100%, 550px)',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'flex-start',
              flexBasis: '100%',
              flexGrow: 1,
              ...(lessThanSmall ? { padding: '16px', boxSizing: 'border-box' } : {}),
            }}
          >
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
                marginBottom: '16px',
                alignItems: 'center',
              }}
            >
              <h3
                className={'font-merriweather'}
                style={{
                  textAlign: lessThanSmall ? 'center' : 'start',
                  fontSize: '32px',
                  color: '#101828',
                  width: 'fit-content',
                }}
              >
                My Video Will
              </h3>
              {!lessThanSmall && renderHelpIcon()}
            </div>
            <div
              style={{
                width: 'auto',
                maxWidth: '100%',
                boxSizing: 'border-box',
                padding: '25px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '10px',
                background: '#E7ECF3',
                borderRadius: '12px',
              }}
            >
              <h4 className={'xs_text font-roboto-bold w-100'}>
                {`Video uploaded on ${dateformat(
                  new Date(signingDocuments?.videoUploadedAt || 0),
                  'dS mmm, yyyy',
                )}`}
              </h4>

              <VideoButton
                willId={lastWillWithVideo?.documentId || activeWill?.documentId}
                noBorder={true}
                showDownloadIcon={true}
              />

              <div className={`xs_text font-roboto-light w-100 inline gap-8px`}>
                <span>Latest Will ID:</span>
                <h4 className={'xs_text font-roboto-bold w-min-content inline'}>{`${
                  (activeWill?.documentId || '').length > 4 ? '...' : ''
                }${(activeWill?.documentId || '').slice(-4)}`}</h4>
                <span>{' | '}</span>
                {activeWill?.dateOfCreation ? (
                  <>
                    <span>Updated on:</span>
                    <h4 className={'xs_text font-roboto-bold inline'}>{` ${dateformat(
                      new Date(activeWill?.updatedOn || activeWill?.dateOfCreation),
                      'dS mmm, yyyy',
                    )}`}</h4>
                  </>
                ) : null}
              </div>
              <CustomButton
                style={{ width: '100%', marginTop: lessThanSmall ? '0px' : '24px' }}
                text={'Replace Video'}
                onClick={() => setConfirmReplaceVideo(true)}
              />
              <h4
                className={'xs_text font-roboto-bold cursor-pointer unselectable'}
                style={{
                  color: '#BA1B1B',
                  textDecoration: 'underline',
                  wordWrap: 'break-word',
                }}
                onClick={() => setConfirmDeleteVideo(true)}
              >
                Delete video
              </h4>
            </div>
            {activeWill?.signingDocuments?.videoURL &&
            signingDocuments?.videoURL === activeWill?.signingDocuments?.videoURL ? (
              <div
                style={{
                  width: '80%',
                  padding: '16px',
                  marginTop: '16px',
                  background: '#EAF2FD',
                  borderRadius: '12px',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  gap: '4px',
                  display: 'inline-flex',
                }}
              >
                <span
                  className={'xs_text font-roboto-bold'}
                  style={{
                    color: '#17449B',
                    wordWrap: 'break-word',
                  }}
                >
                  Note:{' '}
                  <span
                    className={'xs_text font-roboto'}
                    style={{
                      color: '#17449B',
                      wordWrap: 'break-word',
                    }}
                  >
                    Remember to update the signing video whenever you make major changes to your
                    Will. This ensures that your Will reflects your current wishes accurately.
                    You’ve taken a great step in securing your future and protecting your loved
                    ones.
                  </span>
                </span>
              </div>
            ) : (
              <div
                style={{
                  width: '80%',
                  padding: '16px',
                  marginTop: '16px',
                  marginBottom: '16px',
                  background: '#FFEBDF',
                  borderRadius: '12px',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  gap: '10px',
                  display: 'inline-flex',
                }}
              >
                <img className={'h-16px w-16px'} src={warning_icon_will_signing_old} />
                <span
                  className={'xs_text font-roboto'}
                  style={{
                    color: '#995C00',
                    wordWrap: 'break-word',
                  }}
                >
                  This video may be outdated as you have generated a new Will.
                </span>
              </div>
            )}
            <h4
              className={'font-merriweather'}
              style={{
                textAlign: 'start',
                fontSize: '24px',
                color: '#101828',
                width: '100%',
                marginTop: '20px',
                marginBottom: '10px',
              }}
            >
              Recommended steps
            </h4>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              maxWidth={'calc(100vw - 32px)'}
              paddingBottom={lessThanSmall ? '50px' : '0px'}
              boxSizing={'border-box'}
              height={'400px'}
              flexDirection={'column'}
              display={'flex'}
              className={'mb-12px flex flex-col items-center justify center'}
            >
              <div className={'w-100 flex overflow-scroll gap-12px mb-12px'}>
                {
                  // @ts-ignore
                  // @ts-ignore
                  recomendedSteps.map((recomendedStep, index) => (
                    // @ts-ignore
                    <>
                      {/*// @ts-ignore */}
                      {/*// @ts-ignore */}
                      <RecommendedStepCard {...recomendedStep} />
                      {/*// @ts-ignore */}
                      {/*// @ts-ignore */}
                    </>
                    // @ts-ignore
                  ))
                  // @ts-ignore
                }
                {/*// @ts-ignore**/}
              </div>
              <div className={'w-min-content flex gap-40px mt-15px mb-40px'}>
                {
                  // @ts-ignore
                  // @ts-ignore
                  recomendedSteps.map((recomendedStep, index) => (
                    // @ts-ignore
                    <>
                      {/*// @ts-ignore */}
                      {/*// @ts-ignore */}
                      <div
                        className={'rounded-radius50p h-12px w-12px cursor-pointer'}
                        style={{ backgroundColor: '#101828' }}
                        onClick={() => {
                          const myEl = document.getElementById(recomendedStep.id);
                          myEl &&
                            myEl.scrollIntoView({
                              block: 'center',
                              behavior: 'smooth',
                            });
                        }}
                      />
                      {/*// @ts-ignore */}
                      {/*// @ts-ignore */}
                    </>
                    // @ts-ignore
                  ))
                  // @ts-ignore
                }
                {/*// @ts-ignore**/}
              </div>
            </Grid>
          </div>
        </div>
      )}
      {confirmDeleteVideo && (
        <ConfirmationDialog
          dialogImage={will_signing_delete_warning_icon}
          confirmDataButton={() => {
            setConfirmDeleteVideo(false);
            deleteVideo();
          }}
          showConfirmationData={false}
          setShowModel={setConfirmDeleteVideo}
          confirmationText={{
            warningText:
              'Your uploaded video won’t be available any longer. Are you sure you want to delete this video?',
            editButton: 'Cancel',
            confirmButton: 'Yes, delete',
            confirmButtonBackgroundColor: '#BA1B1B',
          }}
          imageWidth={'40px'}
          imageHeight={'40px'}
          imageMargin={{ mobile: '10px', desktop: '10px' }}
        />
      )}
      {confirmReplaceVideo && (
        <ConfirmationDialog
          dialogImage={will_signing_replace_warning_icon}
          confirmDataButton={() => {
            setConfirmReplaceVideo(false);
            setIsUploadingVideo(true);
          }}
          showConfirmationData={false}
          setShowModel={setConfirmReplaceVideo}
          confirmationText={{
            warningText:
              'Your previously uploaded video won’t be available any longer. Are you sure you want to replace this video?',
            editButton: 'Cancel',
            confirmButton: 'Yes, replace',
          }}
          imageWidth={'40px'}
          imageHeight={'40px'}
          imageMargin={{ mobile: '10px', desktop: '10px' }}
        />
      )}
    </>
  );
};
