import { Button, Grid, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';

import styled from 'styled-components';
import { pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import Radio from '@mui/material/Radio';
import Box from '@mui/material/Box';
import helpIcon from 'assets/images/willStepsHelpIcon.png';
import info from 'assets/images/info.png';
import blueIcon from 'assets/images/blue-plus-icon.png';
import addIcon from 'assets/svg/addButton-navyBlue.svg';
import address from 'assets/images/address.png';
import { ReactElement, useEffect, useState } from 'react';
import Loading from 'common/Loading';

import { useNavigate } from 'react-router-dom';
import { selectActiveWill } from 'store/user/selectors';
import { useAppSelector } from 'store/hooks';
import { UpdateWill, Will, WillLocation, WillTypes } from 'utils/types';
import { UPDATE_USER_WILL } from 'common/queries';
import { runMutation } from 'utils/graphQL';

import BackToSectionSummary from './BackToSectionSummary';
import { Pages } from 'routes';

import Arrow from 'assets/images/arrow.png';
import LeftArrow from 'assets/images/left-arrow.png';
import Slider from 'react-slick';
import { PickMyAddressOverlay } from 'common/updateLocationOverlay';
import MuiDrawer from 'common/Reusables/Drawer';
import OverlayHead from './CreateWill/Steps/OverlayHead';
import useAnalyticsEventTracker, { EVENT_CATEGORIES } from 'common/GoogleAnalytics';
import ContactUsHome from 'Pages/ContactUs';
import book from 'assets/svg/book.svg';
import contact from 'assets/svg/menuItem-contact.svg';
import pencil from 'assets/svg/pencil.svg';
import trash from 'assets/svg/trash.svg';
import document from 'assets/images/document.png';
import { useTypedDispatch } from 'store/hooks';
import { showToast, ToastMessage, ToastType } from 'store/toast/slice';
import { fetchUserProfileAction, updateActiveWill } from 'store/user/slice';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { breakpoints } from '../../styles/Breakpoints';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useEventSubscriber } from 'use-event-emitter-hook';
import { useVendor } from 'common/useVendor';
import CustomButton from 'common/Reusables/Button';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const useStyles = makeStyles({
  underline: {
    '&&&:before': {
      borderBottom: 'none',
    },
    '&&:after': {
      borderBottom: 'none',
    },
  },
});

const SectionSummaryWrapper = styled.div`
  .subtitle-2 {
    font-weight: 700;
    // font-size: 20px;
    line-height: 26px;
    color: #101828;
    justify-content: space-between;
    display: flex;
    img {
      filter: invert(1);
      width: 19px;
      cursor: pointer;
    }
  }
  .solid-black-btn {
    background-color: #1d2939;
    border-radius: 8px;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #fcfcfc;
    padding: 14px;
    width: 100%;
    text-transform: capitalize;
    margin-bottom: 30px;
    :hover {
      background-color: #1d2939 !important;
    }
    @media screen and (max-width: ${breakpoints.md}) {
      border-radius: 0;
      margin: 0;
      padding: 16px;
      position: fixed;
      bottom: 0;
      right: 0;
      left: 0;
      width: 100vw;
    }
  }
  .info {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #17449b;
    margin-top: 13px;
    margin-bottom: 49px;
    display: flex;
    align-items: center;
    img {
      margin-right: 14px;
      width: 20px;
    }
  }
  .input-example {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #000;
    background: rgba(16, 24, 40, 0.04);
    border-radius: 12px;
    padding: 16px 16px;
    @media screen and (max-width: ${breakpoints.md}) {
      padding: 16px;
      height: auto;
    }
    box-sizing: border-box;
    margin-top: 16px;
    border: unset;
    width: 100%;
    :focus-visible {
      outline: none;
    }
    &.edit-input {
      background-color: transparent;
      color: #101828;
      margin: 0;
      padding: 16px 0px;
      ::placeholder {
        color: #101828;
      }
    }
  }
  .example {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #000;
    background: rgba(16, 24, 40, 0.04);
    border-radius: 12px;
    padding: 16px 24px;
    margin-top: 16px;
    border: unset;
    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #101828;
      margin-top: 10px;
    }
  }
  .title-head {
    margin-top: 16px;
    margin-bottom: 16px;
    h3 {
      font-weight: 400;
      font-size: 32px;
      line-height: 40px;
      text-align: center;
      color: #101828;
    }
  }
  .sub-heading {
    font-weight: 400;
    line-height: 26px;
    color: #101828;
  }
  .sub-title {
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    color: #101828;
    margin-bottom: 16px;
  }
  .banner_icon {
    margin: 32px 0px;
    width: 220px;
    height: 250px;
  }
  .help_icon {
    cursor: pointer;
    height: 45px;
    width: 45px;
    margin-right: 18px;
  }
  .skipActionArea {
    width: 180px;
    height: 45px;
    border: 2px solid ${(props) => props.theme.primaryColor};
    border-radius: 5px;
    margin-bottom: 20px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .action_button_holder {
    display: flex;
    margin-bottom: 30px;
    .continueWillBtn {
      width: 180px;
      margin-left: 20px;
    }
  }

  .sectionSummary_container {
    padding: 40px 0px;

    .summary_cards_holder {
      margin-top: 40px;
    }
  }
  .summary {
    min-height: 500px;
  }
  .illustrationImage {
    width: 100%;
  }
  .myWill-subtext-font {
    font-size: 24px;
  }
`;

const OuterLayout = styled(Grid)`
  background: #f3f4f5;
  border-radius: 12px;
  padding: 24px;
  display: flex;
  flex-direction: column !important;


  .divGap{
    display:flex;
    flex-direction: column;
    gap: 16px;
  }
  
  .personal-details {
    display: flex;
    align-items: center;
    // margin-bottom: 16px;
    align-items: flex-start;
    .personal-details-inner {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: flex-start;
      .trash {
        cursor: pointer;
        width: 18px;
      }
    }
    h5 {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #1d2939;
    }
    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: rgba(16, 24, 40, 0.64);
    }
  }
  .add-new-address {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    text-decoration-line: underline;
    color: #1270e8;
    align-items: center;
    display: flex;
    cursor: pointer;
    img {
      margin-right: 12px;
    }
  }
  .address-title {
    margin-bottom: 26px;
    h4 {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #1d2939;
      display: flex;
      align-items: end;
      img {
        margin-right: 15px;
      }
    }
    // a {
    //   font-weight: 500;
    //   font-size: 16px;
    //   line-height: 19px;
    //   text-decoration-line: underline;
    //   color: #1270e8;
    //   margin-left: 12px;
    // }
  }
  .sub-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    color: #101828;
    margin-bottom: 16px;
  }
  .update-will {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-decoration-line: underline;
    color: #1270e8;
    cursor: pointer;
  }
  // .MuiGrid-item {
  //   background: #ffffff;
  //   border-radius: 12px;
  //   padding: 24px;
    // margin-bottom: 16px;
  // }
`;

export const HelpWrapper = styled(Grid)`
  padding: 30px 30px 20px;
  // height: 100vh;
  position: relative;
  margin-bottom: 60px;
  display: flex;
  gap: 1.5rem;

  .content {
    height: 65%;

    p {
      margin-top: 20px;
    }
  }
  .bold-text {
    font-weight: 800;
  }
  .queries_section {
    height: min-content;
    width: 100%;
    padding: 10px;

    background: #f3f4f5;
    border-radius: 10px;

    .queryHelpCard {
      cursor: pointer;
      height: 50px;
      max-height: 50px;
      width: 100%;
      background-color: #ffffff;
      border-radius: 10px;
      padding: 12px;

      img {
        height: 20px;
        width: 20px;
        object-fit: contain;
        margin-right: 10px;
      }
    }
  }
`;

interface SlideInnerProps {
  isChecked: boolean;
  index: number;
  maxIndex: number;
}

const SlideInner = styled(Grid)<SlideInnerProps>`
  padding: 16px;
  background-color: ${(props) => (props.isChecked ? '#FFE171' : '#ffffff')};
  width: 230px !important;
  @media screen and (max-width: ${breakpoints.md}) {
    margin-right: ${(props) => (props.index < props.maxIndex ? '5px !important' : '0px')};
    margin-left: ${(props) => (props.index > 0 ? '5px !important' : '0px')};
    // width: 65vw !important;
  }
  border-radius: 12px;
  margin-right: 12px;
  box-shadow: 0px 0px 8px rgba(97, 94, 87, 0.14);
  border: ${(props) => (props.isChecked ? '1px solid black' : 'none')};
`;

const AddressDropdown = styled(Grid)<SlideInnerProps>`
padding: 16px;
background: ${(props) => (props.isChecked ? '#FFE171' : '#ffffff')};
min-width: 100%;

border-radius: 12px;

box-shadow: 0px 0px 8px rgba(97, 94, 87, 0.14);
border: ${(props) => (props.isChecked ? '1px solid black' : 'none')};

`;

const SlideSlick = styled.div`
  padding-top: 0px;
  display: flex;
  flex-direction: row; 
  gap: 12px;

  @media screen and (max-width: ${breakpoints.md}) {
    padding-top: 0;
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    box-sizing: border-box;
  }
  .slick-track {
    width: 100% !important;
    display: flex;
    margin: 0 auto;
  }

  .slick-list {
    // padding: 10px 0;
    // @media screen and (max-width: ${breakpoints.md}) {
    //   max-width: calc(100vw - 64px);
    //   max-width: calc(100vw - 64px) !important;
    //   min-width: calc(100vw - 64px) !important;
    //   overflow: scroll;
    // }
  }
  .slick-slide {
    width: 240px !important;
    // overflow: scroll;
    @media screen and (max-width: ${breakpoints.md}) {
      width: calc(65vw + 5px) !important;
    }
  }
  .Mui-checked {
    color: #000000 !important;
    padding: 0px;
    margin-right: 13px;
  }
  .detail-title {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #101828;
  }
  h3 {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: rgba(16, 24, 40, 0.64);
  }

  .slick-arrow {
    box-shadow: 0px 1px 8px rgb(29 41 57 / 24%);
    width: 40px;
    height: 40px;
    border-radius: 450%;
    background-color: #ffffff;
    background-repeat: no-repeat;
    background-position: center;
    &:before {
      content: unset;
    }
  }
  .slick-prev {
    background-image: url(${LeftArrow});
    z-index: 9;
  }
  .slick-disabled {
    background: transparent;
    box-shadow: none;
    background-image: none !important;
  }
  .slick-next {
    background-image: url(${Arrow});
  }
`;

export enum locationWillType {
  CONFIRMATION_TYPE = 'CONFIRMATION_TYPE',
  INITIAL_TYPE = 'INITIAL_TYPE',
}

export const QueryHelpCard = ({
  icon,
  text,
  onClick,
}: {
  icon: string;
  text: string;
  onClick: Function;
}) => (
  <Grid
    item
    lg={10}
    display='flex'
    justifyContent={'center'}
    alignItems='center'
    className='queryHelpCard'
    onClick={() => onClick()}
  >
    <img src={icon} alt='' />
    <span className='font-semi-bold'>{text}</span>
  </Grid>
);

const UpdateLocation = ({ setIsUpdateLocation }: any) => {
  // @ts-ignore
  const classes = useStyles();
  const navigate = useNavigate();
  const userActiveWill = useAppSelector(selectActiveWill);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [locationPage, setLocationPage] = useState<any>(locationWillType.INITIAL_TYPE);
  const [selectedExecutor, setExecutor] = useState(1);
  const [openHelp, setOpenHelp] = useState(false);
  const gaEventTracker = useAnalyticsEventTracker({ category: EVENT_CATEGORIES.MY_WILL });
  const [showContactUsScreen, setShowContactUsScreen] = useState(false);
  const [existingAddresses, setExistingAddresses] = useState<any>([]);
  const [selectedAddress, setSelectedAddress] = useState<any>({});
  const [additionalInstructions, setAdditionalInstructions] = useState('');
  const [confirmLocation, setConfirmLocation] = useState(false);
  const [isNewAddressOpen, setIsNewAddressOpen] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useTypedDispatch();
  const themew = useTheme();
  const lessThanSmall = useMediaQuery(themew.breakpoints.down('sm'));
  const { isAcko } = useVendor();

  let settings = {
    dots: false,
    arrows: false,
    infinite: false,
    slidesToShow: lessThanSmall ? 1 : 2,
    swipe: !lessThanSmall,
    slidesToScroll: 1,
    speed: 500,
  };

  const getHelpContent = (): ReactElement => {
    return (
      <>
        <p className='text-center'>
          This often overlooked step is important to ensure that your Executor and family will be
          able to easily find your Will, as well as any supplemental documents you may have stored
          along with it.
        </p>
        <p className='text-center'>
          Be sure to include enough detail and update this section should you move residences, or
          decide to shift your Will's location at any time.
        </p>
      </>
    );
  };

  const title = 'Will Location';

  useEffect(() => {
    if (userActiveWill) {
      let addresses: any = [];
      const personalAddress = userActiveWill.testatorDetails?.address;

      if (userActiveWill.willLocation?.address) {
        addresses.push(userActiveWill.willLocation.address);
      }

      if (personalAddress) {
        addresses.push(personalAddress);
      }

      if (userActiveWill.assets) {
        userActiveWill.assets.forEach((el: any) => {
          if (el.address) {
            addresses.push(el.address);
          }
        });
      }

      setExistingAddresses(addresses);
    }
  }, [userActiveWill]);

  const handleDeleteAddress = () => {
    setSelectedAddress({});
    setAdditionalInstructions('');
    handleConfirmLocation();
  };

  useEffect(() => {
    if (existingAddresses.length > 0) {
      const selectedObtainedAddress = existingAddresses.filter(
        (item: any, index: any) => index + 1 === selectedExecutor,
      );
      if (selectedObtainedAddress.length > 0) {
        setSelectedAddress(selectedObtainedAddress[0]);
      }
    }
  }, [selectedExecutor, existingAddresses]);

  const handleConfirmLocation = async (willLocationObject?: any) => {
    const updateWillRequestBody: UpdateWill = {
      willLocation: willLocationObject ? willLocationObject : {},
      documentId: userActiveWill?.documentId || ('' as string),
      type: WillTypes.PREMIUM_WILL,
    };

    try {
      setLoading(true);
      const {
        data: { updateWill: updatedWill },
      }: any = await runMutation({
        mutation: UPDATE_USER_WILL,
        variables: {
          key: 'input',
          value: updateWillRequestBody,
        },
      });

      await new Promise((resolve, reject) => {
        dispatch(fetchUserProfileAction(undefined, resolve, reject));
      });

      if (updatedWill) {
        dispatch(
          updateActiveWill({
            will: updatedWill,
          }),
        );
        willLocationObject
          ? setLocationPage(locationWillType.CONFIRMATION_TYPE)
          : setLocationPage(locationWillType.INITIAL_TYPE);
      }
      (window as any).webengage.track("Will Location added", {
        "Added Location": "yes"
      });
      (window as any).webengage.user.setAttribute("Will Location Added", true);
      setLoading(false);
      return updatedWill as Will;
    } catch (error) {
      const toast: ToastMessage = {
        type: ToastType.ERROR,
        message: 'Error in Updating your Will Location. Please try again.',
      };
      setLoading(false);
      dispatch(showToast(toast));
    }
  };

  useEffect(() => {
    if (
      !confirmLocation &&
      userActiveWill &&
      userActiveWill.willLocation &&
      userActiveWill.willLocation.address
    ) {
      let indexObtained = existingAddresses.findIndex((item: any, index: any) => {
        if (
          JSON.stringify(item) ===
          JSON.stringify(
            userActiveWill && userActiveWill.willLocation && userActiveWill.willLocation.address,
          )
        ) {
          return index;
        }
      });
      setExecutor(indexObtained + 1);
      setLocationPage(locationWillType.CONFIRMATION_TYPE);
      setSelectedAddress(userActiveWill.willLocation.address);
    }
  }, [userActiveWill]);

  useEffect(() => {
    if (locationPage === locationWillType.CONFIRMATION_TYPE) {
      setConfirmLocation(false);
    }
  }, [locationPage]);

  useEventSubscriber('will_location_help_on_click', () => setOpenHelp(true));

  if(loading) return <Loading />;

  return (
    <>
      <SectionSummaryWrapper>
        {locationPage === locationWillType.INITIAL_TYPE ? (
          <>
            <div className={'hide_in_mobile'}>
              <Grid
                container
                lg={12}
                md={12}
                sm={12}
                justifyContent={'space-between'}
                className='actions'
              >
                <BackToSectionSummary
                  text='Back to My Will'
                  action={() => {
                    setIsUpdateLocation(false);
                    navigate(Pages.MY_WILL);
                  }}
                />
              </Grid>
            </div>
            <Grid
              container
              columns={{ xs: 9, md: 12 }}
              display={'flex'}
              alignItems='center'
              justifyContent={'center'}
              flexDirection='column'
            >
              <Grid
                item
                xs={12}
                md={6}
                lg={7}
                style={{
                  ...(lessThanSmall ? { width: '100%', maxWidth: '100%' } : {}),
                  boxSizing: 'border-box',
                }}
              >
                <div
                  className='title-head hide_in_mobile_flex'
                  style={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'space-between',
                  }}
                >
                  <h3 className={'font-merriweather'}>Will Location</h3>
                  {lessThanSmall ? (
                    <HelpOutlineIcon onClick={() => setOpenHelp(true)} />
                  ) : (
                    <img
                      onClick={() => setOpenHelp(true)}
                      src={helpIcon}
                      alt='Help Icon'
                      className='help_icon'
                    />
                  )}
                </div>
                <h4 className={`sub-heading ${lessThanSmall ? 'text-16px mb-16px' : 'text-20px mb-40px' }`}>
                  Update the location of your physical Will, so its easier for the Executor to
                  execute your wishes
                </h4>
                { lessThanSmall ? <h4 className='sub-title'>Address</h4> : null }

                <OuterLayout className={`${lessThanSmall ? 'py-20px px-16px mb-16px' : 'p-24px mb-32px'}`}>
                  { !lessThanSmall ? <h4 className='sub-title'>Address</h4> : null }
                  <div className='divGap'>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      className={`items-center justify-between rounded-radius12 ${lessThanSmall ? 'background-none p-0' : 'background_white p-24px'}`}
                    >
                      <div
                        className={`address-title flex flex-row justify-between w-100 m-0 ${
                          lessThanSmall ? 'items-center' : ''
                        }`}
                      >
                        <div
                          className={`address-title ${
                            lessThanSmall ? 'w-max-content pb-18px' : 'w-max-content'
                          } items-center flex flex-row gap-5px`}
                          style={{ ...(lessThanSmall ? { margin: 0 } : {}) }}
                        >
                          <img className={'h-24px w-24px'} src={address} alt='address' />
                          <h4>Select from existing addresses</h4>
                        </div>
                      </div>
                      {
                        !lessThanSmall ? (
                        <div 
                          className={'border-box p-8px'} 
                          style={{ 
                            width: '100%',
                            overflow: 'scroll',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                          }}
                        >
                          <SlideSlick>
                            <Slider {...settings}>
                              {existingAddresses.map((address: any, index: any) => {
                                const isChecked = selectedExecutor === index + 1 ? true : false;
                                if(index > 1) return null;
                                return (
                                  <SlideInner
                                    onClick={() => setExecutor(index + 1)}
                                    isChecked={isChecked}
                                    index={index}
                                    maxIndex={existingAddresses.length - 1}
                                  >
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        alignItems: 'flex-start',
                                        cursor: 'pointer',
                                      }}
                                    >
                                      <Radio
                                        checked={isChecked}
                                        onChange={() => setExecutor(index + 1)}
                                        value='a'
                                        name='radio-buttons'
                                        inputProps={{ 'aria-label': 'A' }}
                                      />
                                      <div>
                                        <h5 className='detail-title'>{address.addressLine1}</h5>
                                        <h3>
                                          {address.pinCode}, {address.addressLine1}
                                        </h3>
                                      </div>
                                    </Box>
                                  </SlideInner>
                                );
                              })}
                            </Slider>
                          </SlideSlick>
                          <a
                            className={`${lessThanSmall ? 'max-w-80' : 'w-max-content'}`}
                            style={{ cursor: 'pointer', textAlign: 'right', fontWeight: 500, fontSize: '16px', lineHeight: '19px', color: '#1270E8', textDecorationLine: 'underline' }}
                            onClick={() => setIsOpen(true)}
                          >
                            View all &rarr;
                          </a>
                        </div>
                        ) : (
                          <div 
                            className={'border-box'} 
                            style={{ 
                              width: '100%',
                              overflow: 'scroll',
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'space-between',
                              gap: '16px'
                            }}
                          >
                            {
                              existingAddresses.map((address: any, index: any) => {
                                const isChecked = selectedExecutor === index + 1 ? true : false;
                                if (index > 2){
                                  return null;
                                }
                                return (
                                  <AddressDropdown
                                    onClick={() => setExecutor(index + 1)}
                                    isChecked={isChecked}
                                    index={index}
                                    maxIndex={existingAddresses.length - 1}
                                  >
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        alignItems: 'flex-start',
                                        cursor: 'pointer',
                                      }}
                                    >
                                      <Radio
                                        checked={isChecked}
                                        onChange={() => setExecutor(index + 1)}
                                        value='a'
                                        name='radio-buttons'
                                        inputProps={{ 'aria-label': 'A' }}
                                      />
                                      <div>
                                        <h5 className='pricingHeadingColor font-roboto text-16px'>{address.addressLine1}</h5>
                                        <h3 className='font-roboto text-14px' style={{
                                          color: 'rgba(16, 24, 40, 0.64)',
                                        }}>
                                          {address.pinCode}, {address.addressLine1}
                                        </h3>
                                      </div>
                                    </Box>
                                  </AddressDropdown>
                                );
                              })
                            }
                            <a
                              className={`${lessThanSmall ? '' : 'w-max-content'}`}
                              style={{ cursor: 'pointer', textAlign: 'right', fontWeight: 500, fontSize: '16px', lineHeight: '19px', color: '#1270E8', textDecorationLine: 'underline' }}
                              onClick={() => setIsOpen(true)}
                            >
                              View all
                            </a>
                          </div>
                        )
                      }
                    </Grid>

                    {!lessThanSmall ? (
                      <Grid item>
                        <h4
                          className='add-new-address'
                          onClick={() => {
                            setIsNewAddressOpen(true);
                          }}
                        >
                          <img src={blueIcon} alt='plus' />
                          Add a new address
                        </h4>
                      </Grid>
                    ) : null
                    }
                  </div>

                  <div>
                    <PickMyAddressOverlay
                      isOpen={isOpen}
                      setIsOpen={setIsOpen}
                      existingAddresses={existingAddresses}
                      setExecutor={setExecutor}
                      selectedExecutor={selectedExecutor}
                      additionalInstructions={additionalInstructions}
                      setIsNewAddressOpen={setIsNewAddressOpen}
                      isNewAddressOpen={isNewAddressOpen}
                      anchor={lessThanSmall ? 'bottom' : 'right'}
                    />
                  </div>
                </OuterLayout>
                  
                { lessThanSmall &&
                  <Grid
                    style = {{
                      display: 'flex',
                      justifyContent: 'start',
                      alignItems: 'center',
                      marginBottom: '16px',
                      padding: '12px 16px',
                      borderRadius: '8px',
                      border: '1.5px solid #1D2939',
                      gap: '12px'
                    }}
                  >
                    <img src={addIcon} alt='plus' />
                    <h4
                      className='font-roboto text-16px color_primary'
                      onClick={() => {
                        setIsNewAddressOpen(true);
                      }}
                    >
                      Add a new address
                    </h4>
                  </Grid>
                }

                <h3 className='subtitle-2'>Additional Instructions</h3>
                <TextField
                  className='input-example'
                  id='instructions'
                  value={additionalInstructions}
                  onChange={(e) => setAdditionalInstructions(e.target.value)}
                  placeholder={`Eg. In the koramangala house, master bedroom,inside the silver almirah. ${
                    lessThanSmall ? '\n' : ' '
                  }loreum ipsum`}
                  fullWidth
                  multiline
                  rows={lessThanSmall ? '4' : '1'}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{ classes, disableUnderline: true }}
                  variant='standard'
                />
                <p className='info'>
                  <img src={info} alt='info' />
                  Your Will Executor will be notified about the Will location after demise
                  verification.
                </p>
                {lessThanSmall && <div style={{ height: '70px' }} />}
                <Button
                  className='solid-black-btn'
                  onClick={() => {
                    setConfirmLocation(true);
                    const object = {
                      address: selectedAddress,
                      nameOfProperty: '',
                      additionalInstructions: additionalInstructions,
                    } as WillLocation;
                    handleConfirmLocation(object);
                  }}
                >
                  Confirm my Will location
                </Button>
              </Grid>
            </Grid>
          </>
        ) : null}

        {locationPage === locationWillType.CONFIRMATION_TYPE ? (
          <>
            <Grid
              container
              lg={12}
              md={12}
              sm={12}
              justifyContent={'space-between'}
              className='actions hide_in_mobile'
            >
              <BackToSectionSummary
                text='Back to My Will'
                action={() => {
                  setIsUpdateLocation(false);
                  navigate(Pages.MY_WILL);
                }}
              />
            </Grid>
            <Grid
              container
              columns={{ xs: 9, md: 12 }}
              display={'flex'}
              alignItems='center'
              justifyContent={'center'}
              flexDirection='column'
            >
              <Grid item xs={12} md={6} lg={7}>
                <div
                  className='title-head hide_in_mobile_flex'
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    justifyContent: 'space-between',
                  }}
                >
                  <h3 className={'font-merriweather'}>Will Location</h3>
                  {lessThanSmall ? (
                    <HelpOutlineIcon />
                  ) : (
                    <img
                      onClick={() => setOpenHelp(true)}
                      src={helpIcon}
                      alt='Help Icon'
                      className='help_icon'
                    />
                  )}
                </div>
                <div className=''> 
                  <h4 className={`sub-heading ${lessThanSmall ? 'text-16px mb-16px' : 'text-20px mb-40px' }`}>
                    Make sure the location details of your signed Will are up to date so your family
                    have easy access when they need it.
                  </h4>
                </div>
                { lessThanSmall ? <h4 className='sub-title'>Address</h4> : null }
                <OuterLayout
                  className={`mb-32px ${lessThanSmall ? 'gap-16px' : ''}`}
                >
                  { !lessThanSmall &&
                    <h4
                      style={{
                        justifyContent: 'space-between',
                        display: 'flex',
                        marginBottom: '16px',
                      }}
                      className='sub-title'
                    >
                      Address
                        <a
                          className='update-will'
                          onClick={() => setLocationPage(locationWillType.INITIAL_TYPE)}
                        >
                          Update my Will location
                        </a>
                    </h4>
                  }
                  <Grid item xs={12} md={12}>
                    <div className='personal-details'>
                      <img
                        style={{
                          marginRight: '16px',
                        }}
                        src={document}
                        alt='document'
                      />

                      <div className='personal-details-inner'>
                        <div>
                          <h5>{selectedAddress.addressLine1}</h5>
                          <p>{selectedAddress.addressLine2}</p>
                          <p>{selectedAddress.pinCode ? `${selectedAddress.city} - ${selectedAddress.pinCode}, ${selectedAddress.state}` : ''}</p>
                        </div>
                        <img
                          className='trash'
                          onClick={() => handleDeleteAddress()}
                          src={trash}
                          alt='trash'
                        />
                      </div>
                    </div>
                  </Grid>
                  { lessThanSmall &&
                    <a
                      className='update-will'
                      onClick={() => setLocationPage(locationWillType.INITIAL_TYPE)}
                      style = {{
                        textAlign: 'right'
                      }}
                    >
                      Update my Will location
                    </a>
                  }
                </OuterLayout>
                {lessThanSmall &&
                  <h3 className={`subtitle-2 ${lessThanSmall ? 'text-18px' : 'text-20px'}`}>
                    Additional Instructions
                    <img
                      onClick={() => setLocationPage(locationWillType.INITIAL_TYPE)}
                      src={pencil}
                      alt='pencil'
                    />{' '}
                  </h3>
                }
                <div className='example'>
                  {!lessThanSmall &&
                  <h3 className='subtitle-2'>
                    Additional Instructions
                    <img
                      onClick={() => setLocationPage(locationWillType.INITIAL_TYPE)}
                      src={pencil}
                      alt='pencil'
                    />{' '}
                  </h3>
                  }
                  <p>{userActiveWill && userActiveWill?.willLocation?.additionalInstructions}</p>
                </div>
              </Grid>
            </Grid>
          </>
        ) : null}

        <MuiDrawer 
          open={openHelp} 
          width={'min(100%, 500px)'} 
          hideDrawer={() => setOpenHelp(false)} 
          anchor={lessThanSmall ? 'bottom' : 'right'} 
          height={lessThanSmall ? 'auto' : '100%'}
          borderRadius={lessThanSmall ? '24px 24px 0px 0px' : ''}
        >
          <HelpWrapper container>
            <Grid className='content' flexGrow={8}>
              <OverlayHead heading={title} onClick={() => setOpenHelp(false)} />
              <p>{getHelpContent()}</p>
            </Grid>

            <CustomButton
              text='Got it, thanks!'
              style={
                lessThanSmall
                ? ({ width: '368px', borderRadius: '12px' })
                : ({ width: '100%', borderRadius: '12px' })
              }
              onClick={() => setOpenHelp(false)}
            />

            {!isAcko && (
              <Grid
                item
                lg={12}
                className='queries_section'
                display={'flex'}
                flexDirection='column'
                justifyContent='space-around'
                alignItems={'center'}
                style={{ gap: '12px', padding: '12px' }}
              >
                <p className='subText font-semi-bold'>Have a specific query in mind?</p>
                <QueryHelpCard
                  icon={book}
                  text='Explore FAQs'
                  onClick={() => {
                    gaEventTracker({
                      action: `Clicked on Explore FAQs for ${title} step.`,
                      label: `Will Creation Step - ${title}`,
                    });
                    window.open('https://getyellow.in/faq', '_blank');
                  }}
                />
                <QueryHelpCard
                  icon={contact}
                  text='Contact Us'
                  onClick={() => {
                    gaEventTracker({
                      action: `Clicked on Contact Us for ${title} step.`,
                      label: `Will Creation Step - ${title}`,
                    });
                    setShowContactUsScreen(true);
                  }}
                />
              </Grid>
            )}
          </HelpWrapper>
        </MuiDrawer>

        <MuiDrawer
          open={showContactUsScreen}
          width={'min(100%, 500px)'}
          hideDrawer={() => setShowContactUsScreen(false)}
          anchor={lessThanSmall ? 'bottom' : 'right'}
          borderRadius={lessThanSmall ? '24px 24px 0px 0px' : ''}
        >
          <ContactUsHome closeIconAction={() => setShowContactUsScreen(false)} />
        </MuiDrawer>
      </SectionSummaryWrapper>
    </>
  );
};

export default UpdateLocation;
